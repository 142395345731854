import { createSlice } from '@reduxjs/toolkit';
import { IPatient } from 'utils/types/patient.type';

type InitialState = {
    data: { results: IPatient[]; total: number };
    isLoading: boolean;
    error: null | string;
};

const reportingSlice = createSlice({
    name: '/reportingApi',
    initialState: {
        data: { results: [], total: 0 },
        isLoading: false,
        error: null,
    } as InitialState,
    reducers: {},
});

export default reportingSlice.reducer;
