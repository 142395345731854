import {
    ApiResponse,
    AuthApiRequest,
    IAuthNewPasswordRequest,
    IAuthRequest,
    IAuthUserDetailsRequest,
    IAuthVerifyRequest,
    IChallengeRequestParams,
    IListRequestParams,
    IReactionRequest,
    ISchemaRequestParams,
} from 'utils/types/api.type';
import { IErrorMsg, ILoginProps, ISignUpProps, passwordArgProps, validationProps } from '../types/auth.type';

import User from '../types/user.type';
import {
    IChallengeRequest,
    IHomeHOCProps,
    IPatient,
    IPatientAssign,
    IPatientProps,
    IPatientRequest,
    ISaveChallengeNotesRequest,
    ISaveNotesRequest,
    IServeyNoteRequest,
    ISolutionNoteRequest,
    ISolutionRequest,
    ITodoToggleRequestParams,
    PatientsFilter,
} from 'utils/types/patient.type';
import {
    IExistingResourceRequest,
    IImageCreateRequest,
    IImageRequest,
    IPatientResourceRequest,
    IResourcesCreateRequest,
} from 'utils/types/image.type';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ILetterRequest, ILetterRequestRInfo, INavigationProps, IScreenerRequest } from 'utils/types/screener.type';
import { IReportingHOCProps, IReportingRequestParams } from 'utils/types/reporting.type';

const initUser: User = {
    id: '',
    name: '',
    email: '',
    _id: '',
    fullName: '',
};
export default initUser;

export const signupInitValidationValues: validationProps = {
    hasNumber: false,
    hasSpesCharactor: false,
    hasAllCharactors: false,
};

export const inputTypeInit: passwordArgProps = {
    type: 'password',
    rightIcon: 'EyePsw',
};

export const errorMsgInit: IErrorMsg = {
    isVisible: false,
    type: '',
    msg: '',
};

export const loginPropsInit: ILoginProps = {
    isLoading: false,
    errorMsg: {
        isVisible: false,
        type: '',
        msg: '',
    },
    apiMethods: {
        login: function (_req: AuthApiRequest): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
        newPassword: function (_req: IAuthNewPasswordRequest): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
        resetPassword: function (_req: IAuthRequest): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
    },
    navigateMethods: {
        navigateToSignIn: function (): void {
            throw new Error('Function not implemented.');
        },
        navigateToSignUp: function (): void {
            throw new Error('Function not implemented.');
        },
        navigateToResetPassword: function (): void {
            throw new Error('Function not implemented.');
        },
        navigateToRestEmail: function (): void {
            throw new Error('Function not implemented.');
        },
        navigateToVerification: function (_email: string): void {
            throw new Error('Function not implemented.');
        },
        navigateToUserDetails: function (_email: string): void {
            throw new Error('Function not implemented.');
        },
        navigateToHome: function (): void {
            throw new Error('Function not implemented.');
        },
    },
};

export const signUpPropsInit: ISignUpProps = {
    isLoading: false,
    errorMsg: {
        isVisible: false,
        type: '',
        msg: '',
    },
    apiMethods: {
        signUp: function (_req: AuthApiRequest): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
        verify: function (_req: IAuthVerifyRequest): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
        resendCode: function (_req: IAuthRequest): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
        userDetails: function (_req: IAuthUserDetailsRequest): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
        userPositions: function (): Promise<ApiResponse | undefined> {
            throw new Error('Function not implemented.');
        },
    },
    navigateMethods: {
        navigateToSignIn: function (): void {
            throw new Error('Function not implemented.');
        },
        navigateToSignUp: function (): void {
            throw new Error('Function not implemented.');
        },
        navigateToVerification: function (_email: string): void {
            throw new Error('Function not implemented.');
        },
        navigateToHome: function (): void {
            throw new Error('Function not implemented.');
        },
    },
};

export const HomePropsInit: IHomeHOCProps = {
    getDashboardUpdates: function (_userId?: string | undefined): Promise<any> {
        throw new Error('Function not implemented.');
    },
    updateReaction: function (_req: IReactionRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    getDashboardMetrix: function (_userId?: string | undefined): Promise<any> {
        throw new Error('Function not implemented.');
    },
    getDashboardUrgentChallenges: function (_userId: string): Promise<any> {
        throw new Error('Function not implemented.');
    },
    getDashboardTodosChallenges: function (_userId: string): Promise<any> {
        throw new Error('Function not implemented.');
    },
    toggleDashboardTodoCheckbox: function (_req: ITodoToggleRequestParams): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    isFetching: false,
};

export const PatientPropsInit: IPatientProps = {
    isLoading: false,
    errorMsg: {
        isVisible: false,
        type: '',
        msg: '',
    },
    createPatient: function (_req: IPatient): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    saveImage: function (_req: IImageCreateRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    getUsers: function (): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    navigateToPatients: function (_isSaved?: boolean): void {
        throw new Error('Function not implemented.');
    },
    navigateToEdit: function (_id: string): void {
        throw new Error('Function not implemented.');
    },
    navigateToDelete: function (_id: string, _name: string): void {
        throw new Error('Function not implemented.');
    },
    getImage: function (_req: IImageRequest): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    } {
        throw new Error('Function not implemented.');
    },
    editPatient: function (_req: IPatient): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    deletePatient: function (_req: IPatientRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    getPatient: function (_req: IPatientRequest): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },
    getPatientList: function (
        _requestParams: IListRequestParams,
        _filter: PatientsFilter,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },
    updateScreenerSteps: function (_req: IScreenerRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    editAssign: function (_req: IPatientAssign): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    editAssignBulk: function (_req: IPatientAssign): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    navigateToView: function (_id: string): void {
        throw new Error('Function not implemented.');
    },
    screenerVersions: function (_req: ISchemaRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    } {
        throw new Error('Function not implemented.');
    },
    getPatientSchemaVersion: function (_req: ISchemaRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    } {
        throw new Error('Function not implemented.');
    },
    getChallenges: function (_req: IChallengeRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },
    makeChallengeUrgent: function (_req: IChallengeRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    saveNotes: function (_req: ISaveNotesRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    saveChallengeNotes: function (_req: ISaveChallengeNotesRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    getSolutionRowData: function (_req: ISolutionRequest): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },
    saveSolutionNote: function (_req: ISolutionNoteRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    savePatientResource: function (_req: IPatientResourceRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    editPatientResource: function (_req: IPatientResourceRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    navigateToViewWithParams: function (_id: string, _refetch?: boolean | undefined): void {
        throw new Error('Function not implemented.');
    },
    getResources: function (_req: string): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },
    getFile: function (
        _req: string,
        _uploadType?: string,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },

    getPatientLetterSchemaVersion: function (_req: ISchemaRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    } {
        throw new Error('Function not implemented.');
    },
    updateLetterSchemaValues: function (_req: ILetterRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    letterSchemaResponse: undefined,
    getNoteHistory: function (_req: string): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    } {
        throw new Error('Function not implemented.');
    },
    saveServeyNotes: function (_req: IServeyNoteRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    updateServeyNotes: function (_req: IServeyNoteRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    updateNotes: function (_req: IServeyNoteRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    toggleTodoCheckbox: function (_req: ITodoToggleRequestParams): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.')
    },
    getPatinentTimeLine: function (_patientId: string): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },

    getPatientChallengeTimeLine: function (_patientId: string, _challengeId: string): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },

    setSchemaRequestParam: function (_req: ILetterRequest): void {
        throw new Error('Function not implemented.');
    },
    schemaRequestParam: {
        patientId: '',
        solutionId: '',
        patientChallengeId: '',
        configJSON: {
            form: undefined,
        },
        varientId: '',
        isSave: false,
    },
    getPatientLetterSchemaReleaseInfo: function (_req: string): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    } {
        throw new Error('Function not implemented.');
    },
    navigateToLetterView: function (_eq: INavigationProps): void {
        throw new Error('Function not implemented.');
    },
    uploadResourceInfo: function (_req: IImageCreateRequest): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    deleteResourceInfo: function (_reqParams: string): Promise<ApiResponse> {
        throw new Error('Function not implemented.');
    },
    getReleaseInfoFiles: function (_req: IResourcesCreateRequest): Promise<ApiResponse> {
        throw new Error('Function not implemented.');
    },
    navigateToAdd: function (
        _patientId: string,
        _refetch?: boolean | undefined,
        _caseMngerIndex?: boolean | undefined,
        _fileId?: string | undefined,
    ): void {
        throw new Error('Function not implemented.');
    },
    updateLetterSchemaValuesForRInfo: function (_req: ILetterRequestRInfo): Promise<ApiResponse | undefined> {
        throw new Error('Function not implemented.');
    },
    uploadResourceInfoForm: function (_createRequest: IExistingResourceRequest): Promise<ApiResponse> {
        throw new Error('Function not implemented.');
    },
};

export const ReportingPropsInit: IReportingHOCProps = {
    isFetching: false,
    getReporting: function (_req: IReportingRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } {
        throw new Error('Function not implemented.');
    },
};
