import { Logo, Typhography } from 'dfh-ui-library';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { AUTHBG, LOGIN_LEFT_IMAGE } from 'utils/images/Images';

const AuthLayout: React.FC = () => {
    return (
        <div className='flex lg:flex-row  w-full h-screen'>
            <section className='lg:w-5/12 p-5 sm:p-5 md:p-5 h-full flex justify-center sm:w-full md:w-full'>
                <Typhography component='div' additionalClasses='w-4/6 sm:w-full sm:p-4'>
                    <Logo iconType='authLogo' additionalClasses='mb-12' />
                    <Outlet />
                </Typhography>
            </section>
            <section
                style={{ backgroundImage: `url(${AUTHBG})`, backgroundSize: 'cover' }}
                className='md:w-7/12 lg:w-7/12  h-full flex justify-center bg-gray-200 pt-[150px] bg-repeat sm:hidden lg:block md:hidden xl:block'
            >
                <Typhography component='div'>
                    <img src={LOGIN_LEFT_IMAGE} className='object-cover' width='245' />
                </Typhography>
                <Typhography
                    component='div'
                    additionalClasses='absolute p-10 bg-gradient-to-r from-[#40608E] to-[#21406D] gap-20 rounded-[20px] top-[405px] mx-20 mb-20 text-white '
                >
                    <Typhography component='span' additionalClasses='flex text-2xl font-semibold mb-5'>
                        Empowering action with real-time solutions
                    </Typhography>
                    <Typhography component='p' additionalClasses='text-21s'>
                        Docs for Health goes beyond traditional screening by facilitating real-time, on-the-ground
                        interventions. We provide a suite of solutions, including forms, form letters, and the
                        principles of medical-legal partnerships, enabling healthcare providers to take meaningful
                        action based on screening results.
                    </Typhography>
                </Typhography>
            </section>
        </div>
    );
};

export default AuthLayout;
