import { useEffect, useState } from 'react';
import homeHOC from '..';
import { useNavigate } from 'react-router-dom';
import {
    IconInput,
    ListMasterDataProps,
    PanelCard,
    TabButton,
    Todos,
    Typhography,
    UrgentChallenges,
    UrgentDataProps,
} from 'dfh-ui-library';
import BadgeDashboard from 'components/Badge/Badge';
import RouteRegistry from 'routes/routeRegistry';
import { get } from 'lodash';
import { IHomeHOCProps, ITodoToggleRequestParams } from 'utils/types/patient.type';
import LoadingProgress from 'components/Loading/LoadingProgress';
import { EmojiTypes } from 'utils/enum';
import { IReactionRequest } from 'utils/types/api.type';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import UpdateNotification from '../components/updateNotification';
import { MetrixProps, NotificationProps, reactionProps } from 'utils/types/home.type';
import { InfoIcon40 } from 'containers/patientHOC/helpers/iconHelper';
import _ from 'lodash';

const localStorageService = LocalStorageService();

const HomePage = (props: IHomeHOCProps) => {
    const filterOptions = [
        {
            displayText: 'All',
            value: 'all',
        },
        {
            displayText: 'Me',
            value: 'me',
        },
    ];

    const masterData: ListMasterDataProps = {
        title: 'Urgent Challenges',
        actionText: 'See all',
        actionTextReves: 'Collapse All',
    };

    const emojiList = [
        { emoji: '👍', key: EmojiTypes.LIKE },
        { emoji: '😃', key: EmojiTypes.LAUGH },
        { emoji: '👏', key: EmojiTypes.CLAP },
        { emoji: '❤️', key: EmojiTypes.HART },
    ];
    const navigate = useNavigate();

    const [selectedValues, setSelectedValues] = useState<string>('all');
    const [dataUpdateCards, setDataUpdateCards] = useState<NotificationProps[]>([]);
    const [dataMetrix, setDataMetrix] = useState<MetrixProps>({
        resolvedCount: 0,
        solutionsCount: 0,
        challengeCount: 0,
    });
    const [dataChallenges, setDataChallenges] = useState<UrgentDataProps[]>([]);
    const [dataTodos, setDataTodos] = useState<UrgentDataProps[]>([]);
    const [dataFilteredTodos, setDataFilteredTodos] = useState<UrgentDataProps[]>([]);
    const user = localStorageService.getUser();
    const [noteText, setNoteText] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNoteText(e.target.value);
    };

    useEffect(() => {
        if (!dataTodos || !Array.isArray(dataTodos)) return;
        const filteredTodos = dataTodos.filter((value) => {
            if (!Array.isArray(value.notes)) return false;
            const fullNameMatches = value.fullName?.toLocaleLowerCase().includes(noteText?.toLocaleLowerCase());
            const filteredNotes = value.notes.flatMap((val) =>
                val.note.filter(
                    (filteredNote) =>
                        filteredNote?.enableCheckbox === true &&
                        filteredNote?.isChecked === false &&
                        filteredNote.text?.toLocaleLowerCase()?.includes(noteText?.toLocaleLowerCase()),
                ),
            );
            return filteredNotes.length > 0 || fullNameMatches;
        });

        // Only update state if the filtered results are different
        if (noteText === '') {
            setDataFilteredTodos(dataTodos);
        } else {
            setDataFilteredTodos((prev) => {
                if (JSON.stringify(prev) !== JSON.stringify(filteredTodos)) {
                    return filteredTodos;
                }
                return prev;
            });
        }
    }, [noteText, dataTodos]);

    const updateData = () => {
        const response = props.getDashboardUpdates(selectedValues === 'me' && user._id);
        if (response) {
            response.then((result: any) => {
                const newData = result.data.data.map((value: any) => ({
                    date: value.resolvedDate,
                    userName:
                        value.resolvedBy !== 'Individual'
                            ? value.resolvedByUser?.fullName
                            : `${value.patient.firstName} ${value.patient.lastName}`,
                    challenge: value?.challenge?.challengeName,
                    patientName: `${value.patient.firstName} ${value.patient.lastName}`,
                    noteId: value.resolvedBy !== 'Individual' && value?.note?._id,
                    notes: value.resolvedBy !== 'Individual' && value?.note?.note,
                    id: value._id,
                    cId: value._id,
                    reactions: makeReactions(value?.reactions, value._id),
                    themeColor: value.resolvedByUser?.themeColor,
                }));
                setDataUpdateCards(newData);
            });
        }
    };

    useEffect(() => {
        const fetchDashboardUpdates = () => {
            updateData();
        };
        fetchDashboardUpdates();
        const intervalId = setInterval(fetchDashboardUpdates, 10000);
        return () => clearInterval(intervalId);
    }, [selectedValues]);

    const fetchTodos = () => {
        const responseTodos = props.getDashboardTodosChallenges(user._id);
        if (responseTodos) {
            responseTodos.then((resultT: any) => {
                const updatedData = resultT.data.data.map((item: any) => ({
                    ...item,
                    id: item.patientId,
                }));

                setDataTodos(updatedData);
                setDataFilteredTodos(updatedData);
            });
        }
    };

    useEffect(() => {
        const responseSummary = props.getDashboardMetrix(selectedValues === 'me' && user._id);
        if (responseSummary) {
            responseSummary.then((resultS: any) => {
                setDataMetrix(resultS.data.data);
            });
        }
        const responseChallenge = props.getDashboardUrgentChallenges(user._id);
        if (responseChallenge) {
            responseChallenge.then((resultC: any) => {
                const updatedChallengeData = resultC.data.data.map((challenge: any) => ({
                    ...challenge,
                    id: challenge._id,
                }));

                setDataChallenges(updatedChallengeData);
            });
        }

        const fetchDashboardTodos = () => {
            fetchTodos();
        };
        fetchDashboardTodos();
    }, [selectedValues]);

    const addUpdateReaction = (id: string, _emoji: string, key: string, variNum: number, userId: string) => {
        const result = updateReactionEndPoint(userId, id, key, variNum);
        result.then((_resultUpdate: any) => {
            updateData();
        });
    };

    const updateReactionEndPoint = async (_userId: string, id: string, key: string, increment: number) => {
        const req: IReactionRequest = {
            userId: user && user._id,
            emoji: key,
            action: increment > 0 ? 'add' : 'remove',
            chId: id,
        };
        const response = await props.updateReaction(req);
        if (response) {
            updateData();
        }
    };

    const toggleDashboardTodoCheckbox = (req: ITodoToggleRequestParams) => {
        const result = toggleDashboardTodoCheckboxEndPoint({ ...req, actionedUser: user._id });
        result.then((_updated: any) => {
            updateData();
        });
    };

    const toggleDashboardTodoCheckboxEndPoint = async (req: ITodoToggleRequestParams) => {
        const response = await props.toggleDashboardTodoCheckbox(req);
        if (response) {
            updateData();
        }
    };

    const makeReactions = (reaction: any, id: string) => {
        const user = localStorageService.getUser();
        if (reaction) {
            const tempArray = emojiList
                .map(({ emoji, key }) => ({
                    emoji,
                    key,
                    isCurrentUserReacted: reaction[key]
                        ? _.filter(reaction[key], function (item) {
                              return item.userId === user._id;
                          }).length > 0
                        : false,
                    count: reaction[key]?.length || 0,
                }))
                .filter(({ count }) => count > 0);
            const tempObj: reactionProps = { id, emoji: tempArray };

            return [tempObj];
        }
    };

    const onClickTab = (item: string) => {
        setSelectedValues(item);
    };

    const solutionNavigation = (itemsId: string | undefined) => {
        navigate(`${get(RouteRegistry, 'patient.paths.view.path')}/${itemsId}`, {
            state: { isEnableSolution: true },
        });
    };

    const getUrgentCout = (getUrgentData: UrgentDataProps[]) => {
        return getUrgentData.flatMap((items) => items.challenges).length;
    };
    const getTodosCount = () => {
        const filteredNotes = dataTodos.flatMap((value) => {
            // Check if value.note?.note is an array
            if (Array.isArray(value.notes)) {
                return value?.notes.flatMap((val) =>
                    val.note.filter(
                        (filteredNotes) => filteredNotes?.enableCheckbox === true && filteredNotes?.isChecked === false,
                    ),
                );
            }
            return []; // Return an empty array if not
        });
        return filteredNotes?.length ?? 0;
    };

    const handleNameClick = (id?: string) => {
        navigate(`${RouteRegistry.patient.paths.view.path}/${id}`);
    };

    const handleChallengeHeaderClick = (patientId?: string, challengeId?: string) => {
        const stateObject = {
            patientId: patientId,
            challengeId: challengeId,
            isEnableSolution: true,
        };
        navigate(`${RouteRegistry.patient.paths.view.path}/${patientId}`, { state: stateObject });
    };

    return (
        <div className='max-w-full h-screen' style={{ height: 'calc(100vh - 100px)' }}>
            <div className='relative'>{props.isFetching && <LoadingProgress isLoading={props.isFetching} />}</div>
            <div className='flex flex-row pt-3 space-x-4 h-full'>
                <section className='w-2/4 box-content flex flex-col'>
                    <Typhography component='div' additionalClasses='flex justify-between items-center'>
                        <h1 className='font-medium text-lg py-3'>Dashboard</h1>
                        <Typhography component='div' additionalClasses='w-1/6'>
                            <TabButton
                                options={filterOptions}
                                onClickTab={onClickTab}
                                setSelectedValues={setSelectedValues}
                                selectedValues={selectedValues}
                                additionalClasses='gap-2 my-4'
                            />
                        </Typhography>
                    </Typhography>

                    <section className='flex space-x-4 w-full justify-between'>
                        <div
                            className='bg-white border rounded-md px-4 w-1/3  md:mb-0 justify-between flex align-middle items-center'
                            style={{ height: '50px' }}
                        >
                            <div className='text-xs w-1/2 py-3'>Challenges</div>
                            <div className='font-medium flex align-middle'>
                                <span className='align-middle inline-block'>{dataMetrix.challengeCount}</span>
                            </div>
                        </div>
                        <div
                            className='bg-white border rounded-md px-4 w-1/3  md:mb-0 justify-between flex align-middle items-center'
                            style={{ height: '50px' }}
                        >
                            <div className='text-xs w-1/2 py-3'>Challenges resolved</div>
                            <div className='font-medium'>{dataMetrix.resolvedCount}</div>
                        </div>
                        <div
                            className='bg-white border rounded-md px-4 w-1/3  md:mb-0 justify-between flex align-middle items-center'
                            style={{ height: '50px' }}
                        >
                            <div className='text-xs w-2/3 py-3'>Solutions implemented</div>
                            <div className='font-medium'>{dataMetrix.solutionsCount}</div>
                        </div>
                    </section>
                    <section className='flex flex-col bg-white'>
                        <PanelCard
                            additionalClasses='mt-4'
                            headerTitle='Updates'
                            content={
                                dataUpdateCards.length > 0 ? (
                                    <div className='h-full overflow-y-auto' style={{ height: 'calc(100vh - 309px)' }}>
                                        {dataUpdateCards.map((notification, index) => (
                                            <UpdateNotification
                                                key={index}
                                                {...notification}
                                                updateReactionEndPoint={updateReactionEndPoint}
                                                emojiList={emojiList}
                                                addUpdateReaction={addUpdateReaction}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div
                                        className='flex flex-col justify-center items-center'
                                        style={{ height: 'calc(100vh - 309px)' }}
                                    >
                                        <InfoIcon40 />
                                        <Typhography component='div'>No updates to show</Typhography>
                                    </div>
                                )
                            }
                        />
                    </section>
                </section>

                <section className='w-1/4 xl:w-1/4 mt-3 lg:mt-0 bg-white rounded-md border flex flex-col flex-grow overflow-hidden'>
                    <Typhography component='div' additionalClasses='p-4 flex-grow'>
                        <BadgeDashboard
                            count={getUrgentCout(dataChallenges)}
                            color='bg-[#F23A5C]'
                            title='My Urgent Challenges'
                        />
                        {dataChallenges.length > 0 ? (
                            <div className='h-full overflow-y-auto' style={{ height: 'calc(100vh - 220px)' }}>
                                <UrgentChallenges
                                    urgentDataList={dataChallenges}
                                    listMasterData={masterData}
                                    handleNameClick={handleNameClick}
                                    handleChallengeHeaderClick={handleChallengeHeaderClick}
                                />
                            </div>
                        ) : (
                            <Typhography
                                component='div'
                                additionalClasses='flex flex-col justify-center items-center my-20'
                            >
                                <InfoIcon40 />
                                <Typhography component='div'>No urgent challenges to show</Typhography>
                            </Typhography>
                        )}
                    </Typhography>
                </section>

                <section className='w-1/4 xl:w-1/4 mt-3 lg:mt-0 bg-white rounded-md border flex flex-col flex-grow overflow-hidden'>
                    <Typhography component='div' additionalClasses='p-4 flex-grow'>
                        <BadgeDashboard count={getTodosCount()} color='bg-blue-500' title='My To-Dos' />
                        <Typhography component='div' additionalClasses='pt-3'>
                            <IconInput
                                type='text'
                                value={noteText}
                                onChange={handleChange}
                                isAdditionalErrorInput={true}
                                placeholder='Search for To-Dos...'
                                labelType='formLabelMedeum'
                                isIconRight={true}
                                rightIcon={'Search'}
                                iconClassRight='absolute top-3 right-4'
                                labelSpanClasses='text-21s'
                                additionalClasses='mt-0 h-11'
                            />
                        </Typhography>
                        {dataFilteredTodos.length > 0 ? (
                            <div className='h-full overflow-y-auto' style={{ height: 'calc(100vh - 270px)' }}>
                                <Todos
                                    urgentDataList={dataFilteredTodos}
                                    listMasterData={masterData}
                                    detailAction={solutionNavigation}
                                    handleNameClick={handleNameClick}
                                    toggleTodoCheckbox={toggleDashboardTodoCheckbox}
                                />
                            </div>
                        ) : (
                            <Typhography
                                component='div'
                                additionalClasses='flex flex-col justify-center items-center my-20'
                            >
                                <InfoIcon40 />
                                <Typhography component='div'>No to-dos to show</Typhography>
                            </Typhography>
                        )}
                    </Typhography>
                </section>
            </div>
        </div>
    );
};

const HomePageComponent = homeHOC(HomePage);

export default HomePageComponent;
