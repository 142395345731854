import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import User from 'utils/types/user.type';
import initUser from '../utils/init/index';
import { globalsAPI } from 'api/globalsAPI';

interface IState {
    isLoading: boolean;
    isSuccess: boolean;
    user: User;
    primaryLanguages: string[];
    races: string[];
    educations: string[];
    relationshipStatuses: string[];
    ethnicities: string[];
    genders: string[];
    housingStatuses: string[];
    mailingAddresses: string[];
    resourceTypes: string[];
}

const initialState: IState = {
    isLoading: false,
    isSuccess: false,
    user: initUser,
    primaryLanguages: [],
    races: [],
    educations: [],
    relationshipStatuses: [],
    ethnicities: [],
    genders: [],
    housingStatuses: [],
    mailingAddresses: [],
    resourceTypes: [],
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setState: (state: IState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setLoading: (state: IState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },

        setIsSuccess: (state: IState, action: PayloadAction<boolean>) => {
            state.isSuccess = action.payload;
        },
        setUser: (state: IState, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(globalsAPI.endpoints.getPrimaryLanguage.matchFulfilled), (state, action) => {
                state.primaryLanguages = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getRace.matchFulfilled), (state, action) => {
                state.races = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getEducation.matchFulfilled), (state, action) => {
                state.educations = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getRelationshipStatus.matchFulfilled), (state, action) => {
                state.relationshipStatuses = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getEthnicity.matchFulfilled), (state, action) => {
                state.ethnicities = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getGender.matchFulfilled), (state, action) => {
                state.genders = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getHousingStatus.matchFulfilled), (state, action) => {
                state.housingStatuses = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getMailingAddress.matchFulfilled), (state, action) => {
                state.mailingAddresses = action.payload.data.values;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(globalsAPI.endpoints.getResourceTypes.matchFulfilled), (state, action) => {
                state.resourceTypes = action.payload.data.values;
                state.isLoading = false;
            });
    },
});

export const { setState, setLoading, setUser } = appSlice.actions;

export const selectIsLoading = (state: RootState) => state.app.isLoading;
export const selectIsSuccess = (state: RootState) => state.app.isSuccess;
export const selectUser = (state: RootState) => state.app.user;
export const getPrimaryLanguages = (state: RootState) => state.app.primaryLanguages;
export const getRaces = (state: RootState) => state.app.races;
export const getEducations = (state: RootState) => state.app.educations;
export const getRelationshipStatuses = (state: RootState) => state.app.relationshipStatuses;
export const getEthnicities = (state: RootState) => state.app.ethnicities;
export const getGenders = (state: RootState) => state.app.genders;
export const getHousingStatus = (state: RootState) => state.app.housingStatuses;
export const getMailingAddresses = (state: RootState) => state.app.mailingAddresses;
export const getResourceTypes = (state: RootState) => state.app.resourceTypes;
export default appSlice.reducer;
