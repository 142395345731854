import { IAuthToken } from 'utils/types/auth.type';

const TIMELINE_SELECTED_TABS_FIELDNAME = 'timeline_selected_tabs';

export const LocalStorageService = () => {
    const setAuthTokens = (tokenObj: IAuthToken) => {
        localStorage.setItem('access_token', tokenObj.accessToken);
        localStorage.setItem('refresh_token', tokenObj.refreshToken);
        localStorage.setItem('id_token', tokenObj.idToken);
        localStorage.setItem('user', JSON.stringify(tokenObj.user));
    };

    const getAccessToken = () => {
        return localStorage.getItem('access_token');
    };

    const getRefreshToken = () => {
        return localStorage.getItem('refresh_token');
    };

    const getIdToken = () => {
        return localStorage.getItem('id_token');
    };

    const getUser = () => {
        const userJson = localStorage.getItem('user');
        if (userJson) {
            try {
                return JSON.parse(userJson);
            } catch (error) {
                console.error('Failed to parse user JSON:', error);
                return null;
            }
        }
        return null;
    };

    const clearAuthTokens = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('user');
    };

    const setSelectedTimelineTabs = (selectedTab: number[]) => {
        localStorage.setItem(TIMELINE_SELECTED_TABS_FIELDNAME, JSON.stringify(selectedTab));
    }

    const getSelectedTimelineTabs = () => {
        return localStorage.getItem(TIMELINE_SELECTED_TABS_FIELDNAME)
    }

    const clearSelectedTimelineTabs = () => {
        localStorage.removeItem(TIMELINE_SELECTED_TABS_FIELDNAME)
    }

    return {
        setAuthTokens,
        setSelectedTimelineTabs,
        getAccessToken,
        getRefreshToken,
        getIdToken,
        getUser,
        getSelectedTimelineTabs,
        clearAuthTokens,
        clearSelectedTimelineTabs
    };
};
