import { IListRequestParams } from 'utils/types/api.type';
import { IAddress, IPatient, PermanentAddressType, ResponsibleType } from 'utils/types/patient.type';

export const initPatient: IPatient = {
    imageId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    enrollmentDate: new Date(),
    race: '',
    ethnicity: '',
    relationshipStatus: '',
    gender: '',
    dob: null,
    email: '',
    phoneNumber: '',
    socialSecurityNumber: '',
    personalIncome: '',
    householdIncome: '',
    householdSize: '',
    medicalProvider: '',
    emergencyContactName: '',
    emergencyContactRelationship: '',
    emergencyContact: '',
    bestWayToContact: '',
    housingStatus: '',
    mailingAddress: '',
    address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
    } as IAddress,
    responsibleIndividual: {
        _id: '',
        fullName: '',
    },
    externalCaseManagers: [
        {
            fullName: '',
            email: '',
            phoneNumber: '',
            agency: '',
            notes: '',
        },
    ],
    primaryLanguage: '',
    education: '',
};

export const sexTypes: any[] = [
    { id: 1, value: 'Male' },
    { id: 2, value: 'Female' },
    { id: 3, value: 'Other' },
];

export const responsibleTypes: { value: ResponsibleType; displayName: string }[] = [
    {
        value: ResponsibleType.ME,
        displayName: 'Me',
    },
    {
        value: ResponsibleType.REGISTERGED,
        displayName: 'Registered User',
    },
    {
        value: ResponsibleType.UNREGISTERGED,
        displayName: 'Unregistered User',
    },
];

export const permanentAddressTypes: { value: PermanentAddressType; displayName: string }[] = [
    {
        value: PermanentAddressType.HOMELESS_NO_ADDRESS,
        displayName: 'Homeless, need a permanent mailing address to receive vital documents',
    },
    {
        value: PermanentAddressType.Homeless_WITH_ADDRESS,
        displayName: 'Homeless, uses this permanent address to receive vital documents',
    },
    {
        value: PermanentAddressType.HOUSED,
        displayName: 'Housed with the following permanent address',
    },
];

export const initPatientParams: IListRequestParams = { sortby: 'fullName:asc', skip: 0, limit: 10, filters: undefined };
