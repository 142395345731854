import { useEffect, useState } from "react";
import { NoteEditor, NoteProps, Typho, Typhography } from "dfh-ui-library";
import { IScreenerNote } from "utils/types/patient.type";

const ScreenerNoteComponent = (props: IScreenerNote) => {
    const [userNote, setUserNote] = useState<NoteProps>();

    useEffect(() => {
      if (props.patientSchema) {
        setUserNote(props.patientSchema.note);
      }
    }, [props.patientSchema, userNote]);

    return (
        <>
            <Typho
              type="div"
              additionalClasses="text-14s font-semibold text-black-540 justify-self-start ml-4 my-6"
            >
              Updates and Next Steps
            </Typho>
            <Typhography component='div' additionalClasses='justify-between align-middle pb-4 pr-4 pl-2'>
                <NoteEditor
                    onAdd={props.handleAddItem}
                    cancelAction={() => props.setShowNote(false)}
                    showNote={props.showNote}
                    additionalClassesNote='pl-2 overflow-y-auto'
                    additionalClassesButtons='pl-2'
                    additionalClassesTitlebar="pl-2"
                    minHeight='calc(100vh - 550px)'
                    savedItems={userNote}
                    isChallengeNote={true}
                />
            </Typhography>
        </>
    )
}

export default ScreenerNoteComponent