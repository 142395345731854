import { Button, IconInput, LoadingSpinner, Message, Typhography } from 'dfh-ui-library';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ISignUpProps, passwordArgProps, validationProps } from '../../../utils/types/auth.type';
import { inputTypeInit, signupInitValidationValues } from '../../../utils/init';
import signUpHOC from '../index';
import { getRandomColor } from '../../../utils/helpers';

const Signup = (props: ISignUpProps) => {
    const [passwordArgs, setPasswordArgs] = useState<passwordArgProps>(inputTypeInit);
    const [valSpecs, setValSpecs] = useState<validationProps>(signupInitValidationValues);

    const validatePassword = (password: string): boolean => {
        const hasNumber = /\d/.test(password);
        const hasSpecialCharacter = /[!@#$%^&*]/.test(password);
        const valData: validationProps = {
            hasNumber: hasNumber,
            hasSpesCharactor: hasSpecialCharacter,
            hasAllCharactors: password.length >= 8,
        };
        setValSpecs(valData);
        return hasNumber && hasSpecialCharacter;
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string()
                .required('Password is required')
                .test('custom-validation', '', (value) => {
                    return validatePassword(value || '');
                }),
        }),
        onSubmit: async (values) => {
            const signUpResult = await props.apiMethods.signUp({
                email: values.email,
                password: values.password,
                themeColor: getRandomColor(),
            });
            if (signUpResult?.data) {
                formik.resetForm();
                props.navigateMethods.navigateToVerification(values.email);
            }
        },
    });

    // Show/Hide password
    const showHidePassword = () => {
        if (passwordArgs.type === 'password') {
            setPasswordArgs({
                type: 'text',
                rightIcon: 'EyePswOff',
            });
        } else {
            setPasswordArgs({
                type: 'password',
                rightIcon: 'EyePsw',
            });
        }
    };

    return (
        <>
            {props.isLoading && <LoadingSpinner />}
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <Typhography component='div' additionalClasses='mt-12'>
                <Typhography type='authHeader'>Sign Up</Typhography>
                <form onSubmit={formik.handleSubmit}>
                    <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                        <Typhography component='div' additionalClasses='py-2'>
                            <Typhography component='div'>
                                <IconInput
                                    iconClassLeft={`top-5 left-4 ${formik.values.email ? '' : 'opacity-50'}`}
                                    isIconLeft
                                    leftIcon='Email'
                                    label='Email'
                                    placeholder='example@mail.com'
                                    name='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                                    isAdditionalErrorInput={true}
                                    additionalErrorClasses='text-red-500 text-xs pt-1'
                                    isBorderedError={formik.errors.email ? true : false}
                                />
                            </Typhography>
                        </Typhography>
                        <Typhography additionalClasses='py-2'>
                            <Typhography>
                                <Typhography additionalClasses='flex flex-col relative'>
                                    <IconInput
                                        iconClassLeft={`top-4 left-4 absolute ${formik.values.password ? '' : 'opacity-50'}`}
                                        iconClassRight='right-1.5 top-1/2 transform -translate-y-1/2'
                                        iconOnClick={showHidePassword}
                                        isIconLeft
                                        isIconRight
                                        leftIcon='Password'
                                        type={passwordArgs.type}
                                        rightIcon={passwordArgs.rightIcon}
                                        label='Password'
                                        placeholder='*******'
                                        name='password'
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.password && formik.errors.password
                                                ? formik.errors.password
                                                : ''
                                        }
                                        isAdditionalErrorInput={true}
                                        additionalErrorClasses='text-red-500 text-xs pt-1'
                                        minLength={8}
                                        isBorderedError={formik.errors.password ? true : false}
                                    />
                                    <Typhography
                                        component='div'
                                        additionalClasses='flex flex-row justify-start mt-3 mb-2 text-xs'
                                    >
                                        <Typhography
                                            component='span'
                                            additionalClasses={
                                                valSpecs?.hasAllCharactors ? 'text-black' : 'text-gray-400'
                                            }
                                        >
                                            &#x2714; Min 8 characters
                                        </Typhography>
                                        <Typhography
                                            component='span'
                                            additionalClasses={
                                                valSpecs?.hasNumber ? 'text-black mx-3' : 'text-gray-400 mx-3'
                                            }
                                        >
                                            &#x2714; 1 Number
                                        </Typhography>
                                        <Typhography
                                            component='span'
                                            additionalClasses={
                                                valSpecs?.hasSpesCharactor ? 'text-black' : 'text-gray-400'
                                            }
                                        >
                                            &#x2714; 1 Special character
                                        </Typhography>
                                    </Typhography>
                                </Typhography>
                            </Typhography>
                        </Typhography>

                        <Typhography additionalClasses='my-8'>
                            <Button
                                buttonLabel='Sign Up'
                                variants='large'
                                type='submit'
                                additionalClasses='bg-[#006CD0] cursor-pointer hover:bg-[#006CD0]'
                                isDisabled={!(formik.isValid && formik.dirty && valSpecs.hasAllCharactors)}
                            ></Button>
                        </Typhography>
                        <Typhography additionalClasses='text-center'>
                            <Typhography component='p'>
                                <Typhography type='textMediumGray' additionalClasses='text-gray-400'>
                                    Already have an account?
                                </Typhography>

                                <Typhography
                                    type='linkTextMedium'
                                    additionalClasses='cursor-pointer ml-1'
                                    onClick={props.navigateMethods.navigateToSignIn}
                                >
                                    Log In
                                </Typhography>
                            </Typhography>
                        </Typhography>
                    </Typhography>
                </form>
            </Typhography>
        </>
    );
};

const signUpComponent = signUpHOC(Signup);
export default signUpComponent;
