// import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { Typhography } from 'dfh-ui-library';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
    return (
        <>
            <Header />
            <Typhography component='div' additionalClasses='px-10 pb-5'>
                <Outlet />
            </Typhography>
            {/* <Footer /> */}
        </>
    );
};

export default AppLayout;
