import {
    BADGETYPE,
    Badge,
    BorderType,
    Button,
    CheckBoxComponent,
    Label,
    ListRow,
    NoteEditor,
    NoteItemProps,
    NoteProps,
    RegularDropdown,
    Typhography,
} from 'dfh-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { language } from '../helpers/language';
import { ISaveChallengeNotesRequest, ISolution, ISolutionNoteRequest, IVariation, solutionTypes } from 'utils/types/patient.type';
import { ActionTypes, CHALLENGE_STATUS } from 'utils/enum';
import { EmailIcon, PhoneIcon } from '../helpers/iconHelper';
import User from 'utils/types/user.type';
import moment from 'moment';

const RowDetails: React.FC<{
    item: any;
    challengeData: any;
    showNote: boolean;
    setShowNote?: (status: boolean) => void;
    rowClick?: boolean;
    updateChallenge?: (req: ISaveChallengeNotesRequest) => void;
    saveSolutionNote?: (req: ISolutionNoteRequest) => void;
    solution?: ISolution[] | undefined;
    referalAction?: (solution: ISolution) => void;
    actionType?: ActionTypes;
    actionData?: ISolution;
    cancelAction?: () => void;
    setRowClick?: (isRowClick: boolean) => void;
    navigateToLetterView?: (
        challengeId: string,
        veriantId: string,
        varient: string,
        solutionId: string,
        tempChallageId: string,
    ) => void;
    showChallengeTimeline?: (status: boolean) => void;
    copiedNote?: NoteProps;
    showNoteEdit?: boolean;
    setShowNoteEdit?: (value: boolean) => void;
}> = ({
    item,
    challengeData,
    showNote,
    setShowNote,
    rowClick,
    updateChallenge,
    saveSolutionNote,
    solution,
    referalAction,
    actionType,
    actionData,
    cancelAction,
    setRowClick,
    navigateToLetterView,
    showChallengeTimeline,
    copiedNote,
    showNoteEdit,
    setShowNoteEdit
}) => {
    const [selectedValuesReferral, setSelectedValuesReferral] = useState<{
        displayText: string;
        value: string;
    }>({
        displayText: '',
        value: '',
    });
    const [selectedValuesLetter, setSelectedValuesLetter] = useState<{
        displayText: string;
        value: string;
    }>({
        displayText: '',
        value: '',
    });
    const [selectedValuesForm, setSelectedValuesForm] = useState<{
        displayText: string;
        value: string;
    }>({
        displayText: '',
        value: '',
    });

    const defaultItem = {
        note: [{ text: '', enableCheckbox: false, isChecked: false }],
        _id: '',
    };
    const [savedItem, setSavedItem] = useState<NoteProps>(defaultItem);
    const userJson = localStorage.getItem('user');
    const userDetails: User = userJson ? JSON.parse(userJson) : null;
    const rowDetailsComponentRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        rowDetailsComponentRef && rowDetailsComponentRef.current?.scrollIntoView(true);
    }, [rowClick]);

    useEffect(() => {
        const updatedNote = challengeData?.find((challenge: any) => challenge._id === item?._id)?.note;
        setSavedItem(updatedNote);
    }, [challengeData]);

    useEffect(() => {
        if (copiedNote) {
            setSavedItem(copiedNote)
        } else if (item.note) {
            setSavedItem(item.note);
        } else {
            setSavedItem(defaultItem);
        }
    }, [item, copiedNote]);

    const handleSelectedValuesChange = (newValues: { displayText: string; value: string }, solution?: ISolution) => {
        const foundRecord =
            solution && solution.variants ? solution.variants.find((item) => item._id === newValues.value) : null;

        const newSolution = {
            ...solution,
            variants: foundRecord ? [foundRecord] : [],
        };

        switch (solution?.solutionType) {
            case 'Referral': {
                referalAction && referalAction(newSolution);
                setSelectedValuesReferral(newValues);
                break;
            }
            case 'Letter': {
                setSelectedValuesLetter(newValues);
                letterBtnClick(newSolution, newValues.displayText);
                break;
            }
            case 'Form': {
                setSelectedValuesForm(newValues);
                formBtnClick(newSolution, newValues.displayText);
                break;
            }
            default:
                return null;
        }
    };

    const solutionNote = (items: NoteItemProps[], _id: string) => {
        if (actionData?._id && actionData.variants)
            saveSolutionNote &&
                saveSolutionNote({
                    solutionId: actionData._id,
                    solutionVariantId: actionData.variants[0]._id || '',
                    note: items,
                    patient: '',
                    patientChallenge: '',
                });
    };

    const handleAddChallengeNote = async (_items: NoteProps, patientChallengeId: string, isResolved: boolean = false) => {
        if (updateChallenge && _items.note && patientChallengeId) {
            updateChallenge({
                id: patientChallengeId,
                note: _items,
                actionedUser: userDetails._id,
                status: isResolved ? CHALLENGE_STATUS.RESOLVED : item.status,
            });
        }
    };

    const controllersForm = (solution: ISolution) => (
        <>
            {solution && solution?.variants && solution?.variants?.length > 1 ? (
                    <RegularDropdown
                        bgClasses='bg-blue-550 text-white'
                        defaultText='Letter'
                        handleSelectedValuesChange={(newValue: any) => handleSelectedValuesChange(newValue, solution)}
                        hoverBgColor='bg-sky-700'
                        options={getDropdownObject(solution?.variants)}
                        selectedValues={selectedValuesForm}
                        additionalClasses='item-center'
                    />
                ) : (
                    <Button
                        additionalClasses='text-21s font-normal items-center !w-auto'
                        buttonLabel='Fill out'
                        data-testId='InputField-id'
                        variants='smallBlue'
                        onClick={() => formBtnClick(solution, '')}
                    />
            )}
        </>
    );

    const letterBtnClick = (solution: ISolution, varient: string) => {
        if (solution && solution?.variants && solution?.variants[0]) {
            navigateToLetterView &&
                navigateToLetterView(
                    item._id,
                    solution?.variants[0]?._id ?? '',
                    varient ?? solution?.variants[0].solutionVariantName,
                    solution?._id ?? '',
                    item.challenge._id,
                );
        }
    };

    const formBtnClick = (solution: ISolution, varient: string) => {
        if (solution && solution?.variants && solution?.variants[0]) {
            navigateToLetterView &&
                navigateToLetterView(
                    item._id,
                    solution?.variants[0]?._id ?? '',
                    varient ?? solution?.variants[0].solutionVariantName,
                    solution?._id ?? '',
                    item.challenge._id,
                );
        }
    };

    const controllersLetter = (solution: ISolution) => {
        return (
            <>
                {solution && solution?.variants && solution?.variants?.length > 1 ? (
                    <RegularDropdown
                        bgClasses='bg-blue-550 text-white'
                        defaultText='Letter'
                        handleSelectedValuesChange={(newValue: any) => handleSelectedValuesChange(newValue, solution)}
                        hoverBgColor='bg-sky-700'
                        options={getDropdownObject(solution?.variants)}
                        selectedValues={selectedValuesLetter}
                        additionalClasses='item-center'
                    />
                ) : (
                    <Button
                        additionalClasses='text-21s font-normal items-center !w-auto'
                        buttonLabel='Letter'
                        data-testId='InputField-id'
                        variants='smallBlue'
                        onClick={() => letterBtnClick(solution, '')}
                    />
                )}
            </>
        );
    };

    const getDropdownObject = (variants: IVariation[]) => {
        const transformedArray = variants.map(({ _id, solutionVariantName }) => ({
            displayText: solutionVariantName ?? '',
            value: _id ?? '',
        }));
        return transformedArray;
    };

    const controllersReferal = (solution: ISolution) => (
        <>
            {solution && solution?.variants && solution?.variants?.length > 1 ? (
                <RegularDropdown
                    bgClasses='bg-blue-550 text-white'
                    defaultText='Referral'
                    handleSelectedValuesChange={(newValue: any) => handleSelectedValuesChange(newValue, solution)}
                    hoverBgColor='bg-sky-700'
                    options={getDropdownObject(solution?.variants)}
                    selectedValues={selectedValuesReferral}
                    additionalClasses='item-center'
                />
            ) : (
                <Button
                    additionalClasses='text-21s font-normal items-center !w-auto !min-w-[120px]'
                    buttonLabel='Referral'
                    data-testId='InputField-id'
                    variants='smallBlue'
                    onClick={() => referalAction && referalAction(solution)}
                />
            )}
            <></>
        </>
    );

    // Function to get controller based on solution type
    const getControllerByType = (solution: ISolution, solutionType: solutionTypes): React.ReactNode => {
        switch (solutionType) {
            case 'Referral':
                return controllersReferal(solution);
            case 'Form':
                return controllersForm(solution);
            case 'Letter':
                return controllersLetter(solution);
            default:
                return null; // Handle other cases or return a default controller
        }
    };

    const solutionContents = (solutions: ISolution[]) => (
        <>
            {solutions
                .filter((solution) => solution.variants && solution.variants.length > 0)
                .map((solution, index) => (
                    <Typhography
                        component='div'
                        additionalClasses='flex my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg justify-between'
                        key={`${index}-id`}
                    >
                        <Typhography component='div' additionalClasses='flex-col'>
                            <Typhography component='div' additionalClasses='!text-25s'>
                                {solution.solutionName || ''}
                            </Typhography>
                            <Typhography
                                component='div'
                                additionalClasses='text-14 font-normal text-black-900  text-21s text-grey-540 font-normal mt-1'
                            >
                                {solution.shortDescription || ''}
                            </Typhography>
                        </Typhography>
                        <Typhography component='div' additionalClasses={`flex justify-end }`}>
                            {getControllerByType(solution, solution.solutionType)}
                        </Typhography>
                    </Typhography>
                ))}
        </>
    );

    const solutionNoteContent = (patientChallengeId: any) => (
        <Typhography component='div' additionalClasses='justify-between align-middle pr-4'>
            {!showNoteEdit ? (
                <>
                    <Typhography component='div'>
                        {solutionHeader(item.patientSolutions, item.challenge, solution?.length)}
                    </Typhography>
                    <Typhography
                        component='div'
                        additionalClasses='rounded border border-gray-19 p-3 ml-4 min-h-[150px] cursor-pointer'
                        onClick={() => {
                            setShowNoteEdit && setShowNoteEdit(true);
                        }}
                    >
                        <Typhography
                            component='div'
                            additionalClasses='flex justify-between items-center pb-3 border-b'
                        >
                            <Typhography component='div' additionalClasses='text-grey-540 text-12s'>
                                {savedItem && savedItem?.actionedUser?.fullName
                                    ? 'Edited ' +
                                      moment(savedItem?.date).format('MM/DD/YYYY') +
                                      ' by ' +
                                      savedItem?.actionedUser?.fullName
                                    : ''}
                            </Typhography>
                            <Typhography component='div' additionalClasses='flex items-center'>
                                <Badge
                                    additionalClasses='text-12s text-black-540 bg-primaryBlue text-nowrap !mr-0'
                                    badgeType={BADGETYPE.PILLS}
                                    colorVariant='gray'
                                >
                                    Incomplete
                                </Badge>
                            </Typhography>
                        </Typhography>
                        <Typhography component='div'>
                            {savedItem?.note &&
                                savedItem.note.length > 0 &&
                                savedItem.note?.map((item, index) => (
                                    <Typhography component='div' key={index} additionalClasses='text-12s pt-2'>
                                        {item && item?.enableCheckbox && (
                                            <>
                                                <CheckBoxComponent
                                                    onChange={() => {}}
                                                    checked={item.isChecked}
                                                    noteId={item.noteId}
                                                    noteIndex={item.noteIndex}
                                                />
                                                <Label
                                                    label={item?.text}
                                                    name='testname'
                                                    additionalClasses={`!text-12s ${item?.isChecked && 'line-through text-gray-95'} p-2`}
                                                />
                                            </>
                                        )}
                                        {!item?.enableCheckbox && (
                                            <Typhography component='p' additionalClasses='text-12s font-normal'>
                                                {item.text}
                                            </Typhography>
                                        )}
                                    </Typhography>
                                ))}
                            {savedItem?.note && savedItem?.note?.length === 1 && savedItem?.note[0]?.text == '' && (
                                <Typhography component='p' additionalClasses='text-12s font-normal text-grey-540'>
                                    {'Add new note here...'}
                                </Typhography>
                            )}
                        </Typhography>
                    </Typhography>
                </>
            ) : (
                <Typhography component='div' additionalClasses='flex flex-col h-[calc(100vh-390px)] relative'>
                    {solutionHeader(item.patientSolutions, item.challenge, solution?.length)}
                    <NoteEditor
                        onAdd={(items: any) => handleAddChallengeNote(items, patientChallengeId)}
                        cancelAction={() => setShowNoteEdit && setShowNoteEdit(false)}
                        savedItems={savedItem}
                        showNote={showNote}
                        additionalClassesNote='h-full pl-4 overflow-y-auto'
                        additionalClassesButtons='pl-4 pb-4'
                        additionalClassesTitlebar='pl-4'
                        isChallengeNote={true}
                        isAutoSave={true}
                    />
                </Typhography>
            )}
        </Typhography>
    );

    const solutionHeader = (_solutionDetails: any, challenge: any, solutionLength: number = 0) => (
        <>
            <Typhography component='div'>
                <Typhography additionalClasses='flex justify-between w-full pl-4 pt-4 pb-2'>
                    <Typhography component='span' additionalClasses='flex '>
                        <Typhography component='div' additionalClasses='font-semibold text-25s inline-block'>
                            <Typhography
                                component='span'
                                additionalClasses='mr-3'
                            >
                                {challenge.challengeName}
                            </Typhography>
                            <Typhography
                                component='span'
                                onClick={() => showChallengeTimeline && showChallengeTimeline(true)}
                                additionalClasses='whitespace-nowrap font-semibold text-12s underline cursor-pointer mr-3'
                            >
                                {'View previous'}
                            </Typhography>
                        </Typhography>
                    </Typhography>
                    <Typhography component='div' additionalClasses='flex items-start'>
                        <Badge
                            additionalClasses='text-12s text-black-540 bg-primaryBlue text-nowrap !mr-0'
                            badgeType={BADGETYPE.PILLS}
                            colorVariant='gray'
                        >
                            {solutionLength} {language.solution.badgeTitle}
                        </Badge>
                    </Typhography>
                </Typhography>
                <Typhography component='div' additionalClasses='flex px-4 text-21s font-normal text-grey-540 mb-4'>
                    {challenge.challengeDescription}
                </Typhography>
            </Typhography>
        </>
    );

    const solutionHeaderResolved = (_solution: any, challenge: any) => (
        <>
            <ListRow
                title={
                    <Typhography component='div'>
                        <Badge
                            additionalClasses='text-12s text-black-540 bg-primaryBlue text-nowrap'
                            badgeType={BADGETYPE.PILLS}
                            colorVariant='gray'
                        >
                            {language.solution.challengeResolved}
                        </Badge>

                        <Typhography component='div' additionalClasses='font-semibold text-25s mt-2'>
                            {challenge.challengeName}
                        </Typhography>
                    </Typhography>
                }
                additionalClasses='py-3.5 px-6'
                bottomBorderType={BorderType.Dashed}
                controllers={<div className='text-nowrap'></div>}
                bottomContent={
                    <Typhography component='div' additionalClasses='flex px-6 text-21s font-normal text-grey-540'>
                        {challenge.challengeDescription}
                    </Typhography>
                }
            />
        </>
    );
    const solutionHeaderRefer = (_actionData: any, challenge: any) => (
        <>
            <ListRow
                title={
                    <Typhography component='div'>
                        <Badge
                            additionalClasses='text-12s text-black-540 bg-primaryBlue'
                            badgeType={BADGETYPE.PILLS}
                            colorVariant='gray'
                        >
                            {language.solution.challengeRefer}
                        </Badge>

                        <Typhography component='div' additionalClasses='font-semibold text-25s mt-2 text-nowrap'>
                            {challenge.challengeName}
                        </Typhography>
                    </Typhography>
                }
                additionalClasses='py-3.5 px-6'
                bottomBorderType={BorderType.Dashed}
                controllers={<div className='text-nowrap'></div>}
                bottomContent={
                    <Typhography component='div' additionalClasses='flex px-6 text-21s font-normal text-grey-540'>
                        {challenge.challengeDescription}
                    </Typhography>
                }
            />
        </>
    );

    const getVariationData = (variations: IVariation[]): React.ReactNode => {
        if (variations.length == 1) {
            const variFirst = variations[0];
            return (
                <Typhography component='div' additionalClasses='flex gap-4'>
                    <Typhography component='div' additionalClasses='flex text-nowrap text-21s font-medium items-center'>
                        <EmailIcon classes='mr-1 mt-0.5' w={'24'} h={'24'} />
                        {variFirst.referralData?.email}
                    </Typhography>

                    <Typhography
                        component='div'
                        additionalClasses=' flex text-nowrap text-21s font-medium items-center'
                    >
                        <PhoneIcon classes='mr-1 mt-0.5' w={'24'} h={'24'} />
                        {variFirst.referralData?.phone}
                    </Typhography>
                </Typhography>
            );
        } else {
            return <></>;
        }
    };

    const solutionCompleteContent = (actionData?: ISolution): React.ReactNode => {
        return (
            <>
                {actionData && (
                    <ListRow
                        title={
                            <Typhography component='div' additionalClasses='font-semibold text-25s mt-2 text-nowrap'>
                                {actionData.solutionName}
                            </Typhography>
                        }
                        subTitle={''}
                        additionalClasses='mb-2'
                        bottomBorderType={BorderType.Dashed}
                        controllers={''}
                        bottomContent={
                            <Typhography component='div' additionalClasses='pb-4'>
                                <Typhography
                                    component='div'
                                    additionalClasses='flex text-21s font-normal text-grey-540 col-span-6 pb-4'
                                >
                                    {actionData.longDescription}
                                </Typhography>
                                <Typhography component='div' additionalClasses='text-21s font-normal text-grey-540'>
                                    {actionData.solutionName}
                                </Typhography>
                                {actionData?.variants && getVariationData(actionData?.variants)}
                            </Typhography>
                        }
                    />
                )}
            </>
        );
    };

    const renderContent = () => {
        switch (actionType) {
            case ActionTypes.REFER:
                return (
                    <>
                        {!rowClick && solutionHeaderRefer(actionData, item.challenge)}
                        {!rowClick && (
                            <Typhography
                                component='div'
                                additionalClasses='my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg'
                            >
                                {solutionCompleteContent(actionData)}

                                {showNote && (
                                    <>
                                        <Typhography additionalClasses='text-12s font-medium'>Notes</Typhography>
                                        <NoteEditor
                                            onAdd={(items: any) => saveSolutionNote && solutionNote(items, item._id)}
                                            cancelAction={cancelAction}
                                            buttonLabels={{ cancel: 'Cancel', save: 'Complete' }}
                                        />
                                    </>
                                )}
                            </Typhography>
                        )}
                    </>
                );
            case ActionTypes.LETTER:
                return (
                    <>
                        {!rowClick && solutionHeaderRefer(actionData, item.challenge)}
                        {!rowClick && (
                            <Typhography
                                component='div'
                                additionalClasses='my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg'
                            >
                                {solutionCompleteContent(actionData)}

                                {showNote && (
                                    <>
                                        <Typhography additionalClasses='text-12s font-medium'>Notes</Typhography>
                                        <NoteEditor
                                            onAdd={(items: any) => saveSolutionNote && solutionNote(items, item._id)}
                                            cancelAction={cancelAction}
                                            buttonLabels={{ cancel: 'Cancel', save: 'Complete' }}
                                        />
                                    </>
                                )}
                            </Typhography>
                        )}
                    </>
                );
            case ActionTypes.RESOLVED:
                return (
                    <>
                        {!rowClick && solutionHeaderResolved(item.patientSolutions, item.challenge)}
                        {showNote && !rowClick && (
                            <Typhography component='div' additionalClasses='p-4'>
                                <Typhography additionalClasses='text-12s font-medium'>Notes</Typhography>
                                <NoteEditor
                                    onAdd={(items: any) => handleAddChallengeNote && handleAddChallengeNote(items, item._id, true)}
                                    cancelAction={() => {
                                        setShowNote && setShowNote(false);
                                        setRowClick && setRowClick(true);
                                    }}
                                    savedItems={savedItem}
                                    buttonLabels={{ cancel: "Cancel", save: "Resolve" }}
                                    isChallengeNote={true}
                                />
                            </Typhography>
                        )}
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <div ref={rowDetailsComponentRef} className='relative'>
            {rowClick && (
                <>
                    {solutionNoteContent(item._id)}
                    {solution && !showNoteEdit && solutionContents(solution)}
                </>
            )}
            {renderContent()}
        </div>
    );
};

export default RowDetails;
