import { CheckBoxComponent, Label, NoteItemProps, Typhography } from 'dfh-ui-library';
import React from 'react';
import { ITodoToggleRequestParams } from 'utils/types/patient.type';

interface NoteViewProps {
    noteId?: string;
    notes?: NoteItemProps[];
    toggleDashboardTodoCheckbox?: (req: ITodoToggleRequestParams) => void;
}

const checkboxView = (
    item: NoteItemProps, 
    noteId?: string, 
    index?: number,
) => {
    return (
        <>
            <CheckBoxComponent 
                onChange={() => {}} 
                checked={item.isChecked} 
                addtionalClasses={'!cursor-default'} 
                noteId={noteId}
                noteIndex={index}
            />
            <Label
                label={item.text}
                name='testname'
                additionalClasses={`text-xs ${item.isChecked && 'line-through text-gray-95'} p-2`}
            />
        </>
    );
};

const textView = (item: NoteItemProps) => {
    return (
        <>
            <Typhography component='p' additionalClasses='text-12s font-normal py-2'>
                {item.text}
            </Typhography>
        </>
    );
};

const NotesView: React.FC<NoteViewProps> = ({ noteId, notes }) => {
    return (
        <Typhography component='div'>
            {notes &&
                notes.map((item, index) => (
                    <div key={index} >
                        {item.enableCheckbox && checkboxView(item, noteId, index)}
                        {!item.enableCheckbox && item?.text !== '' && textView(item)}
                    </div>
                ))}
        </Typhography>
    );
};

export default NotesView;
