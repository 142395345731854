import {
    IChallengeRequest,
    IPatient,
    IPatientAssign,
    IPatientRequest,
    ISaveNotesRequest,
    IServeyNoteRequest,
    ISolutionNoteRequest,
    ISolutionRequest,
    ITodoToggleRequestParams
} from 'utils/types/patient.type';
import { IPatientRepository } from './IPatientRepository';

import {
    useMakeUrgentMutation,
    usePatientAddMutation,
    usePatientDeleteMutation,
    usePatientEditAssignBulkMutation,
    usePatientEditAssignMutation,
    usePatientEditMutation,
    usePatientGetQuery,
    useSaveNotesMutation,
    usePatientListQuery,
    useSaveSolutionNoteMutation,
    useSavePatientResourcesMutation,
    useGetResourcesQuery,
    useDownloadResourcesQuery,
    useGetPatientTimeLineQuery,
    useGetPatientChallengeTimeLineQuery,
    useSaveServeyNotesMutation,
    useUpdateServeyNotesMutation,
    useUpdateNotesMutation,
    useToggleTodoCheckboxMutation,
    useLazyGetNoteHistoryQuery,
    useLazyPatientChallengeQuery,
    useLazyGetSolutionDataQuery,
    useResourceInfoMutation,
    useDeleteResourceInfoMutation,
    useLazyGetReleaseInfoFilesQuery,
    useEditPatientResourcesMutation,
    useResourceInfoFormMutation,
} from 'api/patientAPI';
import { IChallengeRequestParams, IListRequestParams } from 'utils/types/api.type';

import { ApiResponse } from 'utils/types/api.type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import {
    IExistingResourceRequest,
    IImageCreateRequest,
    IPatientResourceRequest,
    IResourcesCreateRequest,
} from 'utils/types/image.type';

export const usePatientRepository = (): IPatientRepository => {
    const [mutateAddFunction] = usePatientAddMutation();
    const [mutateEditFunction] = usePatientEditMutation();
    const [mutateDeleteFunction] = usePatientDeleteMutation();
    const [mutateMakeUrgentFunction] = useMakeUrgentMutation();
    const [saveNotesFunction] = useSaveNotesMutation();
    const [mutateEditAssignFunction] = usePatientEditAssignMutation();
    const [mutateEditAssignBulkFunction] = usePatientEditAssignBulkMutation();
    const [saveSolutionNoteFunction] = useSaveSolutionNoteMutation();
    const [saveServeyNoteFunction] = useSaveServeyNotesMutation();
    const [updateServeyNoteFunction] = useUpdateServeyNotesMutation();
    const [updateNoteFunction] = useUpdateNotesMutation();
    const [toggleTodoCheckboxFunction] = useToggleTodoCheckboxMutation();
    const [saveResourceFunction] = useSavePatientResourcesMutation();
    const [editResourceFunction] = useEditPatientResourcesMutation();
    const [getNoteHistoryFunction] = useLazyGetNoteHistoryQuery();
    const [getChallengesFunction] = useLazyPatientChallengeQuery();
    const [getSolutionFunction] = useLazyGetSolutionDataQuery();
    const [mutateResourceInfoFunction] = useResourceInfoMutation();
    const [mutateResourceInfoFormFunction] = useResourceInfoFormMutation();
    const [mutateDeleteResourceInfoFunction] = useDeleteResourceInfoMutation();
    const [getReleaseInfoFilesFunction] = useLazyGetReleaseInfoFilesQuery();

    const localStorageService = LocalStorageService();

    const addPatient = async (createPatientRequest: IPatient): Promise<any> => {
        try {
            const result = mutateAddFunction(createPatientRequest); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during create patient:', error);
            throw new Error('An error occurred during create patient');
        }
    };

    const editPatient = async (editPatientRequest: IPatient): Promise<any> => {
        try {
            const result = mutateEditFunction(editPatientRequest);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };
    const editAssign = async (editPatientRequest: IPatientAssign): Promise<any> => {
        try {
            const result = mutateEditAssignFunction(editPatientRequest);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const editAssignBulk = async (editPatientRequest: IPatientAssign): Promise<any> => {
        try {
            const result = mutateEditAssignBulkFunction(editPatientRequest);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const getMyPatientList = (
        gridProps: IListRequestParams,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        let filter = '';
        if (gridProps.filters) filter = `${gridProps.filters},`;
        const params = {
            ...gridProps,
            filters: `${filter}responsibleIndividual:eq:${localStorageService?.getUser()._id}`,
        };
        const { data, error, isFetching, refetch } = usePatientListQuery(params);
        return { data, error, isFetching, refetch };
    };

    const getAssignedPatientList = (
        gridProps: IListRequestParams,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        let filter = '';
        if (gridProps.filters) filter = `${gridProps.filters},`;

        const params = {
            ...gridProps,
            filters: `${filter}${filter?.length > 0 ? ',' : ''}responsibleIndividual:isnotnull`,
        };
        const { data, error, isFetching, refetch } = usePatientListQuery(params);
        return { data, error, isFetching, refetch };
    };

    const getUnassignedPatientList = (
        gridProps: IListRequestParams,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        let filter = '';
        if (gridProps.filters) filter = `${gridProps.filters},`;
        const params = { ...gridProps, filters: `${filter}responsibleIndividual:isnull` };
        const { data, error, isFetching, refetch } = usePatientListQuery(params);
        return { data, error, isFetching, refetch };
    };

    const getPatientListForRI = (
        gridProps: IListRequestParams,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        let filter = '';
        if (gridProps.filters) filter = `${gridProps.filters},`;
        let params = gridProps.ids
            ? { ...gridProps, filters: `${filter}responsibleIndividual:in:${gridProps.ids}` }
            : { ...gridProps, filters: `${filter}responsibleIndividual:isnotnull` };
        const { data, error, isFetching, refetch } = usePatientListQuery(params);
        return { data, error, isFetching, refetch };
    };

    const deletePatient = async (patientRequest: IPatientRequest): Promise<any> => {
        try {
            const result = mutateDeleteFunction(patientRequest);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const makeUrgent = async (challengeReq: IChallengeRequest): Promise<any> => {
        try {
            const result = mutateMakeUrgentFunction(challengeReq);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const saveNotes = async (challengeReq: ISaveNotesRequest): Promise<any> => {

        try {
            const result = saveNotesFunction(challengeReq);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const savePatientResources = async (resource: IPatientResourceRequest): Promise<any> => {
        try {
            const result = saveResourceFunction(resource);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const editPatientResources = async (resource: IPatientResourceRequest): Promise<any> => {
        try {
            const result = editResourceFunction(resource);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const getPatient = (
        patientRequest: IPatientRequest,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        const { data, error, isFetching, refetch } = usePatientGetQuery(patientRequest);
        return { data, error, isFetching, refetch };
    };

    const getResources = (
        resourceProps: string,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        const { data, error, isFetching, refetch } = useGetResourcesQuery(resourceProps);
        return { data, error, isFetching, refetch };
    };

    const getChallengeUnresolvedList = (challengeReq: IChallengeRequestParams): Promise<any> => {
        try {
            const result = getChallengesFunction(challengeReq); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    const getSolutionRowData = (req: ISolutionRequest): Promise<any> => {
        try {
            const result = getSolutionFunction(req); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    const getFile = (
        req: string,
        uploadType: string,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        if (req !== '') {
            let type: string;
            if (uploadType === 'Letter' || uploadType === 'Form') {
                type = 'solution-variant-gen';
            } else {
                type = 'patient-resource';
            }

            const { data, error, isFetching, refetch } = useDownloadResourcesQuery({ req, type });
            return { data, error, isFetching, refetch };
        } else {
            const { data, error, isFetching, refetch } = useDownloadResourcesQuery({ req, type: '' }, { skip: true });
            return { data, error, isFetching, refetch };
        }
    };

    const saveSolutionNote = async (req: ISolutionNoteRequest): Promise<any> => {
        try {
            const request = { ...req, actionedUser: localStorageService.getUser()._id };

            const result = saveSolutionNoteFunction(request);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during saving note:', error);
            throw new Error('An error occurred during daving note');
        }
    };

    const saveServeyNotes = async (reqParams: IServeyNoteRequest): Promise<any> => {
        try {
            const request = { ...reqParams, actionedUser: localStorageService.getUser()._id };
            const result = saveServeyNoteFunction(request);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during saving note:', error);
            throw new Error('An error occurred during daving note');
        }
    };
    const updateServeyNotes = async (reqParams: IServeyNoteRequest): Promise<any> => {
        try {
            const request = { ...reqParams, actionedUser: localStorageService.getUser()._id };
            const result = updateServeyNoteFunction(request);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during saving note:', error);
            throw new Error('An error occurred during daving note');
        }
    };

    const updateNotes = async (reqParams: IServeyNoteRequest): Promise<any> => {
        try {
            const request = { ...reqParams, actionedUser: localStorageService.getUser()._id };

            const result = updateNoteFunction(request);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during saving note:', error);
            throw new Error('An error occurred during daving note');
        }
    };

    const toggleTodoCheckbox = async (reqParams: ITodoToggleRequestParams): Promise<any> => {
        try {
            const request = { ... reqParams, actionedUser: localStorageService.getUser()._id };
            const result = toggleTodoCheckboxFunction(request);
            if (result) {
                result.then((response) => {
                    return response;
                }) 
            }
            return result;
        } catch (error) {
            console.error('An error occurred during toggling the to-do:', error);
            throw new Error('An error occurred during toggling the to-do');
        }
    }

    const getPatientTimeLine = (
        patientId: string,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        const { data, error, isFetching, refetch } = useGetPatientTimeLineQuery(patientId);
        return { data, error, isFetching, refetch };
    };

    const getPatientChallengeTimeLine = (
        patientId: string,
        challengeId: string,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        const params = { patientId: patientId, challengeId: challengeId };
        const { data, error, isFetching, refetch } = useGetPatientChallengeTimeLineQuery(params);
        return { data, error, isFetching, refetch };
    };

    const getNoteHistory = (reqParams: string): Promise<any> => {
        try {
            const result = getNoteHistoryFunction(reqParams); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    const uploadResourceInfo = async (createRequest: IImageCreateRequest): Promise<any> => {
        try {
            const result = mutateResourceInfoFunction(createRequest); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during save image:', error);
            throw new Error('An error occurred during save image');
        }
    };

    const uploadResourceInfoForm = async (createRequest: IExistingResourceRequest): Promise<any> => {
        try {
            const result = mutateResourceInfoFormFunction(createRequest); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during save image:', error);
            throw new Error('An error occurred during save image');
        }
    };

    const deleteResourceInfo = async (createRequest: string): Promise<any> => {
        try {
            const result = mutateDeleteResourceInfoFunction(createRequest); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during save image:', error);
            throw new Error('An error occurred during save image');
        }
    };

    const getReleaseInfoFiles = async (createRequest: IResourcesCreateRequest): Promise<any> => {
        try {
            const result = getReleaseInfoFilesFunction(createRequest); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during save image:', error);
            throw new Error('An error occurred during save image');
        }
    };

    return {
        addPatient,
        editPatient,
        deletePatient,
        getPatient,
        getMyPatientList,
        getAssignedPatientList,
        getUnassignedPatientList,
        getPatientListForRI,
        editAssign,
        editAssignBulk,
        getChallengeUnresolvedList,
        makeUrgent,
        saveNotes,
        getSolutionRowData,
        saveSolutionNote,
        savePatientResources,
        editPatientResources,
        getResources,
        getFile,
        getPatientTimeLine,
        getPatientChallengeTimeLine,
        saveServeyNotes,
        updateServeyNotes,
        updateNotes,
        toggleTodoCheckbox,
        getNoteHistory,
        uploadResourceInfo,
        deleteResourceInfo,
        getReleaseInfoFiles,
        uploadResourceInfoForm,
    };
};
