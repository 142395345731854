import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import config from '../configs/config';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { ApiTokenResponse, IAuthRequest } from 'utils/types/api.type';

const localStorageService = LocalStorageService();
let refreshTokenPromise: Promise<any> | null = null;

export const baseQuery = fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders(headers) {
        const authorization = localStorageService.getAccessToken();
        const idToken = localStorageService.getIdToken();
        const refreshToken = localStorageService.getRefreshToken();
        if (authorization) {
            headers.set('authorization', `Bearer ${authorization}`);
        }
        if (idToken) {
            headers.set('id-token', `${idToken}`);
        }
        if (refreshToken) {
            headers.set('refresh-token', `${refreshToken}`);
        }
        return headers;
    },
});

export const baseQueryWithReAuth: BaseQueryFn<any | FetchArgs, any, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        if (!refreshTokenPromise) {
            const user = localStorageService.getUser();
            const userData: IAuthRequest = { email: user?.email };

            refreshTokenPromise = (async () => {
                try {
                    const refreshResult = await baseQuery(
                        {
                            url: 'auth/refresh',
                            method: 'POST',
                            body: userData,
                        },
                        api,
                        extraOptions,
                    );

                    if (refreshResult && refreshResult.data) {
                        const tokens = refreshResult.data as ApiTokenResponse;
                        // Store the new token
                        localStorageService.setAuthTokens({
                            accessToken: tokens.data.accessToken,
                            refreshToken: tokens.data.refreshToken,
                            idToken: tokens.data.idToken,
                            user: user,
                        });
                        return tokens.data.accessToken;
                    } else {
                        localStorageService.clearAuthTokens();
                        throw new Error('Failed to refresh token');
                    }
                } catch (error) {
                    console.error('An error occurred during refresh:', error);
                    localStorageService.clearAuthTokens();
                    throw error;
                } finally {
                    refreshTokenPromise = null; // Reset the promise after the request completes
                }
            })();
        }

        try {
            const newAccessToken = await refreshTokenPromise;
            // Retry the initial query with the new access token
            if (newAccessToken) {
                result = await baseQuery(args, api, extraOptions);
            }
        } catch (error) {
            // Handle the error if the refresh token request fails
            console.error('An error occurred during refresh token handling:', error);
        }
    }

    return result;
};