import { AppConfig } from 'utils/types/appConfig.type';

const config: AppConfig = {
    api: {
        baseUrl: 'https://api.docsforhealth.org/api/v1',
        // baseUrl:  'https://brs6myf0q3.execute-api.us-east-1.amazonaws.com/api/v1'
    },
};

export default config;
