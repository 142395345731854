import { IImageRepository } from './IImageRepository';
import { useImageAddMutation, useImageDeleteMutation, useImageQuery } from '../api/imageAPI';
import { ApiResponse, IImageCreateRequest, IImageRequest } from 'utils/types/image.type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export const useImageRepository = (): IImageRepository => {
    const [mutateAddFunction] = useImageAddMutation();
    const [mutateDeleteFunction] = useImageDeleteMutation();

    const saveImage = async (createRequest: IImageCreateRequest): Promise<any> => {
        try {
            const result = mutateAddFunction(createRequest); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during save image:', error);
            throw new Error('An error occurred during save image');
        }
    };

    const deleteImage = async (deleteRequest: IImageRequest): Promise<any> => {
        try {
            const result = mutateDeleteFunction(deleteRequest);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during delete image:', error);
            throw new Error('An error occurred during delete image');
        }
    };

    const getImage = (
        imageRequest: IImageRequest,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    } => {
        const { data, error, isLoading } = useImageQuery(imageRequest);
        return { data, error, isLoading };
    };

    return {
        saveImage,
        deleteImage,
        getImage,
    };
};
