import { useLocation } from 'react-router-dom';
import { Button, Input, LoadingSpinner, Message, Typhography } from 'dfh-ui-library';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ISignUpProps } from 'utils/types/auth.type';
import signUpHOC from '../index';

const Verification = (props: ISignUpProps) => {
    const { state } = useLocation();
    useEffect(() => {
        if (!state && !state?.email) props.navigateMethods.navigateToSignUp();
    });

    const handleVerify = async (code: string) => {
        const verifyResult = await props.apiMethods.verify({
            email: state?.email,
            confirmationCode: code,
        });
        if (verifyResult?.data) {
            formik.resetForm();
            props.navigateMethods.navigateToSignIn();
        }
    };

    const handleResend = async () => {
        await props.apiMethods.resendCode({
            email: state?.email,
        });
    };

    // Verification form config
    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: Yup.object({
            code: Yup.string().required('Verfication code is required'),
        }),
        onSubmit: (values) => {
            handleVerify(values.code);
        },
    });

    return (
        <>
            {props.isLoading && <LoadingSpinner />}
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <Typhography type='authHeader'>Verify it's you!</Typhography>
            <form onSubmit={formik.handleSubmit}>
                <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                    <Typhography component='div' additionalClasses='py-2 left-0 flex-1'>
                        <Typhography component='div' additionalClasses='left-0'>
                            <Typhography type='inforTextGray'>
                                We have sent you the verification code to <br />
                            </Typhography>
                            <Typhography type='inforTextNormal' additionalClasses='cursor-pointer ml-1'>
                                {state?.email}
                            </Typhography>
                        </Typhography>
                    </Typhography>
                    <Typhography component='div' additionalClasses='py-2 mt-8'>
                        <Typhography component='div'>
                            <Input
                                label='Verification code'
                                name='code'
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && formik.errors.code ? formik.errors.code : ''}
                                isAdditionalErrorInput={true}
                                additionalErrorClasses='text-red-500 text-xs pt-1 pl-0'
                                additionalClasses={formik.errors.code ? 'border-red-500 mt-2' : 'mt-2'}
                                isBorderedError={formik.errors.code ? true : false}
                            />
                        </Typhography>
                    </Typhography>
                    <Typhography additionalClasses='py-2'>
                        <Typhography component='p'>
                            <Typhography type='textMediumGray'>Haven't received a verification code?</Typhography>
                            <Typhography
                                type='linkTextMedium'
                                additionalClasses='cursor-pointer ml-1'
                                onClick={handleResend}
                            >
                                Resend Code
                            </Typhography>
                        </Typhography>
                    </Typhography>
                    <Typhography additionalClasses='my-8'>
                        <Button
                            type='submit'
                            buttonLabel='Verify'
                            variants='large'
                            additionalClasses='bg-[#006CD0] cursor-pointer hover:bg-[#006CD0]'
                            isDisabled={!(formik.isValid && formik.dirty)}
                        ></Button>
                    </Typhography>
                </Typhography>
            </form>
        </>
    );
};

const VerificationComponent = signUpHOC(Verification);
export default VerificationComponent;
