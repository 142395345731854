// status.ts

enum StepStatusColor {
    NotStarted = '#A5B1BC',
    InProgress = '#FFB066',
    Completed = '#00A39B',
}

export enum ScreenerStatus {
    NOTSTARTED = 'NOTSTARTED',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
}

export enum FilterTypes {
    MyPatient = 1,
    UnAssign = 3,
    Assigned = 2,
    SelectUser = 4,
}
export default StepStatusColor;

export enum TabValue {
    SocialDeterminantsScreening = '1',
    Challenges = '2',
    FormsAndLetters = '3',
    Outcomes = '4',
}

export const CHALLENGE_URGENCY = {
    URGENT: 'Urgent',
    NORMAL: 'Normal',
};

export const CHALLENGE_STATUS = {
    IN_PROGRESS: 'In progress',
    IN_QUEUE: 'In queue',
    RESOLVED: 'Resolved',
};

export const SOLUTION_TYPES = {
    REFERRAL: 'Referral',
    FORM: 'Form',
    LETTER: 'Letter',
};
export type SolutionType = keyof typeof SOLUTION_TYPES;

export enum ActionTypes {
    REFER = 'refer',
    RESOLVED = 'resolved',
    LETTER = 'letter',
    FORM = 'form',
}

export enum FilterOptions {
    AllDocs = 'alldocs',
    Forms = 'form',
    Letters = 'letters',
    Uploads = 'uploads',
}

export enum LETTER_GRID_ACTION_TYPES {
    EDIT = 'edit',
    DOWNLOAD = 'download',
    PRINT = 'print',
}

// Enums and mapping
export enum TIMELINE_ACTION_TYPES {
    CHALLENGE = 1,
    SOLUTION = 2,
    Normal = 3,
    SCREENER = 4,
}

export const TIMELINE_TYPES = {
    1: 'Challenge',
    3: 'Normal',
    2: 'Solution',
    4: 'Survey',
};

export const TIMELINE_NOTE_TYPE = {
    SURVEY: 'Survey',
    NORMAL: 'Normal',
};

export enum DOC_TAB_TYPES {
    alldocs = 1,
    form = 2,
    letters = 3,
    uploads = 4,
}

export const DOC_TYPES: { [key: number]: string } = {
    1: 'AllDocs',
    2: 'Form',
    3: 'Letter',
    4: 'Upload',
};

export enum EmojiTypes {
    LIKE = 'like',
    LAUGH = 'laugh',
    CLAP = 'clap',
    HART = 'hart',
}
