import { BorderType, Button, Icon, ListRow, PanelCard, SortDropdown, Typho, Typhography } from 'dfh-ui-library';
import { useEffect, useState } from 'react';
import { sortOptions } from '../helpers/default';
import moment from 'moment';
import { ActionTypes, CHALLENGE_URGENCY } from 'utils/enum';
import RowDetails from './rowDetails';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ChallengeProps, IPatientChallengeDto, ISolution } from 'utils/types/patient.type';
import { useDispatch, useSelector } from 'react-redux';
import { getLetterGenParams, setLetterGenParams } from 'stores/letterFormSlice';
import { AppDispatch, RootState } from 'stores/store';
import { setActionType } from 'stores/letterSlice';

const ChallengePannel = (props: ChallengeProps) => {
    const [selectedValues, setSelectedValues] = useState<{
        displayText: string;
        value: string;
    }>({
        displayText: 'Urgent',
        value: 'urgent',
    });

    const [rowDetailsData, setRowDetailsData] = useState<IPatientChallengeDto>();
    const [showNoteEdit, setShowNoteEdit] = useState<boolean>(false);
    const letterGenParams = useSelector((state: RootState) => getLetterGenParams(state));
    const dispatch = useDispatch<AppDispatch>();
    const { actionType } = useSelector((state: RootState) => state.letter);
    const { setShowNote, setActionData, setSolutionRowClick, challengeData } = props;

    useEffect(() => {
        () => {
            dispatch(setLetterGenParams({ letterGenParams: {} }));
        };
    }, []);

    useEffect(() => {
        if (letterGenParams?.isSave) {
            dispatch(setActionType(ActionTypes.LETTER));
            setShowNote && setShowNote(true);
            const solutionItem = props.solution?.find((value: any) => value._id == letterGenParams.solutionId);
            setActionData && solutionItem && setActionData(solutionItem);
            setSolutionRowClick && setSolutionRowClick(false);
        }
    }, [letterGenParams, props.solution]);

    useEffect(() => {
        if (challengeData?.length > 0 && props.solutionRowClick == false && !rowDetailsData) {
            if (!props.clickedId) {
                const firstRecord = challengeData?.[0];
                listRowClick(firstRecord);
            } else {
                listRowClick(challengeData.find((val: any) => val._id == props.clickedId));
            }
        }
        if (challengeData?.length > 0 && !challengeData?.find((challenge: any) => challenge._id === props.clickedId)) {
            listRowClick(challengeData?.[0]);
        }
        if (challengeData?.length === 0) {
            setRowDetailsData(undefined);
        }
    }, [challengeData]);

    const handleSelectedValuesChange = (newValues: { displayText: string; value: string }) => {
        setSelectedValues(newValues);
        props.sortChallenges && props.sortChallenges(newValues.value, challengeData);
    };

    const clickResolve = (item: any, event: any) => {
        event.stopPropagation();
        dispatch(setActionType(ActionTypes.RESOLVED));
        setSolutionRowClick && setSolutionRowClick(false);
        props.setclickedId && props.setclickedId(item._id);
        setRowDetailsData(item);
        setShowNote && setShowNote(true);
        setShowNoteEdit(false);
    };

    const listRowClick = (item: IPatientChallengeDto) => {
        props.getSolution && props.getSolution({ patientChallengeId: item._id, challengeId: item.challenge._id });
        props.setclickedId && props.setclickedId(item._id);
        setRowDetailsData(item);
        props.setRowDetails && props.setRowDetails(item);
        props.setSolutionRowClick && props.setSolutionRowClick(true);
        props.setSelectedChallenge && props.setSelectedChallenge(item.challenge._id);
        setShowNoteEdit(false);
    };
    const referalAction = (solution: ISolution) => {
        dispatch(setActionType(ActionTypes.REFER));
        setShowNote && setShowNote(true);
        props.setActionData && props.setActionData(solution);
        props.setSolutionRowClick && props.setSolutionRowClick(false);
    };
    const controllers = (
        <>
            <SortDropdown
                options={sortOptions}
                selectedValues={selectedValues}
                handleSelectedValuesChange={handleSelectedValuesChange}
                enableLabel
            />
        </>
    );

    const pannelContents = (challengeData: any) => {
        return (
            <>
                {challengeData?.length > 0 &&
                    challengeData.map((item: any, index: any) => (
                        <div onClick={() => listRowClick(item)} key={index}>
                            <ListRow
                                key={index} // Adding a unique key prop is important when rendering arrays in React
                                title={
                                    <Typho
                                        type='div'
                                        color='black540'
                                        weight='medium'
                                        size='21s'
                                        onClick={() => listRowClick(item)}
                                    >
                                        {item.challenge.challengeName}
                                    </Typho>
                                }
                                subTitle={
                                    <Typhography
                                        component='div'
                                        additionalClasses='text-grey-540 text-12s'
                                        onClick={() => listRowClick(item)}
                                    >
                                        {moment(item.createdDate).format('MM/DD/YYYY')}
                                    </Typhography>
                                }
                                additionalClasses={`py-3.5 px-6 group hover:bg-blue-25 hover:border-r-4 hover:border-blue-550 ${index === challengeData?.length - 1 && 'mb-4'} ${props.clickedId === item._id ? 'bg-[#EFF7FF] border-r-4 border-[#006CD0]' : 'border-r-4 border-[#FFF]'}`}
                                bottomBorderType={BorderType.WideDash} // Assuming BorderType is defined elsewhere
                                controllers={
                                    <>
                                        <Typho type='div' additionalClasses='h-full flex items-center justify-end'>
                                            {item.urgency == 'Normal' && (
                                                <div data-tooltip-id='makeurgent'>
                                                    <Icon
                                                        iconType='FlagGrayIcon'
                                                        additionalClasses='w-8 h-8 mr-2 items-center hidden group-hover:block'
                                                        onClick={(event: any) =>
                                                            props.makeUrgent &&
                                                            props.makeUrgent(CHALLENGE_URGENCY.URGENT, item._id, event)
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {item.urgency == 'Urgent' && (
                                                <Icon
                                                    iconType='FlagError'
                                                    additionalClasses='mr-4 items-center'
                                                    onClick={(event: any) =>
                                                        props.makeUrgent &&
                                                        props.makeUrgent(CHALLENGE_URGENCY.NORMAL, item._id, event)
                                                    }
                                                />
                                            )}
                                            {
                                                <div onClick={(event: any) => clickResolve(item, event)}>
                                                    <Button
                                                        buttonLabel='Resolve'
                                                        data-testId='InputField-id'
                                                        variants='smallWhite'
                                                    />
                                                </div>
                                            }
                                        </Typho>
                                    </>
                                }
                                onFocus={() => {}}
                            />
                        </div>
                    ))}
            </>
        );
    };

    const pannelContentsRight = (_challengeData: any) => {
        return (
            <>
                {rowDetailsData && (
                    <RowDetails
                        item={rowDetailsData}
                        challengeData={_challengeData}
                        showNote={props.showNote ? props.showNote : false}
                        setShowNote={props.setShowNote}
                        rowClick={props.solutionRowClick}
                        setRowClick={props.setSolutionRowClick}
                        updateChallenge={props.updateChallenge}
                        saveSolutionNote={props.saveSolutionNote}
                        solution={props.solution}
                        referalAction={referalAction}
                        actionType={actionType}
                        actionData={props.actionData}
                        cancelAction={props.cancelAction}
                        navigateToLetterView={props.navigateToLetterView}
                        showChallengeTimeline={props.setShowChallengeTimeline}
                        copiedNote={props.copiedNote}
                        showNoteEdit={showNoteEdit}
                        setShowNoteEdit={setShowNoteEdit}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <ReactTooltip
                id='makeurgent'
                data-tooltip-place='top'
                content='Make Urgent'
                style={{ backgroundColor: '#21232C', fontSize: '12', zIndex: 1000 }}
                data-tooltip-class-name={'text-red text-12s'}
            />
            {challengeData && (
                <PanelCard
                    additionalClasses='mt-8 h-[calc(100vh-316px)] overflow-hidden'
                    headerTitle={`${challengeData && challengeData?.length} challenges`}
                    controllers={controllers}
                    content={
                        <Typhography component='div' additionalClasses='flex'>
                            <Typhography
                                additionalClasses='w-1/3 border-r relative overflow-y-auto h-[calc(100vh-396px)]'
                                component='div'
                            >
                                {challengeData && challengeData?.length > 0 && pannelContents(challengeData)}
                            </Typhography>
                            <Typhography additionalClasses='w-2/3 relative h-[calc(100vh-390px)] overflow-y-auto' component='div'>
                                {pannelContentsRight(challengeData)}
                            </Typhography>
                        </Typhography>
                    }
                />
            )}
        </>
    );
};

export default ChallengePannel;
