// letterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionTypes } from 'utils/enum';

interface LetterState {
    actionType: ActionTypes | undefined;
}

const initialState: LetterState = {
    actionType: undefined,
};

const letterSlice = createSlice({
    name: 'letter',
    initialState,
    reducers: {
        setActionType(state, action: PayloadAction<ActionTypes>) {
            state.actionType = action.payload;
        },
    },
});

export const { setActionType } = letterSlice.actions;

export default letterSlice.reducer;
