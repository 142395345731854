import {
    Button,
    ColumnDef,
    FilterButtonGroup,
    Icon,
    IconInput,
    ImagePreview,
    IndeterminateCheckbox,
    InputDatePicker,
    Message,
    OptionProps,
    SelectUserWithButton,
    SideBarListView,
    Table,
    TooltipWithChildren,
    Typhography,
    UrgentDataProps,
    usePagination,
    useSorting,
} from 'dfh-ui-library';
import patientHOC from '../index';
import {
    IPatient,
    IPatientAssign,
    IPatientDetails,
    IPatientProps,
    ITodoToggleRequestParams,
    PatientsFilter,
} from 'utils/types/patient.type';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteRegistry from 'routes/routeRegistry';
import { useAppSelector } from 'stores/store';
import { IListRequestParams } from 'utils/types/api.type';
import { initPatientParams } from 'utils/init/patientInit';
import moment from 'moment';
import {
    extractTodosData,
    extractUrgentData,
    getFristLettersFromString,
    getMappedUserDetailsForChose,
    getRandomBgColor,
} from '../helpers/userHelpers';
import GridNotification from '../components/gridNotification';
import { get, isEqual } from 'lodash';
import { masterData, options } from '../helpers/default';
import LoadingProgress from 'components/Loading/LoadingProgress';
import { CHALLENGE_STATUS, CHALLENGE_URGENCY } from 'utils/enum';
import { language } from '../helpers/language';
import { Tooltip } from 'react-tooltip';

const PatientList = (props: IPatientProps) => {
    const navigate = useNavigate();
    const [patients, setPatients] = useState<IPatient[]>([]);
    const location = useLocation();
    const receivedData = location.state;
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    const sortbyParam = searchParams.get('sortby');
    const skipParam = searchParams.get('skip');
    const limitParam = searchParams.get('limit');
    const { data: usersData } = useAppSelector((state) => state.user);
    const [searchText, setSearchText] = useState('');
    const [rowSelection, setRowSelection] = useState<any>(); // Initialize with appropriate type
    const [showNotification, setShowNotification] = useState(false);
    const [requestParams, setRequestParams] = useState<IListRequestParams>(initPatientParams);
    const { limit, onPaginationChange, skip, pagination } = usePagination(10, 0);
    const { sorting, onSortingChange, field, order } = useSorting('firstName', 'desc');
    const [totalRecords, setTotalRecords] = useState(0);
    const [tooltipContent, setTooltipContent] = useState('');

    const [selectedValues, setSelectedValues] = useState<OptionProps[] | undefined>([
        {
            displayText: '',
            iconText: '',
            imageUrl: '',
            bgColor: '',
            id: '',
        },
    ]);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [key, setKey] = useState(0); // State to trigger re-render
    const [tempEditedRes, setTempEditedRes] = useState<any[]>();
    const [sidePannelDataList, setSidePannelDataList] = useState<UrgentDataProps[]>();
    const [prevUpdateReq, setPrevUpdateReq] = useState<{ req: IPatientAssign; isMul: boolean } | undefined>(undefined);
    const [showTooltip, _setShowTooltip] = useState(false);
    const [patientsFilter, setPatientsFilter] = useState<PatientsFilter>(PatientsFilter.MY);
    const {
        data: patientsData,
        isFetching: patientsDataIsFetching,
        refetch: patientsDataRefetch,
    } = props.getPatientList(requestParams, patientsFilter);
    const [dataFilteredTodos, setDataFilteredTodos] = useState<UrgentDataProps[]>([]);

    const setUrlParam = (sortbyParam: string | null, skipParam: string | null, limitParam: string | null) => {
        searchParams.set('sortby', sortbyParam || initPatientParams.sortby);
        searchParams.set('skip', skipParam || initPatientParams.skip.toString());
        searchParams.set('limit', limitParam || initPatientParams.limit.toString());
        const newSearch = `?${searchParams.toString()}`;
        window.history.replaceState({}, '', newSearch);
        const newObj = {
            ...requestParams,
            sortby: sortbyParam || initPatientParams.sortby,
            skip: skipParam ? parseInt(skipParam) : initPatientParams.skip,
            limit: limitParam ? parseInt(limitParam) : initPatientParams.limit,
        };
        setRequestParams(newObj);
    };

    useMemo(() => {
        if (filterParam) {
            setPatientsFilter(filterParam as PatientsFilter);
        } else {
            setFilterParam(PatientsFilter.MY);
        }
        setUrlParam(sortbyParam, skipParam, limitParam);
    }, []);

    const handleShowNotification = (isOpen: boolean) => {
        // setShowNotification(isOpen);
        // Hide the notification after 10 seconds
        if (isOpen) {
            setTimeout(() => {
                setShowNotification(false);
            }, 10000);
        }
    };

    const setFilterParam = (filter: PatientsFilter) => {
        searchParams.set('filter', filter);
        const newSearch = `?${searchParams.toString()}`;
        window.history.replaceState({}, '', newSearch);
        setPatientsFilter(filter);
    };

    useEffect(() => {
        if (patientsData?.data) {
            setPatients(patientsData.data.results);
            setTotalRecords(patientsData.data.total);
            const sidePannelData = extractUrgentData(patientsData.data.results);
            setSidePannelDataList(sidePannelData);
        }
    }, [patientsDataIsFetching, patientsData]);

    useEffect(() => {
        if (patientsData?.data) {
            setDataFilteredTodos(getTodos());
        }
    }, [patientsData]);

    const navigateToAdd = () => {
        navigate(RouteRegistry.patient.paths.add.path);
    };

    const rowClick = (cell: any) => {
        const accessorKey = cell.column.columnDef.accessorKey;
        if (accessorKey === 'firstName') {
            props.navigateToView(cell.row.original._id);
        }
    };

    const rowClickNavigate = (row: any) => {
        navigate(RouteRegistry.patient.paths.view.path, { state: { id: row.original._id } });
    };

    const handleChallengeHeaderClick = (patientId?: string, challengeId?: string) => {
        const stateObject = {
            patientId: patientId,
            challengeId: challengeId,
            isEnableSolution: true,
        };
        navigate(`${RouteRegistry.patient.paths.view.path}/${patientId}`, { state: stateObject });
    };

    const handleUnresolvedClick = (row: any) => {
        navigate(`${RouteRegistry.patient.paths.view.path}/${row.original._id}`, { state: { activeTab: '2' } });
    };

    useEffect(() => {
        const sortColumn = sorting ? `${field}:${order}` : 'firstName';
        setUrlParam(sortColumn, skip.toString(), limit.toString());
    }, [pagination, sorting]);

    const reloadGrid = () => {
        // Increment the key to trigger re-render
        setKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        let filterData = undefined;
        if (searchText !== '') {
            filterData =
                filterData === undefined
                    ? `fullName:contains:${searchText}`
                    : `fullName:contains:${searchText},${filterData}`;
        }
        if (selectedDate !== null) {
            const mDate = moment(selectedDate);

            // Format the date
            const formattedDate = mDate.format('YYYY-MM-DD');

            filterData =
                filterData === undefined
                    ? searchText === ''
                        ? `dob:eq:${formattedDate}`
                        : `dob:eq:${formattedDate},${filterData}`
                    : `dob:eq:${formattedDate},${filterData}`;
        }

        if (filterData || selectedValues)
            setRequestParams({
                ...requestParams,
                filters: filterData,
                ids:
                    isEqual(patientsFilter, PatientsFilter.RI) &&
                    !isEqual(selectedValues?.map((values) => values.id).join(';'), '')
                        ? selectedValues?.map((values) => values.id).join(';')
                        : undefined,
            });
    }, [selectedValues, searchText, selectedDate]);

    const onSelectedUsers = (selectedOption: OptionProps[] | undefined) => {
        setSelectedValues(selectedOption);
    };

    const tempEditedResCache = useMemo(() => {
        return tempEditedRes;
    }, [tempEditedRes]);

    const updatePatient = async (data: any, rowDetails: any, isMultiple = false) => {
        setTempEditedRes([]);
        let updateData: IPatientAssign = { responsibleIndividual: data._id };
        if (isMultiple) {
            setPrevUpdateReq({
                req: {
                    patientIds: rowSelection,
                    responsibleIndividual: null,
                },
                isMul: true,
            });
            updateData = { ...updateData, patientIds: rowSelection };
            await props.editAssignBulk(updateData);
            tempEditedRes
                ? setTempEditedRes([...tempEditedRes, { rep: data, row: rowSelection }])
                : setTempEditedRes([{ rep: data, row: rowSelection, isMul: isMultiple }]);
            handleShowNotification(true);
        } else {
            setPrevUpdateReq({
                req: {
                    _id: rowDetails._id,
                    responsibleIndividual:
                        patients.filter((patient) => patient._id === rowDetails._id)[0].responsibleIndividual &&
                        patients.filter((patient) => patient._id === rowDetails._id)[0].responsibleIndividual?._id
                            ? patients.filter((patient) => patient._id === rowDetails._id)[0].responsibleIndividual?._id
                            : null,
                },
                isMul: false,
            });
            updateData = { ...updateData, _id: rowDetails._id };
            await props.editAssign(updateData);
            tempEditedRes
                ? setTempEditedRes([...tempEditedRes, { rep: data, row: rowDetails }])
                : setTempEditedRes([{ rep: data, row: rowDetails, isMul: isMultiple }]);
            handleShowNotification(true);
        }
    };

    const undoUpdatePatient = async () => {
        if (prevUpdateReq) {
            if (prevUpdateReq.isMul) {
                await props.editAssignBulk(prevUpdateReq.req);
            } else {
                await props.editAssign(prevUpdateReq.req);
            }
        }
        setTempEditedRes([]);
        setShowNotification(false);
    };

    const isManualPagination = true;
    const isManualSorting = true;

    const handleSelect = (selectedOption: number) => {
        setFilterParam(Object.values(PatientsFilter)[selectedOption - 1]);
        reloadGrid();
    };

    const handleSelectedValuesChangeAll = (newValues: OptionProps) => {
        updatePatient(newValues, null, true);
    };

    const handleSelectedValuesChangeSingle = (newValues: OptionProps, rowData: any) => {
        updatePatient(newValues, rowData);
    };

    const setSearchTextOnChange = (e: any) => {
        e.persist();
        setSearchText(e.target.value);
    };

    const handleDobChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const setTooltipValue = (unresolved: number) => {
        setTooltipContent(`${unresolved} unresolved Challenges.`);
    };

    //Commented due to patient list not loading sometimes after create a new patient
    // useMemo(() => {
    //     if (receivedData?.isSaved && patientsDataIsFetching) patientsDataRefetch();
    // }, [receivedData?.isSaved, patientsDataIsFetching]);

    const columns = useMemo<ColumnDef<IPatientDetails>[]>(
        () => [
            {
                accessorKey: 'firstName',
                id: 'firstName',
                size: 200,
                header: 'Name (DoB)',
                accessorFn: (row) => row.firstName,
                cell: ({ row }) => {
                    const mDate = moment(row.original.dob);
                    const formattedDate = mDate.format('MM/DD/YYYY');
                    return (
                        <div className='flex items-center gap-2'>
                            {patientsFilter == PatientsFilter.UNASSIGNED && (
                                <IndeterminateCheckbox
                                    {...{
                                        checked: row.getIsSelected(),
                                        disabled: !row.getCanSelect(),
                                        indeterminate: row.getIsSomeSelected(),
                                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.stopPropagation();
                                            row.getToggleSelectedHandler()(e);
                                        },
                                        onClick: (e: React.MouseEvent<HTMLInputElement>) => {
                                            e.stopPropagation();
                                        },
                                    }}
                                />
                            )}
                            <ImagePreview
                                text={getFristLettersFromString(`${row.original?.firstName} ${row.original.lastName}`)}
                                isSmall={true}
                                imageUrl={row.original.imageSignedUrl}
                                h={'h-8'}
                                w={'w-8'}
                                bgColor={getRandomBgColor()}
                            />
                            <Typhography
                                component='div'
                                additionalClasses='flex flex-col'
                                onClick={() => rowClickNavigate(row)}
                            >
                                <Typhography component='span' additionalClasses='text-21s font-medium'>
                                    {`${row.original.firstName} ${row.original.lastName}`}
                                </Typhography>
                                <Typhography
                                    component='span'
                                    additionalClasses='text-grey-540 font-normal text-12s'
                                >{`${formattedDate}`}</Typhography>
                            </Typhography>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'dob',
                header: 'Age',
                size: 50,
                footer: (props) => props.column.id,
                cell: ({ row }) => `${moment().diff(moment(row.original.dob), 'years')}`,
            },
            {
                accessorKey: 'responsibleIndividual',
                header: 'Responsible',
                size: 150,
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    const rPersons = row.original.responsibleIndividual;

                    let selected =
                        patientsFilter == PatientsFilter.UNASSIGNED
                            ? tempEditedResCache?.find((val) => {
                                  if (val.isMul) {
                                      return val.row.find((value: any) => value === row.original._id);
                                  } else {
                                      return val.row._id === row.original._id;
                                  }
                              })
                            : undefined;

                    const isEdited =
                        selected !== undefined && selected?.row && tempEditedResCache && tempEditedResCache?.length > 0;
                    const editedVal = selected !== undefined && selected?.rep ? selected?.rep : null;

                    return (
                        <div className='flex items-center gap-2'>
                            {patientsFilter == PatientsFilter.UNASSIGNED ? (
                                <SelectUserWithButton
                                    options={usersData && getMappedUserDetailsForChose(usersData)}
                                    labelType='formLabelMedeum'
                                    onSelectedValuesChange={(eventData: any) =>
                                        handleSelectedValuesChangeSingle(eventData, row.original)
                                    }
                                    defaultValue='Assign'
                                    error={'enter the valid name'}
                                    isBorderedError={true}
                                    additionalErrorClasses='text-red-500 text-xs pt-1'
                                    selectedValues={selectedValues}
                                    isNoNBoarder={true}
                                    edited={isEdited}
                                    editedValue={editedVal}
                                />
                            ) : (
                                <Typhography component='span'>
                                    {rPersons?.fullName && rPersons?.fullName + ''}
                                </Typhography>
                            )}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'gad7',
                header: () => 'GAD-7',
                size: 100,
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    return (
                        <Typhography component='div' additionalClasses='flex' data-tooltip-id='gad7'>
                            {row.original.gad7 ? (
                                <Typhography component='div' additionalClasses='flex'>
                                    <div data-tooltip-id={`${'gad7-tooltip-prev' + row.id}`}>
                                        {row.original.gad7?.points[1]?.sum}
                                    </div>
                                    <Typhography
                                        additionalClasses={
                                            row.original.gad7?.points[1]?.sum !== undefined ? 'px-1' : ''
                                        }
                                    >
                                        {row.original.gad7?.points[1]?.sum !== undefined ? ' => ' : ''}
                                    </Typhography>
                                    <div data-tooltip-id={`${'gad7-tooltip-current' + row.id}`}>
                                        {row.original.gad7?.points[0]?.sum !== undefined
                                            ? row.original.gad7?.points[0]?.sum
                                            : ''}
                                    </div>
                                    <Tooltip
                                        id={`${'gad7-tooltip-prev' + row.id}`}
                                        style={{
                                            zIndex: 1000,
                                            borderRadius: '10px',
                                            boxShadow:
                                                '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            padding: '16px',
                                        }}
                                        place='bottom-start'
                                        arrowColor='transparent'
                                        opacity={1}
                                    >
                                        <Typhography component='div' additionalClasses='text-xs text-gray-700'>
                                            {row.original.gad7?.points[1]?.date}
                                        </Typhography>
                                    </Tooltip>
                                    <Tooltip
                                        id={`${'gad7-tooltip-current' + row.id}`}
                                        style={{
                                            zIndex: 1000,
                                            borderRadius: '10px',
                                            boxShadow:
                                                '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            padding: '16px',
                                        }}
                                        place='bottom-start'
                                        arrowColor='transparent'
                                        opacity={1}
                                    >
                                        <Typhography component='div' additionalClasses='text-xs text-gray-700'>
                                            {row.original.gad7?.points[0]?.date}
                                        </Typhography>
                                    </Tooltip>
                                </Typhography>
                            ) : (
                                <Typhography>{'-'}</Typhography>
                            )}
                        </Typhography>
                    );
                },
            },
            {
                accessorKey: 'phq9',
                header: () => 'PHQ-9',
                size: 100,
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    return (
                        <Typhography component='div' additionalClasses='flex'>
                            {row.original.phq9 ? (
                                <Typhography component='div' additionalClasses='flex'>
                                    <div data-tooltip-id={`${'phq9-tooltip-prev' + row.id}`}>
                                        {row.original.phq9?.points[1]?.sum}
                                    </div>
                                    <Typhography
                                        additionalClasses={
                                            row.original.phq9?.points[1]?.sum !== undefined ? 'px-1' : ''
                                        }
                                    >
                                        {row.original.phq9?.points[1]?.sum !== undefined ? '=>' : ''}
                                    </Typhography>
                                    <div data-tooltip-id={`${'phq9-tooltip-current' + row.id}`}>
                                        {row.original.phq9?.points[0]?.sum !== undefined
                                            ? row.original.phq9?.points[0]?.sum
                                            : ''}
                                    </div>
                                    <Tooltip
                                        id={`${'phq9-tooltip-prev' + row.id}`}
                                        style={{
                                            zIndex: 1000,
                                            borderRadius: '10px',
                                            boxShadow:
                                                '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            padding: '16px',
                                        }}
                                        place='bottom-start'
                                        arrowColor='transparent'
                                        opacity={1}
                                    >
                                        <Typhography component='div' additionalClasses='text-xs text-gray-700'>
                                            {row.original.phq9?.points[1]?.date}
                                        </Typhography>
                                    </Tooltip>
                                    <Tooltip
                                        id={`${'phq9-tooltip-current' + row.id}`}
                                        style={{
                                            zIndex: 1000,
                                            borderRadius: '10px',
                                            boxShadow:
                                                '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            padding: '16px',
                                        }}
                                        place='bottom-start'
                                        arrowColor='transparent'
                                        opacity={1}
                                    >
                                        <Typhography component='div' additionalClasses='text-xs text-gray-700'>
                                            {row.original.phq9?.points[0]?.date}
                                        </Typhography>
                                    </Tooltip>
                                </Typhography>
                            ) : (
                                <Typhography>{'-'}</Typhography>
                            )}
                        </Typhography>
                    );
                },
            },
            {
                accessorKey: 'unresolved',
                header: () => 'Unresolved',
                size: 100,
                // id: 'email',
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    const unresolved = row.original.challenges?.filter(
                        (val, _idx) => val.status !== CHALLENGE_STATUS.RESOLVED,
                    );
                    const urgent = row.original.challenges?.filter(
                        (val, _idx) =>
                            val.status !== CHALLENGE_STATUS.RESOLVED && val.urgency === CHALLENGE_URGENCY.URGENT,
                    );

                    return (
                        <Typhography
                            component='div'
                            additionalClasses='flex items-center ml-4 gap-2'
                            onClick={() => handleUnresolvedClick(row)}
                        >
                            {urgent && urgent?.length > 0 ? (
                                <div
                                    data-tooltip-id='urgentTooltip'
                                    onFocus={() => unresolved && setTooltipValue(unresolved.length)}
                                >
                                    <TooltipWithChildren
                                        tooltipHeading=''
                                        additionalClasses=''
                                        tooltipContent={
                                            <Typhography>
                                                <Typhography
                                                    component='div'
                                                    additionalClasses='w-50 text-nowrap cursor-pointer'
                                                >
                                                    <ul className='list-disc pl-5 space-y-2'>
                                                        <li className='text-gray-700'>
                                                            {unresolved?.length} {language.list.tooltipChallenges}
                                                        </li>
                                                        <li className='text-gray-700'>
                                                            {urgent?.length?.toString()} {language.list.tooltipUrgent}
                                                        </li>
                                                    </ul>
                                                </Typhography>
                                            </Typhography>
                                        }
                                        tooltipIcon='info'
                                        tooltipContentHighlighted={true}
                                    >
                                        <div className='inline-flex items-center space-x-2'>
                                            <Typhography>
                                                {unresolved && unresolved?.length > 0 ? unresolved?.length : '0'}
                                            </Typhography>
                                            <ImagePreview
                                                text={urgent?.length?.toString()}
                                                isSmall={true}
                                                imageUrl={''}
                                                h={'h-5'}
                                                w={'w-5'}
                                                bgColor={'bg-[#F23A5C]'}
                                            />
                                        </div>
                                    </TooltipWithChildren>
                                </div>
                            ) : (
                                <Typhography additionalClasses=''>
                                    {unresolved && unresolved?.length > 0 ? (
                                        <div
                                            data-tooltip-id='urgentTooltip'
                                            onFocus={() => unresolved && setTooltipValue(unresolved.length)}
                                        >
                                            <TooltipWithChildren
                                                tooltipHeading=''
                                                additionalClasses=''
                                                tooltipContent={
                                                    <Typhography>
                                                        <Typhography
                                                            component='div'
                                                            additionalClasses='w-50 text-nowrap cursor-pointer'
                                                        >
                                                            <ul className='list-disc pl-5 space-y-2'>
                                                                <li className='text-gray-700'>
                                                                    {unresolved?.length}{' '}
                                                                    {language.list.tooltipChallenges}
                                                                </li>
                                                            </ul>
                                                        </Typhography>
                                                    </Typhography>
                                                }
                                                tooltipIcon='info'
                                                tooltipContentHighlighted={true}
                                            >
                                                <Typhography>{unresolved?.length}</Typhography>
                                            </TooltipWithChildren>
                                        </div>
                                    ) : (
                                        '0'
                                    )}
                                </Typhography>
                            )}
                        </Typhography>
                    );
                },
            },
            {
                accessorKey: 'resolved',
                header: () => 'Resolved',
                size: 100,
                // id: 'email',
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    const resolved = row.original.challenges?.filter(
                        (val, _idx) => val.status === CHALLENGE_STATUS.RESOLVED,
                    );

                    return (
                        <Typhography component='div' additionalClasses='flex items-center ml-4 gap-2'>
                            <Typhography additionalClasses=''>
                                {resolved && resolved?.length > 0 ? resolved?.length : '0'}
                            </Typhography>
                        </Typhography>
                    );
                },
            },
            {
                accessorKey: 'bestWayToContact',
                header: 'Last contact',
                size: 100,
                footer: (props) => props.column.id,
                sortDescFirst: false,
                cell: ({ row }) => {
                    const rPersons = row.original.lastContact
                        ? moment(row.original.lastContact).format('MM/DD/YYYY')
                        : '';
                    return (
                        <div className='flex items-center gap-2'>
                            <Typhography component='div'>{rPersons ? rPersons : ''}</Typhography>
                        </div>
                    );
                },
            },
            {
                accessorKey: 'primaryLanguage',
                header: 'Language',
                size: 100,
                sortDescFirst: false,
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'phoneNumber',
                header: 'Contact',
                // size: 200,
                footer: (props) => props.column.id,
                sortDescFirst: false,
                cell: ({ row }) => {
                    const rPersons = row.original.phoneNumber;
                    return (
                        <div className='flex items-center gap-2'>
                            <Typhography component='span'>{rPersons}</Typhography>
                            {rPersons && row.original.bestWayToContact && (
                                <Typhography>
                                    <a data-tooltip-id={`${'contact-tooltip' + row.id}`}>
                                        <Icon iconType={'InfoLarge'} color='blue' />
                                    </a>
                                    <Tooltip
                                        id={`${'contact-tooltip' + row.id}`}
                                        style={{
                                            zIndex: 1000,
                                            borderRadius: '10px',
                                            boxShadow:
                                                '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            padding: '16px',
                                        }}
                                        place='bottom-start'
                                        arrowColor='transparent'
                                        opacity={1}
                                    >
                                        <Typhography
                                            component='div'
                                            additionalClasses='text-black mb-2 text-xs font-bold'
                                        >
                                            Best Way to Contact
                                        </Typhography>
                                        <Typhography component='div' additionalClasses='text-xs text-gray-700'>
                                            {row.original.bestWayToContact}
                                        </Typhography>
                                    </Tooltip>
                                </Typhography>
                            )}
                        </div>
                    );
                },
            },
        ],
        [usersData, patients, selectedValues, tempEditedRes, showTooltip, tooltipContent],
    );

    const getLoadingState = (): boolean => {
        const ldState = props.isLoading || patientsDataIsFetching;
        return ldState;
    };
    const [selectedTab, setSelectedTab] = useState('challenges');
    const sideBarTabClick = (tabVal: string) => {
        setSelectedTab(tabVal);
    };
    const solutionNavigation = (itemsId: string | undefined) => {
        navigate(`${get(RouteRegistry, 'patient.paths.view.path')}/${itemsId}`, {
            state: { isEnableSolution: true },
        });
    };

    const getTodos = () => {
        if (patientsData) {
            return extractTodosData(patientsData.data.results);
        } else {
            return [];
        }
    };

    const handleNameClick = (id?: String) => {
        navigate(`${RouteRegistry.patient.paths.view.path}/${id}`);
    };

    const toggleTodoCheckbox = async (req: ITodoToggleRequestParams) => {
        await toggleTodoCheckboxEndPoint(req);
    };

    const toggleTodoCheckboxEndPoint = async (req: ITodoToggleRequestParams) => {
        await props.toggleTodoCheckbox(req);
    };

    useEffect(() => {
        patientsDataRefetch();
    }, [location]);

    return (
        <div className='max-w-full h-[calc(100vh-100px)] overflow-hidden flex flex-col'>
            {receivedData && receivedData.isSaved && <Message type={'success'} text={'Patient successfully saved!.'} />}
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <div className='relative'>{getLoadingState() && <LoadingProgress isLoading={getLoadingState()} />}</div>

            <Typhography component='div' additionalClasses='flex justify-between my-8 items-center'>
                <Typhography component='div' additionalClasses=''>
                    {usersData && usersData.length > 0 && (
                        <FilterButtonGroup
                            options={options}
                            optionsUser={usersData && getMappedUserDetailsForChose(usersData)}
                            onSelect={handleSelect}
                            selected={Object.values(PatientsFilter).indexOf(patientsFilter) + 1}
                            onSelectedUsers={onSelectedUsers}
                        />
                    )}
                </Typhography>

                {rowSelection?.length == 0 ? (
                    <Typhography component='div' additionalClasses='flex justify-between items-center gap-2'>
                        <Typhography component='div' additionalClasses='flex w-44'>
                            <IconInput
                                type='text'
                                value={searchText}
                                onChange={(e: any) => setSearchTextOnChange(e)}
                                isCrossIcon={searchText.length > 0 ? true : false}
                                isCrossIconHandler={() => setSearchText('')}
                                placeholder='Search By Name'
                                isIconRight={true}
                                rightIcon={'Search'}
                                labelSpanClasses='text-21s'
                                additionalClasses='mt-0 h-11'
                            />
                        </Typhography>
                        <Typhography component='div'>
                            <InputDatePicker
                                onChange={handleDobChange}
                                selectedDate={selectedDate}
                                labelType='formLabelMedeum'
                                isAdditionalErrorInput={true}
                                additionalErrorClasses='text-red-500 text-21s pt-1'
                                height='h-11'
                                placeHolder='Search By DOB'
                            />
                        </Typhography>
                        <Typhography component='div' additionalClasses='flex gap-2'>
                            <Typhography component='div'>
                                <Button
                                    additionalClasses='text-white text-21s font-medium justify-center bg-[#006CD0]'
                                    buttonLabel=' + Add new patient'
                                    data-testId='InputField-id'
                                    iconAlignment='left'
                                    iconType=''
                                    isIconEnabled={false}
                                    variants='primaryWhiteIcon'
                                    onClick={navigateToAdd}
                                />
                            </Typhography>
                        </Typhography>
                    </Typhography>
                ) : (
                    <Typhography
                        component='div'
                        additionalClasses='flex justify-end items-center gap-2 text-grey-540 font-normal text-21s'
                    >
                        <Typhography
                            component='div'
                            additionalClasses='mr-4'
                        >{`Select ${rowSelection?.length} users`}</Typhography>
                        <SelectUserWithButton
                            options={usersData && getMappedUserDetailsForChose(usersData)}
                            labelType='formLabelMedeum'
                            onSelectedValuesChange={handleSelectedValuesChangeAll}
                            defaultValue='Assign Responsible'
                            error={'enter the valid name'}
                            isBorderedError={true}
                            additionalErrorClasses='text-red-500 text-xs pt-1 '
                            isLeft={false}
                            isNoNBoarder={true}
                            selectedValues={selectedValues}
                        />
                    </Typhography>
                )}
            </Typhography>
            <Typhography component='div' additionalClasses='flex gap-4'>
                <Typhography
                    component='div'
                    //additionalClasses={`${sidePannelDataList && sidePannelDataList.length > 0 ? 'w-9/12 ' : 'w-full'}`}
                    additionalClasses={`w-9/12`}
                >
                    {patients && (
                        <Typhography component='div'>
                            {patients && (
                                <Table
                                    {...{
                                        data: patients,
                                        columns,
                                        sorting,
                                        onSortingChange,
                                        pagination,
                                        totalRecords,
                                        isManualPagination,
                                        isManualSorting,
                                        onPaginationChange,
                                        containerHeightRemoveInPixel: 283,
                                        rowClick,
                                        setRowSelection,
                                        isLoading: false,
                                    }}
                                    key={key}
                                />
                            )}
                        </Typhography>
                    )}
                </Typhography>
                <Typhography component='div' additionalClasses={'w-3/12'}>
                    {
                        <SideBarListView
                            urgentDataList={sidePannelDataList ?? []}
                            listMasterData={masterData}
                            onClickTab={sideBarTabClick}
                            selectedTab={selectedTab}
                            detailAction={solutionNavigation}
                            todoDataList={dataFilteredTodos}
                            handleNameClick={handleNameClick}
                            handleChallengeHeaderClick={handleChallengeHeaderClick}
                            toggleTodoCheckbox={toggleTodoCheckbox}
                        />
                    }
                </Typhography>
            </Typhography>

            {showNotification && (
                <GridNotification
                    count={rowSelection?.length !== 0 ? rowSelection.length : tempEditedRes?.length}
                    handleCancel={() => setShowNotification(false)}
                    handleUndo={() => undoUpdatePatient()}
                />
            )}
        </div>
    );
};
const PatientListComponent = patientHOC(PatientList);
export default PatientListComponent;
