import { createSlice } from '@reduxjs/toolkit';
import { ISchema } from 'dfh-form-generator';
import { RootState } from './store';

interface SchemaState {
    schema: ISchema;
}

const initialState = { schema: {} } as SchemaState;

const schemaSlice = createSlice({
    name: 'schema',
    initialState,
    reducers: {
        setSchema(state, action) {
            state.schema = action.payload;
        },
    },
});

export const { setSchema } = schemaSlice.actions;
export const getSchema = (state: RootState) => state.schema.schema;
export default schemaSlice.reducer;
