import { IReportingRepository } from './IReportingRepository';
import { useLazyReportingQuery } from 'api/reportingAPI';
import { IReportingRequestParams } from 'utils/types/reporting.type';

export const useReportingRepository = (): IReportingRepository => {
    const [getReportingFunction] = useLazyReportingQuery();

    const getReporting = (reportingReq: IReportingRequestParams): Promise<any> => {
        try {
            const result = getReportingFunction(reportingReq);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during get reporting data:', error);
            throw new Error('An error occurred during get reporting data');
        }
    };

    return {
        getReporting,
    };
};
