import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from '../utils/types/api.type';
import { baseQueryWithReAuth } from './interceptors';
import { IReportingRequestParams } from 'utils/types/reporting.type';

const baseQuery = baseQueryWithReAuth;

export const reportingAPI = createApi({
    reducerPath: '/reportingApi',
    baseQuery,
    tagTypes: ['Reporting'],
    endpoints: (builder) => ({
        reporting: builder.query<ApiResponse, IReportingRequestParams>({
            query: (req) => ({
                url: `reporting?fromDate=${req.fromDate}&toDate=${req.toDate}&type=${req.type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyReportingQuery } = reportingAPI;
