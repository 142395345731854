import { Button, Message, Typhography } from 'dfh-ui-library';
import patientHOC from '../index';
import { IPatientProps } from 'utils/types/patient.type';
import { DeleteScreen } from '../helpers/iconHelper';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const PatientDelete = (props: IPatientProps) => {
    const location = useLocation();
    const receivedData = location.state;
    useMemo(() => {
        if (receivedData === null) {
            props.navigateToPatients();
        }
    }, [receivedData]);

    return (
        <>
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <Typhography component='div' additionalClasses='flex flex-col my-12 justify-center items-center pt-20'>
                <DeleteScreen />
                <Typhography component='div' additionalClasses='my-6 text-16s font-semibold text-black-540 text-center'>
                    {receivedData?.name ? receivedData?.name : ''} was <br></br>deleted successfully
                </Typhography>
                <Typhography component='div' additionalClasses='flex gap-4'>
                    <Button
                        additionalClasses='text-21s font-medium items-center bg-[#006CD0]'
                        buttonLabel='Go to Patients'
                        data-testId='InputField-id'
                        iconAlignment='right'
                        iconType='ArrowBC'
                        isIconEnabled
                        variants='primaryBlue'
                        onClick={() => props.navigateToPatients()}
                    />
                </Typhography>
            </Typhography>
        </>
    );
};
const PatientDeleteComponent = patientHOC(PatientDelete);
export default PatientDeleteComponent;
