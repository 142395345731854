import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './interceptors';
import { ApiResponse, IReactionRequest } from 'utils/types/api.type';
import { ITodoToggleRequestParams } from '../utils/types/patient.type';

export const homeAPI = createApi({
    reducerPath: '/homeApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        dashboard: builder.mutation({
            query: (data) => ({
                url: '/dahboard/data',
                method: 'POST',
                body: data,
            }),
        }),
        getDashboardUpdates: builder.query<ApiResponse, string | undefined>({
            query: (userId) => ({
                url: userId ? `/dashboard/updates?userId=${userId}` : `/dashboard/updates`,
                method: 'GET',
            }),
        }),
        updateReaction: builder.mutation<ApiResponse, IReactionRequest>({
            query: (data) => ({
                url: `/dashboard/updates/${data.chId}`,
                method: 'PUT',
                body: data,
            }),
        }),
        getDashboardMetrix: builder.query<ApiResponse, string | undefined>({
            query: (userId) => ({
                url: userId ? `/dashboard/metrics?userId=${userId}` : `/dashboard/metrics`,
                method: 'GET',
            }),
        }),
        getDashboardUrgentChallenges: builder.query<ApiResponse, string | undefined>({
            query: (userId) => ({
                url: `/dashboard/urgent-challenges?userId=${userId}`,
                method: 'GET',
            }),
        }),
        getDashboardTodosChallenges: builder.query<ApiResponse, string | undefined>({
            query: (userId) => ({
                url: `/dashboard/todos?userId=${userId}`,
                method: 'GET',
            }),
        }),
        toggleDashboardTodoCheckbox: builder.mutation<ApiResponse, ITodoToggleRequestParams>({
            query: (data) => ({
                url: `/notes/toggle-todo/${data.noteId}`,
                method: 'PUT',
                body: {
                    ...data,
                    toggleOptions: data.toggleOptions,
                }
            })
        }),
    }),
});

export const {
    useDashboardMutation,
    useLazyGetDashboardUpdatesQuery,
    useUpdateReactionMutation,
    useLazyGetDashboardMetrixQuery,
    useLazyGetDashboardUrgentChallengesQuery,
    useLazyGetDashboardTodosChallengesQuery,
    useToggleDashboardTodoCheckboxMutation,
} = homeAPI;
