import React, { ComponentType, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthRepository } from 'repositories/AuthRepository';
import { useUserRepository } from 'repositories/UserRepository';
import RouteRegistry from 'routes/routeRegistry';
import { errorMsgInit } from 'utils/init';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import {
    ApiResponse,
    AuthApiRequest,
    IAuthRequest,
    IAuthUserDetailsRequest,
    IAuthVerifyRequest,
} from 'utils/types/api.type';
import { IErrorMsg, ISignUpProps } from 'utils/types/auth.type';

const signUpHOC = <P extends ISignUpProps>(WrappedComponent: ComponentType<ISignUpProps>) => {
    const signUpDataFetching: React.FC<P & ISignUpProps> = (props) => {
        const [isLoading, setIsLoading] = React.useState(false);
        const [errorMsg, setErrorMsg] = useState<IErrorMsg>(errorMsgInit);

        const navigate = useNavigate();
        const authRepository = useAuthRepository();
        const userRepository = useUserRepository();

        const localStorageService = LocalStorageService();

        const navigateToSignIn = () => {
            navigate(RouteRegistry.auth.paths.signIn.path);
        };

        const navigateToSignUp = () => {
            navigate(RouteRegistry.auth.paths.signUp.path);
        };

        const navigateToVerification = (email: string) => {
            navigate(RouteRegistry.auth.paths.verification.path, { state: { email: email } });
        };

        const navigateToHome = () => {
            navigate(RouteRegistry.app.paths.root.path);
        };

        // SignUp Function
        const signUp = async (req: AuthApiRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const signUpResult = await authRepository.signUp({
                    email: req.email,
                    password: req.password,
                    themeColor: req.themeColor
                });
                if (signUpResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Sign up failed.',
                    });
                setIsLoading(false);
                return signUpResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Verify Function
        const verify = async (req: IAuthVerifyRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const verifyResult = await authRepository.verify({
                    email: req.email,
                    confirmationCode: req.confirmationCode,
                });
                if (verifyResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Sign up failed.',
                    });
                setIsLoading(false);
                return verifyResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Resend Function
        const resendCode = async (req: IAuthRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const verifyResult = await authRepository.resendCode({
                    email: req.email,
                });
                if (verifyResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Resend code failed.',
                    });
                setIsLoading(false);
                return verifyResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // User Details Function
        const userDetails = async (req: IAuthUserDetailsRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const verifyResult = await userRepository.userDetails({
                    email: req.email,
                    fullName: req.fullName,
                    position: req.position,
                });
                if (verifyResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving user details failed.',
                    });
                if (verifyResult.data) {
                    const accessToken = localStorageService.getAccessToken() || "";
                    const refreshToken = localStorageService.getRefreshToken() || "";
                    const idToken = localStorageService.getIdToken() || "";
                    const user = localStorageService.getUser();

                    localStorageService.setAuthTokens({ 
                        accessToken,
                        refreshToken,
                        idToken,
                        user: { ...user, fullName: verifyResult.data.data.fullName }
                    })
                }

                setIsLoading(false);
                return verifyResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Get User Position Function
        const userPositions = async (): Promise<ApiResponse | undefined> => {
            try {
                return await userRepository.userPositions();
            } catch (apiError) {
                return;
            }
        };

        const apiMethods = {
            signUp: signUp,
            verify: verify,
            resendCode: resendCode,
            userDetails: userDetails,
            userPositions: userPositions,
        };

        const navigateMethods = {
            navigateToSignIn: navigateToSignIn,
            navigateToSignUp: navigateToSignUp,
            navigateToVerification: navigateToVerification,
            navigateToHome: navigateToHome,
        };

        return (
            <WrappedComponent
                {...(props as P & ISignUpProps)}
                isLoading={isLoading}
                errorMsg={errorMsg}
                apiMethods={apiMethods}
                navigateMethods={navigateMethods}
            />
        );
    };

    return signUpDataFetching;
};

export default signUpHOC;
