import { AppConfig } from '../utils/types/appConfig.type';
import devConfig from './devConfig';
import prodConfig from './prodConfig';
import stagingConfig from './stagingConfig';

const getConfig = (stage: string): AppConfig => {
    switch (stage) {
        case "prod":
            return prodConfig;
        case "staging":
            return stagingConfig;
        case "dev":
            return devConfig;
        default:
            return devConfig;
    }
}

const config: AppConfig = getConfig(import.meta.env.VITE_STAGE);

export default config;
