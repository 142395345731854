import { ListMasterDataProps } from 'dfh-ui-library';
import { FilterOptions, FilterTypes } from 'utils/enum';

export const options = [
    { id: FilterTypes.MyPatient, value: 'My Patients' },
    { id: FilterTypes.Assigned, value: 'All Assigned Patients' },
    { id: FilterTypes.UnAssign, value: 'Unassigned Patients' },
    { id: FilterTypes.SelectUser, value: 'Select User' },
];
export const sortOptions = [
    {
        displayText: 'Urgent',
        value: 'urgent',
    },
    {
        displayText: 'Oldest',
        value: 'oldest',
    },
    {
        displayText: 'Newest',
        value: 'newest',
    },
];

export const masterData: ListMasterDataProps = {
    title: 'Urgent Challenges',
    actionText: 'See all',
    actionTextReves: 'Collapse All',
};

export const letterComponentSchema = [
    { fieldId: 1, component: 'ButtonGroup' },
    { fieldId: 2, component: 'Input' },
    { fieldId: 3, component: 'ButtonGroupWithInputs' },
    { fieldId: 4, component: 'InputsGroup' },
    { fieldId: 5, component: 'ButtonGroupWithUpload' },
    { fieldId: 6, component: 'AccommodationComponent' },
    { fieldId: 7, component: 'TagSelect' },
    { fieldId: 8, component: 'MultiInputGroups' },
    { fieldId: 9, component: 'Date' },
    { fieldId: 10, component: 'TextArea' },
    { fieldId: 11, component: 'Signature' },
    { fieldId: 12, component: 'checkbox' },
    { fieldId: 13, component: 'label' },
    { fieldId: 14, component: 'TyphographyFormGen'},
    { fieldId: 15, component: 'MultiSelectFormGen'} 
];

export const filterOptions = [
    {
        displayText: 'All docs',
        value: FilterOptions.AllDocs,
    },
    {
        displayText: 'Forms',
        value: FilterOptions.Forms,
    },
    {
        displayText: 'Letters',
        value: FilterOptions.Letters,
    },
    {
        displayText: 'Uploads',
        value: FilterOptions.Uploads,
    },
];
