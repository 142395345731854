import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './interceptors';
import { ApiResponse, ISchemaRequestParams } from 'utils/types/api.type';
import { ILetterRequest, ILetterRequestRInfo, IScreenerRequest } from 'utils/types/screener.type';

const baseQuery = baseQueryWithReAuth;

export const screeningAPI = createApi({
    reducerPath: '/screeningApi',
    baseQuery,
    tagTypes: ['Screening'],
    endpoints: (builder) => ({
        getSchema: builder.query({
            query: () => ({
                url: `/ui-gen/1.0.0`,
                method: 'GET',
            }),
        }),
        getPatientSchema: builder.query<ApiResponse, ISchemaRequestParams>({
            query: (req) => {
                return {
                    url: `/ui-gen/1.0.0?patientId=${req.patientId}${req.dateVersioned ? '&dateVersioned=' + req.dateVersioned : ''}`,
                    method: 'GET',
                };
            },
        }),
        getPatientLetterSchema: builder.query<ApiResponse, ISchemaRequestParams>({
            query: (req) => ({
                url: `/pdfgen/${req.varientId}?patientChallengeId=${req.patientChallengeId}&patientId=${req.patientId}&schemaVersion=1.0.0`,
                method: 'GET',
            }),
        }),
        getPatientLetterSchemaReleaseInfo: builder.query<ApiResponse, string>({
            query: (req) => ({
                url: `/patients/release-of-information/${req}`,
                method: 'GET',
            }),
        }),
        updateScreener: builder.mutation<ApiResponse, IScreenerRequest>({
            query: (data) => ({
                url: '/surveys',
                method: 'POST',
                body: data,
            }),
        }),
        updateLetter: builder.mutation<ApiResponse, ILetterRequest>({
            query: (data) => ({
                url: `/pdfgen/${data.varientId}`,
                method: 'POST',
                body: {
                    configJSON: data.configJSON,
                    solutionId: data.solutionId,
                    patientId: data.patientId,
                    patientChallengeId: data.patientChallengeId,
                },
            }),
        }),
        updateLetterInfo: builder.mutation<ApiResponse, ILetterRequestRInfo>({
            query: (data) => ({
                url: `/patients/release-of-information/${data.patientId}`,
                method: 'POST',
                body: {
                    configJSON: data.configJSON,
                },
            }),
        }),
        screenerVersions: builder.query<ApiResponse, ISchemaRequestParams>({
            query: (req) => ({
                url: `surveys/versions/${req.patientId}`,
                method: 'Get',
            }),
        }),
    }),
});

export const {
    useGetSchemaQuery,
    useUpdateScreenerMutation,
    useGetPatientSchemaQuery,
    useLazyGetPatientLetterSchemaQuery,
    useScreenerVersionsQuery,
    useUpdateLetterMutation,
    useLazyGetPatientLetterSchemaReleaseInfoQuery,
    useUpdateLetterInfoMutation,
} = screeningAPI;
