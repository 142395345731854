import { Button, Typhography } from 'dfh-ui-library';
import loginHOC from '../index';
import { ILoginProps } from 'utils/types/auth.type';

const ResetEmail = (props: ILoginProps) => {
    return (
        <Typhography component='div' additionalClasses='relative top-24'>
            <Typhography type='authHeader'>Reset your password</Typhography>
            <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                <Typhography additionalClasses='py-2'>
                    <Typhography>
                        <Typhography type='textMediumGray'>
                            You've got mail. Check your email for a password reset link.
                        </Typhography>
                    </Typhography>
                </Typhography>

                <Typhography additionalClasses='my-8'>
                    <Button
                        buttonLabel='Login'
                        variants='large'
                        onClick={props.navigateMethods.navigateToSignIn}
                        additionalClasses='bg-[#006CD0] hover:bg-[#006CD0]'
                    ></Button>
                </Typhography>
                <Typhography additionalClasses='py-2 text-center'>
                    <Typhography component='p'>
                        <Typhography type='textMediumGray'>Dont you have an account?</Typhography>

                        <Typhography
                            type='linkTextMedium'
                            additionalClasses='cursor-pointer ml-1'
                            onClick={props.navigateMethods.navigateToSignUp}
                        >
                            Sign Up
                        </Typhography>
                    </Typhography>
                </Typhography>
            </Typhography>
        </Typhography>
    );
};
const ResetEmailComponent = loginHOC(ResetEmail);
export default ResetEmailComponent;
