import { UrgentDataProps } from 'dfh-ui-library';
import moment from 'moment';
import { CHALLENGE_STATUS, CHALLENGE_URGENCY } from 'utils/enum';

export interface UserObj {
    id?: string;
    email?: string;
    fullName?: string;
    phoneNumber?: string;
    _id?: string;
    verified?: boolean;
}

const dgColorArray = ['bg-yellow-400', 'bg-lime-400', 'bg-teal-400', 'bg-green-400'];

export const getFristLettersFromString = (inputString: string) => {
    // Split the string into words
    const words = inputString.split(' ');
    if (words.length == 1) return words[0].slice(0, 2);
    // Extract the first two letters from each word
    const result = words.map((word) => word.slice(0, 1));

    // Join the results into a new string
    const outputString = result.join('');
    return outputString.toUpperCase();
};
export const extractUrgentData = (jsonData: any): UrgentDataProps[] => {
    const urgentData: UrgentDataProps[] = jsonData
        .filter((patient: any) => patient.challenges && patient.challenges.length > 0)
        .map((patient: any) => {
            const urgentChallenges = patient.challenges
                .filter(
                    (challenge: any) =>
                        challenge.urgency === CHALLENGE_URGENCY.URGENT &&
                        challenge.status !== CHALLENGE_STATUS.RESOLVED,
                )
                .map((challenge: any) => ({
                    urgency: challenge.urgency,
                    challengeName: challenge.details.map((detail: any) => detail.challengeName).join(', '),
                    _id: challenge._id,
                }));

            return {
                fullName: patient.fullName,
                dob: moment(patient.dob).format('YYYY-MM-DD'), // Assuming the date of birth is the first part of the datetime string
                imageSignedUrl: patient.imageSignedUrl, // Assuming the imageId is directly used as imageSignedUrl
                challenges: urgentChallenges,
                notes: patient.notes,
                id: patient._id,
            };
        });

    return urgentData.filter((patient) => patient.challenges && patient.challenges.length > 0);
};

export const extractTodosData = (jsonData: any): UrgentDataProps[] => {
    const noteData: UrgentDataProps[] = jsonData
        .filter((patient: any) => patient.notes && patient.notes.length > 0)
        .map((patient: any) => {
            return {
                fullName: patient.fullName,
                dob: moment(patient.dob).format('YYYY-MM-DD'), // Assuming the date of birth is the first part of the datetime string
                imageSignedUrl: patient.imageSignedUrl, // Assuming the imageId is directly used as imageSignedUrl
                notes: patient.notes,
                id: patient._id,
            };
        });
    return noteData;
};

export const getRandomBgColor = () => {
    const randomIndex = Math.floor(Math.random() * dgColorArray.length);
    return dgColorArray[randomIndex];
};

export const getMappedUserDetailsForChose = (userData: UserObj[]) => {
    if (userData && userData.length > 0) {
        const userDataWithUpdate = userData.map((user) => ({
            ...user,
            id: user._id ? user._id : '',
            displayText: user.fullName !== undefined ? user.fullName : '',
            iconText:
                user.fullName &&
                getFristLettersFromString(user.fullName) &&
                getFristLettersFromString(user.fullName) !== undefined
                    ? getFristLettersFromString(user.fullName)
                    : 'D',
            bgColor: getRandomBgColor(),
            imageUrl: '',
        }));
        return userDataWithUpdate;
    }
};
