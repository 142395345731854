import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartDataType } from 'utils/types/reporting.type';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false, // Disable default legend
        },
        title: {
            display: false, // Disable default title
        },
    },
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false, // Display vertical grid lines
            },
        },
        y: {
            beginAtZero: true,
            stacked: true,
            grid: {
                display: true, // Display vertical grid lines
            },
            ticks: {
                callback: (value: any) => `${value}`,
            },
        },
    },
};

interface BarChartSingleProps {
    title: string;
    isLegendVisible: boolean;
    legend1?: string;
    legend2?: string;
    data: ChartDataType;
}

const BarChartSingle: React.FC<BarChartSingleProps> = ({ title, isLegendVisible = false, legend1, legend2, data }) => {
    return (
        <div className='w-full p-4 bg-white border rounded-lg shadow-md'>
            <div className='flex justify-between items-center mb-4'>
                <h2 className='text-21s font-medium'>{title}</h2>
                {isLegendVisible && (
                    <div className='flex items-center'>
                        <div className='flex items-center mr-4 text-12s'>
                            <span className='w-4 h-4 bg-[#006CD0] inline-block mr-2'></span>
                            <span>{legend1}</span>
                        </div>
                        <div className='flex items-center text-12s'>
                            <span className='w-4 h-4 bg-[#C6D1ED] inline-block mr-2'></span>
                            <span>{legend2}</span>
                        </div>
                    </div>
                )}
            </div>
            <Bar options={options} data={data} />
        </div>
    );
};

export default BarChartSingle;
