import { IHomeHOCProps, ITodoToggleRequestParams } from 'utils/types/patient.type';
import { useHomeRepository } from 'repositories/HomeRepository';
import { ComponentType, useState } from 'react';
import { ApiResponse, IReactionRequest } from 'utils/types/api.type';

const homeHOC = <P extends IHomeHOCProps>(WrappedComponent: ComponentType<IHomeHOCProps>) => {
    const HomeDataFetching: React.FC<P & IHomeHOCProps> = (props) => {
        const homeRepository = useHomeRepository();
        const [isFetching, setIsFetching] = useState(false);

        const getDashboardUpdates = async (userId?: string): Promise<any> => {
            setIsFetching(true);
            const result = await homeRepository.getDashboardUpdates(userId);

            setIsFetching(isFetching);
            return result;
        };

        const getDashboardMetrix = async (userId?: string): Promise<any> => {
            setIsFetching(true);
            const result = await homeRepository.getDashboardMetrix(userId);

            setIsFetching(isFetching);
            return result;
        };

        const getDashboardUrgentChallenges = async (userId: string): Promise<any> => {
            setIsFetching(true);
            const result = await homeRepository.getDashboardUrgentChallenges(userId);

            setIsFetching(isFetching);
            return result;
        };
        const getDashboardTodosChallenges = async (userId: string): Promise<any> => {
            setIsFetching(true);
            const result = await homeRepository.getDashboardTodosChallenges(userId);

            setIsFetching(isFetching);
            return result;
        };

        // update patient challenges
        const updateReaction = async (req: IReactionRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsFetching(true);
                const result = await homeRepository.updateReactions(req);
                if (result.error) setIsFetching(false);
                return result;
            } catch (apiError) {
                setIsFetching(false);
                return;
            }
        };

        const toggleDashboardTodoCheckbox = async (req: ITodoToggleRequestParams): Promise<ApiResponse | undefined> => {
            try {
                setIsFetching(true);
                const result = await homeRepository.toggleDashboardTodoCheckbox(req);
                if (result.error) {
                    setIsFetching(false);
                }
                return result;
            } catch (apiError) {
                setIsFetching(false);
                return;
            }
        };      

        const apiMethods = {
            getDashboardUpdates,
            updateReaction,
            getDashboardMetrix,
            getDashboardUrgentChallenges,
            getDashboardTodosChallenges,
            toggleDashboardTodoCheckbox,
        };
        return <WrappedComponent {...props} {...apiMethods} isFetching={isFetching} />;
    };

    return HomeDataFetching;
};

export default homeHOC;
