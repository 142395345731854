import NotesView from 'containers/patientHOC/components/NoteView';
import { getFristLettersFromString } from 'containers/patientHOC/helpers/userHelpers';
import { ImagePreview, NoteItemProps } from 'dfh-ui-library';
import moment from 'moment';
import { useState } from 'react';
import { EmojiTypes } from 'utils/enum';
import { EmojiProps, NotificationProps } from 'utils/types/home.type';

const updateNotification: React.FC<NotificationProps> = ({
    userName,
    date = '',
    patientName = '',
    challenge = '',
    noteId,
    notes = [],
    id,
    cId,
    reactions,
    addUpdateReaction,
    emojiList,
    updateReactionEndPoint,
    setReactions,
    themeColor,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleAddReaction = (cId: string, emoji: string, key: string, userId: string) => {
        addUpdateReaction(cId, emoji, key, 1, userId);
    };

    const addReaction = (id: string, emoji: EmojiProps, increment: number, userId: string) => {
        updateReactionEndPoint(userId, id, emoji.key, increment);
        const currentReaction = reactions.find((reaction: any) => reaction.id === id);
        if (currentReaction) {
            const updatedEmoji = currentReaction.emoji.map((e: any) =>
                e.key === emoji.key ? { ...e, count: e.count + increment } : e,
            );
            if(setReactions) {
                setReactions((prevReactions: any) =>
                    prevReactions.map((reaction: any) =>
                        reaction.id === id ? { ...reaction, emoji: updatedEmoji } : reaction,
                    ),
                );
            }
            
        }
    };

    const EmojiReaction: React.FC<{ id: string; emoji: EmojiProps; count: number; userId: string; isCurrentUserReacted: boolean }> = ({
        id,
        emoji,
        count,
        isCurrentUserReacted,
        userId,
    }) => {
        return (
            <span
                className={`${count > 0 ? `${isCurrentUserReacted ? 'bg-[#D7D7D7]': 'bg-[#EFF7FF]'} rounded-md p-1 mt-1 cursor-pointer mr-2` : ''}`}
                onClick={() => addReaction(id, emoji, count > 0 ? -1 : 1, userId)}
            >
                {count > 0 && (
                    <span className={emoji.emoji === '❤️' ? 'text-[#FF3F32] mr-0.5' : 'mr-0.5'}>{emoji.emoji}</span>
                )}
                <span className='text-sm text-gray-500'>{count > 0 && count}</span>
            </span>
        );
    };    

    const isValidNote = (notes: NoteItemProps[]): boolean => {
        return notes.some((note) => note?.enableCheckbox || note?.text !== '');
    };

    return (
        <div
            className='flex items-start space-x-3 p-4 border-b border-gray-200 relative'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            key={id}
        >
            <div
                className={`flex space-x-2 mb-1 absolute border cursor-pointer right-2 rounded-md p-2 z-50 top-[-10px] bg-[#F9FAFB]  border-gray-200 ${isHovered ? 'visible' : 'invisible'}`}
            >
                {emojiList.map((emoji: any, index: any) => (
                    <div
                        key={index}
                        className='flex items-center space-x-1 cursor-pointer hover:bg-[#EEF7FF]'
                        onClick={() => handleAddReaction(cId, emoji.emoji, emoji.key, id)}
                    >
                        <span className={emoji.key === EmojiTypes.HART ? 'text-[#FF3F32]' : ''}>{emoji.emoji}</span>
                    </div>
                ))}
            </div>
            <ImagePreview
                w={'w-11'}
                h={'h-11'}
                text={userName && getFristLettersFromString(userName)}
                isSmall={false}
                bgColor={themeColor && themeColor}
                textClasses={'text-36s'}
            />
            <div className='flex-1'>
                <div className='text-sm'>
                    <span className='font-medium text-gray-900'>{userName} </span>
                    <span className='font-normal text-gray-900'>resolved </span>
                    {challenge && <span className='font-medium text-gray-900'>"{challenge}" </span>}
                    {challenge && <span className='font-normal text-gray-900'>for patient </span>}
                    {challenge && <span className='font-medium text-gray-900'>{patientName}</span>}
                </div>
                <div className='text-sm text-gray-500'>{moment(date).format('MMM DD, YYYY [at] hh:mm a')}</div>
                {notes && notes.length > 0 && isValidNote(notes) && (
                    <div className='mt-2 text-sm bg-[#F9FAFB] p-2 rounded-md'>
                        <NotesView noteId={noteId} notes={notes} />
                    </div>
                )}
                {reactions && reactions.length > 0 && (
                <div className='flex flex-row pt-2'>
                    <div>
                        <div>
                                {reactions
                                    .filter((x: any) => x.id === cId)
                                    .flatMap((val: any) =>
                                        val.emoji.map((e: any) => (
                                            <EmojiReaction
                                                key={`${val.id}-${e.key}`}
                                                id={val.id}
                                                emoji={e}
                                                count={e.count}
                                                isCurrentUserReacted={e.isCurrentUserReacted}
                                                userId={id}
                                            />
                                        )),
                                    )}
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default updateNotification;
