export const language = {
    patientsEnrolled: {
        title: 'Patients enrolled',
        legendAssigned: 'Assigned',
        legendUnassigned: 'Unassigned',
        excelSheetTitle: 'Patients enrolled',
    },
    patientsScreenedAtLeastOnce: {
        title: 'Patients screened at least once for SDoH',
        excelSheetTitle: 'Patients at least one screener',
    },
    screenerOutcomes: {
        title: 'Screener outcomes',
        legendGreaterThanOneChallenge: '≥ 1 Challenge',
        legendNegative: 'Negative',
        excelSheetTitle: 'Screener outcomes',
    },
    averageNumberOfNewChallengesPerPatient: {
        title: 'Average number of new challenges per patient',
        excelSheetTitle: 'Challenges per patients',
    },
    totalChallengesResolved: {
        title: 'Total challenges resolved',
        excelSheetTitle: 'Challenges resolved',
    },
    patientsWithFoodInsecurity: {
        title: 'Patients with food insecurity',
        excelSheetTitle: 'Food insecurity',
    },
    patientsWithHousingInsecurity: {
        title: 'Patients with housing insecurity',
        excelSheetTitle: 'Housing insecurity',
    },
    patientsWithTransportationInsecurity: {
        title: 'Patients with transportation insecurity',
        excelSheetTitle: 'Transportation insecurity',
    },
    patientsWithIncomeInsecurity: {
        title: 'Patients with income insecurity',
        excelSheetTitle: 'Income insecurity',
    },
};
