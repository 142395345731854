import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useGetUsersQuery, useUserDetailsMutation, useUserPositionsMutation } from '../api/userAPI';
import { ApiResponse, IAuthUserDetailsRequest } from '../utils/types/api.type';
import { IUserRepository } from './IUserRepository';
import { SerializedError } from '@reduxjs/toolkit';

export const useUserRepository = (): IUserRepository => {
    const [mutateUserFunction] = useUserDetailsMutation();
    const [mutatePositionFunction] = useUserPositionsMutation();

    const userDetails = async (userData: IAuthUserDetailsRequest): Promise<any> => {
        try {
            const result = mutateUserFunction(userData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during user details:', error);
            throw new Error('An error occurred during user details');
        }
    };

    const userPositions = async (): Promise<any> => {
        try {
            const result = mutatePositionFunction({});
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during user positions:', error);
            throw new Error('An error occurred during user positions');
        }
    };

    const getUsers = (): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    } => {
        const { data, error, isLoading } = useGetUsersQuery(undefined);
        return { data, error, isLoading };
    };

    return {
        userDetails,
        userPositions,
        getUsers,
    };
};
