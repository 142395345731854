// Loading.tsx
import React from 'react';

interface LoadingProps {
    loading?: boolean;
}

const Loading: React.FC<LoadingProps> = () => {
    return (
        <div
            className='flex justify-center items-center h-16 bg-transparent absolute w-full z-50'
            style={{ height: '100%' }}
        >
            {/* Transparent background */}
            <div className='absolute inset-0 bg-grey-410 opacity-25'></div>

            <svg className='w-12 h-6 animate-spin' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                {/* Semi-circle path */}
                <path
                    className='stroke-current text-blue-500'
                    strokeLinecap='round'
                    strokeWidth='3'
                    d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12'
                />
            </svg>
        </div>
    );
};

export default Loading;
