import ReportingHOC from '..';
import LoadingProgress from 'components/Loading/LoadingProgress';
import BarChart from '../components/Barchart';
import BarChartSingle from '../components/BarchartSingle';
import BarChartAvg from '../components/BarChartAvg';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DatePicker, Typhography } from 'dfh-ui-library';
import moment from 'moment';
import { now } from 'lodash';
import { language } from '../helpers/language';
import {
    ChallengesPerPatients,
    ChallengesPerPatientsMergedData,
    ChartDataType,
    ChartDatasetType,
    DownloadReports,
    IReportingHOCProps,
    Insecurity,
    InsecurityMergedData,
    LabelData,
    PatientScreenedAtLeatOnce,
    PatientScreenedAtLeatOnceMergedData,
    PatientsEnrolled,
    PatientsEnrolledMergedData,
    ReportingType,
    ScreenerOutcomes,
    ScreenerOutcomesMergedData,
    TotalChallengesResolved,
    TotalChallengesResolvedMergedData,
    ExcelData,
    ScreenerOutcomesTypes,
    AssignedStatus,
} from 'utils/types/reporting.type';
import { ApiResponse } from 'utils/types/api.type';
import { WorkBook, utils, writeFileXLSX } from 'xlsx';
import DownloadDropdown from '../components/DownloadDropdown';

const labelsInitialData = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const barChartAvgInitialData = {
    labels: labelsInitialData,
    datasets: [
        {
            label: 'Bar chart avg',
            data: [], // Sample average data
            backgroundColor: '#006CD0', // Blue color
        },
    ],
};

const barChartInitialData = {
    labels: labelsInitialData,
    datasets: [
        {
            label: 'Assigned',
            data: [],
            backgroundColor: '#006CD0',
        },
        {
            label: 'Unassigned',
            data: [],
            backgroundColor: '#C6D1ED',
        },
    ],
};

const screenerOutcomesInitialData = {
    labels: labelsInitialData,
    datasets: [
        {
            label: 'Screeners with challenges',
            data: [],
            backgroundColor: '#006CD0',
        },
        {
            label: 'Screeners without challenges',
            data: [],
            backgroundColor: '#C6D1ED',
        },
    ],
};

const downloadReportsInitialValue = [
    {
        name: 'patients-enrolled',
        label: 'Total patients',
        isChecked: false,
    },
    {
        name: 'patient-at-least-one-screener',
        label: 'Patients screened at least once for SDoH ',
        isChecked: false,
    },
    {
        name: 'screener-outcomes',
        label: 'Screener outcomes ',
        isChecked: false,
    },
    {
        name: 'challenges-per-patients',
        label: 'Average number of new challenges per patient ',
        isChecked: false,
    },
    {
        name: 'challenges-resolved',
        label: 'Total challenges resolved ',
        isChecked: false,
    },
    {
        name: 'food-insecurity',
        label: 'Patients with food insecurity ',
        isChecked: false,
    },
    {
        name: 'housing-insecurity',
        label: 'Patients with housing insecurity ',
        isChecked: false,
    },
    {
        name: 'transportation-insecurity',
        label: 'Patients with transportation insecurity ',
        isChecked: false,
    },
];

const getStartDate = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 12));
    return startDate;
}

const ReportingPage = (props: IReportingHOCProps) => {
    // const [selectedValues, setSelectedValues] = useState<string>('all');
    const [fromDate, setFromDate] = useState<string | Date>(getStartDate());
    const [toDate, setToDate] = useState<string | Date>(new Date());
    const [labels, setLabels] = useState<LabelData[]>([]);
    const [downloadReports, setDownloadReports] = useState<DownloadReports[]>(downloadReportsInitialValue);
    const [patientsEnrolledResponse, setPatientsEnrolledResponse] = useState<ApiResponse | undefined>(undefined);
    const [patientsEnrolledChartData, setPatientsEnrolledChartData] = useState<ChartDataType>(barChartInitialData);
    const [patientsEnrolledExcelData, setPatientsEnrolledExcelData] = useState<ExcelData[]>([]);
    const [patientScreenedAtLeatOnceResponse, setPatientScreenedAtLeatOnceResponse] = useState<ApiResponse | undefined>(
        undefined,
    );
    const [patientScreenedAtLeatOnceChartData, setPatientScreenedAtLeatOnceChartData] =
        useState<ChartDataType>(barChartAvgInitialData);
    const [patientScreenedAtLeatOnceExcelData, setPatientScreenedAtLeatOnceExcelData] = useState<ExcelData[]>([]);
    const [screenerOutcomesResponse, setScreenerOutcomesResponse] = useState<ApiResponse | undefined>(undefined);
    const [screenerOutcomesChartData, setScreenerOutcomesChartData] =
        useState<ChartDataType>(screenerOutcomesInitialData);
    const [screenerOutcomesExcelData, setScreenerOutcomesExcelData] = useState<ExcelData[]>([]);
    const [challengesPerPatientsResponse, setChallengesPerPatientsResponse] = useState<ApiResponse | undefined>(
        undefined,
    );
    const [challengesPerPatientsChartData, setChallengesPerPatientsChartData] =
        useState<ChartDataType>(barChartAvgInitialData);
    const [challengesPerPatientsExcelData, setChallengesPerPatientsExcelData] = useState<ExcelData[]>([]);
    const [totalChallengesResolvedResponse, setTotalChallengesResolvedResponse] = useState<ApiResponse | undefined>(
        undefined,
    );
    const [totalChallengesResolvedChartData, setTotalChallengesResolvedChartData] =
        useState<ChartDataType>(barChartAvgInitialData);
    const [totalChallengesResolvedExcelData, setTotalChallengesResolvedExcelData] = useState<ExcelData[]>([]);
    const [foodInsecurityResponse, setFoodInsecurityResponse] = useState<ApiResponse | undefined>(undefined);
    const [foodInsecurityChartData, setFoodInsecurityChartData] = useState<ChartDataType>(barChartAvgInitialData);
    const [foodInsecurityExcelData, setFoodInsecurityExcelData] = useState<ExcelData[]>([]);
    const [transportationInsecurityResponse, setTransportationInsecurityResponse] = useState<ApiResponse | undefined>(
        undefined,
    );
    const [transportationInsecurityChartData, setTransportationInsecurityChartData] =
        useState<ChartDataType>(barChartAvgInitialData);
    const [transportationInsecurityExcelData, setTransportationInsecurityExcelData] = useState<ExcelData[]>([]);
    const [housingInsecurityResponse, setHousingInsecurityResponse] = useState<ApiResponse | undefined>(undefined);
    const [housingInsecurityChartData, setHousingInsecurityChartData] = useState<ChartDataType>(barChartAvgInitialData);
    const [housingInsecurityExcelData, setHousingInsecurityExcelData] = useState<ExcelData[]>([]);

    useEffect(() => {
        const fromDateValue = typeof fromDate === 'string' ? parseDateString(fromDate) : fromDate;
        const toDateValue = typeof toDate === 'string' ? parseDateString(toDate) : toDate;

        const labelRange: LabelData[] = getLabelRange(fromDateValue, toDateValue);

        setLabels(labelRange);
        fetchReportingData();
    }, [fromDate, toDate]);

    // Start - Patients enrolled
    useEffect(() => {
        if (patientsEnrolledResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = patientsEnrolledResponse.data.data.find(
                    (res: PatientsEnrolled) => res.month === label.month && res.year === label.year,
                );
                return {
                    ...label,
                    assigned: responseData ? responseData.assigned : 0,
                    unassigned: responseData ? responseData.unassigned : 0,
                };
            });

            setPatientsEnrolledExcelData(mergedData);

            setPatientsEnrolledChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: PatientsEnrolledMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => {
                    const dataValues = getDataValuesForPatientsEnrolled(dataset.label, mergedData);
                    return {
                        ...dataset,
                        data: dataValues,
                    };
                }),
            }));
        }
    }, [patientsEnrolledResponse]);
    // End - Patients enrolled

    // Start - Patient screened at least once
    useEffect(() => {
        if (patientScreenedAtLeatOnceResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = patientScreenedAtLeatOnceResponse.data.data.find(
                    (res: PatientScreenedAtLeatOnce) => res.month === label.month && res.year === label.year,
                );
                return {
                    ...label,
                    patientsWithScreener: responseData ? responseData.patientsWithScreener : 0,
                };
            });

            setPatientScreenedAtLeatOnceExcelData(mergedData);

            setPatientScreenedAtLeatOnceChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: PatientScreenedAtLeatOnceMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => ({
                    ...dataset,
                    label: 'Patients with screener',
                    data: mergedData.map((item: PatientScreenedAtLeatOnceMergedData) => item.patientsWithScreener),
                })),
            }));
        }
    }, [patientScreenedAtLeatOnceResponse]);
    // End - Patient screened at least once

    // Start - Screener outcomes
    useEffect(() => {
        if (screenerOutcomesResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = screenerOutcomesResponse.data.data.find(
                    (res: ScreenerOutcomes) => res.month === label.month && res.year === label.year,
                );
                return {
                    ...label,
                    screenersWithChallenges: responseData ? responseData.screenersWithChallenges : 0,
                    screenersWithoutChallenges: responseData ? responseData.screenersWithoutChallenges : 0,
                };
            });

            setScreenerOutcomesExcelData(mergedData);

            setScreenerOutcomesChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: ScreenerOutcomesMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => {
                    const dataValues = getDataValuesForScreenerOutcomes(dataset.label, mergedData);
                    return {
                        ...dataset,
                        data: dataValues,
                    };
                }),
            }));
        }
    }, [screenerOutcomesResponse]);
    // End - Screener outcomes

    // Start - Challenges per patient
    useEffect(() => {
        if (challengesPerPatientsResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = challengesPerPatientsResponse.data.data.find(
                    (res: ChallengesPerPatients) => res.month === label.month && res.year === label.year,
                );
                return {
                    ...label,
                    averageChallengesPerPatient: responseData ? Math.round(responseData.averageChallengesPerPatient) : 0,
                };
            });

            setChallengesPerPatientsExcelData(mergedData);

            setChallengesPerPatientsChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: ChallengesPerPatientsMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => ({
                    ...dataset,
                    label: 'Average challenges per patient',
                    data: mergedData.map((item: ChallengesPerPatients) => item.averageChallengesPerPatient),
                })),
            }));
        }
    }, [challengesPerPatientsResponse]);
    // End - Challenges per patient

    // Start - Total challenges resolved
    useEffect(() => {
        if (totalChallengesResolvedResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = totalChallengesResolvedResponse.data.data.find(
                    (res: TotalChallengesResolved) => res.month === label.month && res.year === label.year,
                );
                return {
                    ...label,
                    resolvedChallengesCount: responseData ? responseData.resolvedChallengesCount : 0,
                };
            });

            setTotalChallengesResolvedExcelData(mergedData);

            setTotalChallengesResolvedChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: TotalChallengesResolvedMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => ({
                    ...dataset,
                    label: 'Resolved challenges count',
                    data: mergedData.map((item: TotalChallengesResolved) => item.resolvedChallengesCount),
                })),
            }));
        }
    }, [totalChallengesResolvedResponse]);
    // End - Total challenges resolved

    // Start - Food insecurity
    useEffect(() => {
        if (foodInsecurityResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = foodInsecurityResponse.data.data.find(
                    (res: Insecurity) => res.month === label.month && res.year === label.year,
                );
                const matchedPatients = responseData ? responseData.matchedPatients : 0;
                const totalPatients = responseData ? responseData.totalPatients : 0;
                return {
                    ...label,
                    matchedPatients: matchedPatients,
                    totalPatients: totalPatients,
                    percentage: getPercentage(matchedPatients, totalPatients),
                };
            });

            setFoodInsecurityExcelData(mergedData);

            setFoodInsecurityChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: InsecurityMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => ({
                    ...dataset,
                    label: 'Food insecurity percentage',
                    data: mergedData.map((item: InsecurityMergedData) => item.percentage),
                })),
            }));
        }
    }, [foodInsecurityResponse]);
    // End - Food insecurity

    // Start - Transportation insecurity
    useEffect(() => {
        if (transportationInsecurityResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = transportationInsecurityResponse.data.data.find(
                    (res: Insecurity) => res.month === label.month && res.year === label.year,
                );
                const matchedPatients = responseData ? responseData.matchedPatients : 0;
                const totalPatients = responseData ? responseData.totalPatients : 0;
                return {
                    ...label,
                    matchedPatients: matchedPatients,
                    totalPatients: totalPatients,
                    percentage: getPercentage(matchedPatients, totalPatients),
                };
            });

            setTransportationInsecurityExcelData(mergedData);

            setTransportationInsecurityChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: InsecurityMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => ({
                    ...dataset,
                    label: 'Transportation insecurity percentage',
                    data: mergedData.map((item: InsecurityMergedData) => item.percentage),
                })),
            }));
        }
    }, [transportationInsecurityResponse]);
    // End - Transportation insecurity

    // Start - Housing insecurity
    useEffect(() => {
        if (housingInsecurityResponse?.data?.data) {
            const mergedData = labels.map((label: LabelData) => {
                const responseData = housingInsecurityResponse.data.data.find(
                    (res: Insecurity) => res.month === label.month && res.year === label.year,
                );
                const matchedPatients = responseData ? responseData.matchedPatients : 0;
                const totalPatients = responseData ? responseData.totalPatients : 0;
                return {
                    ...label,
                    matchedPatients: matchedPatients,
                    totalPatients: totalPatients,
                    percentage: getPercentage(matchedPatients, totalPatients),
                };
            });

            setHousingInsecurityExcelData(mergedData);

            setHousingInsecurityChartData((prevData: ChartDataType) => ({
                labels: mergedData.map((item: InsecurityMergedData) => item.monthLabel),
                datasets: prevData.datasets.map((dataset: ChartDatasetType) => ({
                    ...dataset,
                    label: 'Housing insecurity percentage',
                    data: mergedData.map((item: InsecurityMergedData) => item.percentage),
                })),
            }));
        }
    }, [housingInsecurityResponse]);
    // End - Housing insecurity

    const fetchReportingData = async () => {
        const fetchData = async (type: ReportingType, setter: Dispatch<SetStateAction<ApiResponse | undefined>>) => {
            try {
                const response = await props.getReporting({
                    fromDate: typeof fromDate === 'string' ? fromDate : formatDate(fromDate),
                    toDate: typeof toDate === 'string' ? toDate : formatDate(toDate),
                    type,
                });
                setter(response);
            } catch (error) {
                console.error(`Error fetching data for type ${type}:`, error);
            }
        };

        Promise.allSettled([
            fetchData(ReportingType.PATIENTS_ENROLLED, setPatientsEnrolledResponse),
            fetchData(ReportingType.PATIENT_AT_LEAST_ONE_SCREENER, setPatientScreenedAtLeatOnceResponse),
            fetchData(ReportingType.SCREENER_OUTCOMES, setScreenerOutcomesResponse),
            fetchData(ReportingType.CHALLENGES_PER_PATIENTS, setChallengesPerPatientsResponse),
            fetchData(ReportingType.CHALLENGES_RESOLVED, setTotalChallengesResolvedResponse),
            fetchData(ReportingType.FOOD_INSECURITY, setFoodInsecurityResponse),
            fetchData(ReportingType.TRANSPORTATION_INSECURITY, setTransportationInsecurityResponse),
            fetchData(ReportingType.HOUSING_INSECURITY, setHousingInsecurityResponse),
        ]);
    };

    // const onClickTab = (item: string) => {
    //     setSelectedValues(item);
    // };
    // const filterOptions = [
    //     {
    //         displayText: 'All',
    //         value: 'all',
    //     },
    //     {
    //         displayText: 'Me',
    //         value: 'me',
    //     },
    // ];

    const handleFromChange = (date: Date | null) => {
        date && setFromDate(date);
    };
    const handleToChange = (date: Date | null) => {
        date && setToDate(date);
    };

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const parseDateString = (dateString: string): Date => {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    };

    const getLabelRange = (fromDate: Date, toDate: Date): LabelData[] => {
        const labels: LabelData[] = [];
        let currentDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
        const endDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);

        while (currentDate <= endDate) {
            labels.push({
                year: currentDate.getFullYear(),
                month: currentDate.getMonth() + 1,
                monthLabel: labelsInitialData[currentDate.getMonth()],
            });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return labels;
    };

    const getDataValuesForPatientsEnrolled = (value: string, mergedData: PatientsEnrolledMergedData[]) => {
        switch (value) {
            case AssignedStatus.assigned:
                return mergedData.map((item: PatientsEnrolledMergedData) => item.assigned);
            case AssignedStatus.unassigned:
                return mergedData.map((item: PatientsEnrolledMergedData) => item.unassigned);
            default:
                return [];
        }
    };

    const getDataValuesForScreenerOutcomes = (value: string, mergedData: ScreenerOutcomesMergedData[]) => {
        switch (value) {
            case ScreenerOutcomesTypes.withChallenges:
                return mergedData.map((item: ScreenerOutcomesMergedData) => item.screenersWithChallenges);
            case ScreenerOutcomesTypes.withoutChallenges:
                return mergedData.map((item: ScreenerOutcomesMergedData) => item.screenersWithoutChallenges);
            default:
                return [];
        }
    };

    const getPercentage = (value1: number, value2: number) => {
        if (value1 > 0 && value2 > 0) {
            return Math.round((value1 / value2) * 100);
        } else {
            return 0;
        }
    };

    const exportFile = useCallback(() => {
        if (downloadReports.filter((item) => item.isChecked).length > 0) {
            const wb = utils.book_new();

            downloadReports.map((item: DownloadReports) => {
                item.isChecked === true && appendToSheet(item.name, wb);
            });

            writeFileXLSX(wb, 'Reporting.xlsx');
        }
    }, [
        patientsEnrolledExcelData,
        patientScreenedAtLeatOnceExcelData,
        screenerOutcomesExcelData,
        challengesPerPatientsExcelData,
        totalChallengesResolvedExcelData,
        foodInsecurityExcelData,
        housingInsecurityExcelData,
        transportationInsecurityExcelData,
        downloadReports,
    ]);

    const appendToSheet = (name: string, wb: WorkBook) => {
        switch (name) {
            case ReportingType.PATIENTS_ENROLLED:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(patientsEnrolledExcelData),
                    language.patientsEnrolled.excelSheetTitle,
                );
            case ReportingType.PATIENT_AT_LEAST_ONE_SCREENER:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(patientScreenedAtLeatOnceExcelData),
                    language.patientsScreenedAtLeastOnce.excelSheetTitle,
                );
            case ReportingType.SCREENER_OUTCOMES:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(screenerOutcomesExcelData),
                    language.screenerOutcomes.excelSheetTitle,
                );
            case ReportingType.CHALLENGES_PER_PATIENTS:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(challengesPerPatientsExcelData),
                    language.averageNumberOfNewChallengesPerPatient.excelSheetTitle,
                );
            case ReportingType.CHALLENGES_RESOLVED:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(totalChallengesResolvedExcelData),
                    language.totalChallengesResolved.excelSheetTitle,
                );
            case ReportingType.FOOD_INSECURITY:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(foodInsecurityExcelData),
                    language.patientsWithFoodInsecurity.excelSheetTitle,
                );
            case ReportingType.HOUSING_INSECURITY:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(housingInsecurityExcelData),
                    language.patientsWithHousingInsecurity.excelSheetTitle,
                );
            case ReportingType.TRANSPORTATION_INSECURITY:
                return utils.book_append_sheet(
                    wb,
                    utils.json_to_sheet(transportationInsecurityExcelData),
                    language.patientsWithTransportationInsecurity.excelSheetTitle,
                );
        }
    };

    return (
        <div className='max-w-full h-full'>
            <Typhography component='div' additionalClasses='flex justify-between'>
                <Typhography component='div' additionalClasses='flex gap-4'>
                    {/* <TabButton
                        options={filterOptions}
                        onClickTab={onClickTab}
                        setSelectedValues={setSelectedValues}
                        selectedValues={selectedValues}
                        additionalClasses='gap-2 my-4'
                    /> */}
                    <Typhography component='div' additionalClasses='flex items-center gap-4'>
                        <div className='text-xs w-1/3 py-3 pl-1'>Start date</div>
                        <DatePicker
                            onChange={handleFromChange}
                            selectedDate={fromDate ? new Date(fromDate) : null}
                            label={''}
                            labelType='formLabelMedeum'
                            isAdditionalErrorInput={true}
                            additionalErrorClasses='text-red-500 text-21s'
                            placeHolder='MM-DD-YY'
                            height='h-10'
                            iconType='ArrowDown'
                            dateFormat='MMM yyyy'
                            enableClear={false}
                        />
                        <div className='text-xs w-1/3 py-3 pl-1'>End date</div>
                        <DatePicker
                            onChange={handleToChange}
                            selectedDate={toDate ? new Date(toDate) : null}
                            label={''}
                            labelType='formLabelMedeum'
                            isAdditionalErrorInput={true}
                            additionalErrorClasses='text-red-500 text-21s'
                            placeHolder='MM-DD-YY'
                            height='h-10'
                            iconType='ArrowDown'
                            dateFormat='MMM yyyy'
                            enableClear={false}
                            maxDate={new Date()}
                        />
                    </Typhography>
                </Typhography>

                <Typhography component='div' additionalClasses='flex py-2 items-center gap-2'>
                    <Typhography component='div' additionalClasses='text-12s text-gray-500'>
                        Last updated {moment(now()).format('MM/DD/YYYY')}
                    </Typhography>
                    <DownloadDropdown
                        items={downloadReports}
                        setDownloadReports={setDownloadReports}
                        exportFile={exportFile}
                    />
                </Typhography>
            </Typhography>
            <div className='relative'>{props.isFetching && <LoadingProgress isLoading={props.isFetching} />}</div>
            <div className='flex flex-row space-x-4 '>
                <BarChart
                    title={language.patientsEnrolled.title}
                    legend1={language.patientsEnrolled.legendAssigned}
                    legend2={language.patientsEnrolled.legendUnassigned}
                    isLegendVisible={true}
                    data={patientsEnrolledChartData}
                />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
                <BarChartAvg
                    title={language.patientsScreenedAtLeastOnce.title}
                    data={patientScreenedAtLeatOnceChartData}
                />
                <BarChartSingle
                    title={language.screenerOutcomes.title}
                    legend1={language.screenerOutcomes.legendGreaterThanOneChallenge}
                    legend2={language.screenerOutcomes.legendNegative}
                    isLegendVisible={true}
                    data={screenerOutcomesChartData}
                />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
                <BarChartAvg
                    title={language.averageNumberOfNewChallengesPerPatient.title}
                    data={challengesPerPatientsChartData}
                />
                <BarChartAvg title={language.totalChallengesResolved.title} data={totalChallengesResolvedChartData} />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
                <BarChartAvg
                    title={language.patientsWithFoodInsecurity.title}
                    data={foodInsecurityChartData}
                    isPercentage={true}
                />
                <BarChartAvg
                    title={language.patientsWithHousingInsecurity.title}
                    data={housingInsecurityChartData}
                    isPercentage={true}
                />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
                <BarChartAvg
                    title={language.patientsWithTransportationInsecurity.title}
                    data={transportationInsecurityChartData}
                    isPercentage={true}
                />
                <div className='w-full p-4' />
                {/* <BarChartAvg title={language.patientsWithIncomeInsecurity.title} /> */}
            </div>
        </div>
    );
};

const ReportingPageComponent = ReportingHOC(ReportingPage);

export default ReportingPageComponent;
