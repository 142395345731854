import { Icon, Typhography } from 'dfh-ui-library';

type PreviewProps = {
    content?: string;
    h?: string;
};

export const ImagePreview: React.FC<PreviewProps> = ({ content }) => (
    <img src={content} alt='Preview' className='max-w-full h-60' />
);

export const PdfPreview: React.FC<PreviewProps> = ({ content }) => (
    <div className='w-full overflow-hidden h-60'>
        {/* Set width to full and height as needed, and hide overflow */}
        <object data={content} type='application/pdf' className='w-full h-60'>
            <p>Your browser does not support PDFs.</p>
        </object>
    </div>
);

export const UrlPreview: React.FC<PreviewProps> = ({ content, h = 'h-60' }) => (
    <div className={`w-full overflow-hidden ${h} flex justify-center`}>
        {/* Set width to full and height as needed, and hide overflow */}
        {/* <img src={content} alt='Preview' className='max-w-full h-60' /> */}
        <object data={content} type='application/pdf' className={`w-full ${h}`}>
            <p>Your browser does not support PDFs.</p>
        </object>
    </div>
);

export const DocxPreview: React.FC<PreviewProps> = ({ content }) => {
    return (
        <Typhography component='div' additionalClasses='flex flex-col items-center text-14s text-gray-700'>
            <Typhography> {content}</Typhography>
            <Typhography>
                <Icon iconType='EditDocument' />
            </Typhography>
        </Typhography>
    );
};

export const TextPreview: React.FC<PreviewProps> = ({ content }) => (
    <textarea value={content} readOnly className='w-full h-60' />
);
