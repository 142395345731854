import {
    ApiResponse,
    IChallengeRequestParams,
    IListRequestParams,
    IReactionRequest,
    ISchemaRequestParams,
} from './api.type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
    IExistingResourceRequest,
    IImageCreateRequest,
    IImageRequest,
    IPatientResourceRequest,
    IResourcesCreateRequest,
} from './image.type';
import { SerializedError } from '@reduxjs/toolkit';
import { ILetterSchema, ISchema, ISchemaStep } from 'dfh-form-generator';
import { ILetterRequest, ILetterRequestRInfo, INavigationProps, IScreenerNoteRequest, IScreenerRequest, IScreenerResponse } from './screener.type';
import { ISolutionvalues, NoteItemProps, NoteProps, TimelineEventProps } from 'dfh-ui-library';
import { SchemaState } from 'stores/screenerSlice';

export interface IPatient {
    _id?: string;
    imageId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    enrollmentDate: string | null | Date;
    primaryLanguage: string;
    race: string;
    ethnicity: string;
    relationshipStatus: string;
    gender: string;
    education: string;
    dob: string | null | Date;
    socialSecurityNumber: string;
    personalIncome: string;
    householdIncome: string;
    householdSize: string;
    email: string;
    phoneNumber: string;
    medicalProvider: string;
    bestWayToContact: string;
    emergencyContactName: string;
    emergencyContactRelationship: string;
    emergencyContact: string;
    housingStatus: string;
    mailingAddress: string;
    address?: IAddress;
    responsibleIndividual: IResponsibleIndividual | undefined;
    externalCaseManagers: IExternalCaseManager[];
    imageSignedUrl?: string;
    themeColor?: string;
    lastContact?: string | Date | null;
}

export interface IFormResoureseData {
    _id?: string;
    signedUrl?: string;
    resourceName?: string;
    resourceLink?: string;
}

export interface IFormLetter {
    name: string;
    type: string;
    uploadDate: string;
}
export interface IPatientAssign {
    _id?: string;
    responsibleIndividual?: string | null;
    patientIds?: string[];
}

export interface IPatientDetails {
    _id?: string;
    imageId: string;
    firstName: string;
    lastName: string;
    race: string;
    sex: string;
    dob: Date | null;
    email: string;
    phoneNumber: string;
    medicalProvider: string;
    bestWayToContact: string;
    emergencyContact: string;
    permanentAddress: string;
    address?: string;
    responsibleIndividual: IResponsibleIndividual;
    externalCaseManagers: IExternalCaseManager[];
    imageSignedUrl?: string;
    gad7?: IGroupPoints;
    phq9?: IGroupPoints;
    challenges?: IChallengesProps[];
    lastContact?: string | Date | null;
}

export interface IPatientResources {
    _id?: string;
    patient: string;
    resourceLink: string;
    resourceName: string;
    uploadDate: string;
    uploadType: string;
}

export interface IChallengesProps {
    status?: string;
    urgency?: string;
}
export interface IAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}
export interface IResponsibleIndividual {
    _id: string;
    fullName?: string;
}

export interface IExternalCaseManager {
    fullName: string;
    email: string;
    phoneNumber: string;
    agency: string;
    notes: string;
    releaseOfInformation?: ResourceCardProps[];
}

export interface InfoDetails {
    fileId?: string;
    dateCreated?: string;
    signedUrl?: string;
}
export enum Sex {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
}

export enum ResponsibleType {
    ME = 'ME',
    REGISTERGED = 'REGISTERGED',
    UNREGISTERGED = 'UNREGISTERGED',
}

export enum PermanentAddressType {
    HOMELESS_NO_ADDRESS = 'Homeless, needs a permanent mailing address to receive vital documents',
    Homeless_WITH_ADDRESS = 'Homeless, uses this permanent address to receive vital documents',
    HOUSED = 'Housed with the following permanent address',
}

export interface IErrorMsg {
    isVisible: boolean;
    type: string;
    msg: string;
}

export interface IPatientRequest {
    id: string;
}

export interface IChallengeRequest {
    urgency: string;
    id?: string;
}

export interface ISolutionRequest {
    patientChallengeId: string;
    challengeId: string;
}

export interface ISaveNotesRequest {
    status?: string;
    actionedUser?: string;
    note: NoteItemProps[];
    id?: string;
    patientId?: string;
}

export interface ISaveChallengeNotesRequest {
    status?: string;
    actionedUser?: string;
    note: NoteProps;
    id?: string;
    patientId?: string;
}
export interface IPatientProps {
    getPatientList: (
        requestParams: IListRequestParams,
        filter: PatientsFilter,
    ) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    isLoading: boolean;
    errorMsg: IErrorMsg;
    createPatient: (req: IPatient) => Promise<ApiResponse | undefined>;
    editPatient: (req: IPatient) => Promise<ApiResponse | undefined>;
    editAssign: (req: IPatientAssign) => Promise<ApiResponse | undefined>;
    editAssignBulk: (req: IPatientAssign) => Promise<ApiResponse | undefined>;
    deletePatient: (req: IPatientRequest) => Promise<ApiResponse | undefined>;
    getPatient: (req: IPatientRequest) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    saveImage: (req: IImageCreateRequest) => Promise<ApiResponse | undefined>;
    savePatientResource: (req: IPatientResourceRequest) => Promise<ApiResponse | undefined>;
    editPatientResource: (req: IPatientResourceRequest) => Promise<ApiResponse | undefined>;
    getImage(req: IImageRequest): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    };
    screenerVersions(req: ISchemaRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    };
    getPatientSchemaVersion(req: ISchemaRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    };
    getPatientLetterSchemaVersion(req: ISchemaRequestParams): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    };
    getPatientLetterSchemaReleaseInfo(req: string): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    };
    getNoteHistory(req: string): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    };
    getUsers: () => Promise<ApiResponse | undefined>;
    navigateToPatients: (isSaved?: boolean) => void;
    navigateToEdit: (id: string) => void;
    navigateToView: (id: string, refetch?: boolean, isEnableSolution?: boolean, req?: ILetterRequest) => void;
    navigateToViewWithParams: (id: string, refetch?: boolean) => void;
    navigateToDelete: (id: string, name: string) => void;
    navigateToLetterView: (req: INavigationProps) => void;
    updateScreenerSteps: (req: IScreenerRequest) => Promise<ApiResponse | undefined>;
    updateLetterSchemaValues: (req: ILetterRequest) => Promise<ApiResponse | undefined>;
    getChallenges: (req: IChallengeRequestParams) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    makeChallengeUrgent: (req: IChallengeRequest) => Promise<ApiResponse | undefined>;
    saveNotes: (req: ISaveNotesRequest) => Promise<ApiResponse | undefined>;
    saveChallengeNotes: (req: ISaveChallengeNotesRequest) => Promise<ApiResponse | undefined>;
    getSolutionRowData: (req: ISolutionRequest) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    getResources: (req: string) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    saveSolutionNote: (req: ISolutionNoteRequest) => Promise<ApiResponse | undefined>;
    saveServeyNotes: (req: IServeyNoteRequest) => Promise<ApiResponse | undefined>;
    updateServeyNotes: (req: IServeyNoteRequest) => Promise<ApiResponse | undefined>;
    updateNotes: (req: IServeyNoteRequest) => Promise<ApiResponse | undefined>;
    toggleTodoCheckbox: (req: ITodoToggleRequestParams) => Promise<ApiResponse | undefined>;
    getFile: (
        req: string,
        uploadType?: string,
    ) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    letterSchemaResponse: ILetterSchema | undefined;
    getPatinentTimeLine: (patientId: string) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    getPatientChallengeTimeLine: (patientId: string, challengeId: string) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    schemaRequestParam: ILetterRequest;
    setSchemaRequestParam: (req: ILetterRequest) => void;
    uploadResourceInfo: (req: IImageCreateRequest) => Promise<ApiResponse | undefined>;
    uploadResourceInfoForm(createRequest: IExistingResourceRequest): Promise<ApiResponse>;
    deleteResourceInfo(reqParams: string): Promise<ApiResponse>;
    getReleaseInfoFiles(req: IResourcesCreateRequest): Promise<ApiResponse>;
    navigateToAdd: (patientId: string, refetch?: boolean, caseMngerIndex?: boolean, fileId?: string) => void;
    updateLetterSchemaValuesForRInfo: (req: ILetterRequestRInfo) => Promise<ApiResponse | undefined>;
    saveScreenerNotes?: (req: IScreenerNoteRequest) => Promise<ApiResponse | undefined | ''>;
    patientSchemaRefetch?: () => void;
}

export interface ILetterProps {
    schema?: ILetterSchema;
    patientName?: string;
    navigateToView?: (id: string, refetch?: boolean, isEnableSolution?: boolean) => void;
    receivedData?: any;
    backToView?: (req: ILetterRequest) => void;
    updateLetterSchemaValues: (req: ILetterRequest) => Promise<ApiResponse | undefined>;
    updateLetterSchemaValuesForRInfo: (req: ILetterRequestRInfo) => Promise<ApiResponse | undefined>;
    varientId: string;
    varient: string;
    isReleaseInfo: boolean;
}

export interface IPatientScreenerProps {
    getPatientList: (
        requestParams: IListRequestParams,
        filter: PatientsFilter,
    ) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    isLoading: boolean;
    errorMsg: IErrorMsg;
    selectedPatient: IPatient | undefined;
    schemaStep?: ISchemaStep;
    viewMode?: boolean;
    isEditable?: boolean;
    selectedVersion?: ISchema;
    patientSchema?: SchemaState | undefined;
    createPatient: (req: IPatient) => Promise<ApiResponse | undefined>;
    editPatient: (req: IPatient) => Promise<ApiResponse | undefined>;
    deletePatient: (req: IPatientRequest) => Promise<ApiResponse | undefined>;
    getPatient: (req: IPatientRequest) => void;
    saveImage: (req: IImageCreateRequest) => Promise<ApiResponse | undefined>;
    getImage(req: IImageRequest): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    };
    getUsers: () => Promise<ApiResponse | undefined>;
    navigateToPatients: (isSaved: boolean) => void;
    navigateToEdit: (id: string) => void;
    goToNextStep?: () => void;
    goToScreenerEdit?: () => void;
    saveAndExit?: () => void;
    getChallengeData?: () => void;
    updateScreenerSteps: (req: IScreenerRequest) => Promise<ApiResponse | undefined>;
    saveScreenerNotes?: (req: IScreenerNoteRequest) => Promise<ApiResponse | undefined | ''>;
    getSurvey?: (survey: IScreenerResponse) => void;
    patientSchemaRefetch?: () => void;
}

export interface ScreeningComponentMethods {
    handleSubmitClick: () => void;
}

export interface IScreenerNote {
    showNote: boolean;
    patientSchema?: SchemaState | undefined;
    handleAddItem: (items: NoteProps) => void;
    setShowNote: (status: boolean) => void;
    patientSchemaRefetch?: () => void;
}

export type solutionTypes = 'Referral' | 'Form' | 'Letter' | undefined;

export interface ISolution {
    _id?: string;
    challenge?: string;
    shortDescription?: string;
    longDescription?: string;
    solutionName?: string;
    solutionType?: solutionTypes;
    variants?: IVariation[];
}
export interface IVariation {
    isDefault?: boolean;
    solution?: string;
    solutionVariantName?: string;
    referralData?: IReferralData;
    _id?: string;
}

export interface IReferralData {
    email?: string;
    phone?: string;
}

export interface ChallengeProps {
    challengeData?: any;
    makeUrgent?: (status: string, id: string, event: any) => void;
    sortChallenges?: (value: string, chalData: any) => void;
    updateChallenge?: (items: ISaveChallengeNotesRequest) => void;
    saveSolutionNote?: (req: ISolutionNoteRequest) => void;
    showNote?: boolean;
    setShowNote?: (status: boolean) => void;
    getSolution?: (reqData: ISolutionRequest) => void;
    solution?: ISolution[] | undefined;
    cancelAction?: () => void;
    setActionData?: (solution: ISolution) => void;
    actionData?: any;
    solutionRowClick?: boolean;
    setSolutionRowClick?: (isClick: boolean) => void;
    navigateToLetterView?: (
        challengeId: string,
        veriantId: string,
        varient: string,
        solutionId: string,
        tempChallageId: string,
    ) => void;
    setclickedId?: (_id: string) => void;
    clickedId?: string;
    setShowChallengeTimeline?: (status: boolean) => void;
    setSelectedChallenge?: (challengeId: string) => void;
    copiedNote?: NoteProps;
    setRowDetails?: (item: IPatientChallengeDto) => void
}
export enum PatientsFilter {
    MY = 'my',
    ASSIGNED = 'assigned',
    UNASSIGNED = 'unassigned',
    RI = 'ri',
}

export interface ISolutionNoteRequest {
    solutionId: string;
    solutionVariantId: string;
    note: NoteItemProps[];
    actionedUser?: string;
    patient: string;
    patientChallenge: string;
}

export interface IServeyNoteRequest {
    note: NoteProps;
    actionedUser?: string;
    patient?: string;
    survey?: string;
    noteType: string;
}

export interface ITimelineProps {
    timeLineData: TimelineEventProps[];
    saveServeyNotes: (req: IServeyNoteRequest) => Promise<ApiResponse | undefined | ''>;
    navigationItemAction?: (timeLineNavReq?: ISolutionvalues, type?: string) => void;
    getNoteHistory: (req: string) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
    };
    toggleTodoCheckbox?: (req: ITodoToggleRequestParams) => void;
}

export interface ResourceCardProps {
    title: string;
    fileSize: string;
    progress?: number;
    dateCreated?: string;
    isShowPreview: boolean;
    fileId?: string;
    signedUrl?: string;
    setViewReleaseInfoPdf?: (show: boolean) => void;
    setPdfReleaseInfoUrl?: (url: string) => void;
    removeFileByName?: (fileName: string) => void;
    showPreview?: (fileId: string, uploadType?: string) => void;
    uploadType?: string;
    _id?: string;
    onCheckboxChange?: (id: string, checked: boolean) => void;
}

export interface ResourceChooseCardProps {
    resourceName: string;
    uploadDate?: string;
    isShowPreview: boolean;
    uploadType?: string;
    resourceLink?: string;
    _id?: string;
    setViewReleaseInfoPdf?: (show: boolean) => void;
    setPdfReleaseInfoUrl?: (url: string) => void;
    removeFileByName?: (fileName: string) => void;
    showPreview?: (fileId: string) => void;
}

export interface CardListProps {
    cardData: ResourceCardProps[];
    setViewReleaseInfoPdf?: (show: boolean) => void;
    setPdfReleaseInfoUrl?: (url: string) => void;
    removeFileByName?: (fileName: string) => void;
    showPreview?: (fileId: string) => void;
}

export interface IHomeHOCProps {
    getDashboardUpdates: (userId?: string) => Promise<any>;
    updateReaction: (req: IReactionRequest) => Promise<ApiResponse | undefined>;
    getDashboardMetrix: (userId?: string) => Promise<any>;
    getDashboardUrgentChallenges: (userId: string) => Promise<any>;
    getDashboardTodosChallenges: (userId: string) => Promise<any>;
    toggleDashboardTodoCheckbox: (patientRequest: ITodoToggleRequestParams) => Promise<any>;
    data?: ApiResponse;
    isFetching: boolean;
}

export interface ITodoToggleRequestParams {
    noteId?: string;
    actionedUser?: string;
    patient?: string;
    toggleOptions: {
        noteIndex: number;
        check: boolean;
    };
}

interface IChallengeDto {
    answerId: number;
    challengeName: string;
    domain: string;
    question: string;
    _id: string;
}

export interface IPatientChallengeDto {
    _id: string;
    urgency: string;
    status: string;
    survey: string;
    challenge: IChallengeDto;
    patient: string;
    createdUser: string;
    createdDate: Date | string;
}

export interface IGroupPoints {
    _id: string;
    groupId: string;
    patientId: string;
    points: {
        date: String;
        surveyId: string;
        sum: number;
    }[]
}