import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ISchema } from 'dfh-form-generator';
import { ScreenerStatus } from 'utils/enum';
import { NoteProps } from 'dfh-ui-library';

export interface SchemaState extends ISchema {
    isLocked: boolean;
    completedOn: string;
    note?: NoteProps;
    surveyId?: string;
}

interface ScreenerState {
    data: { patientId: string; schema: SchemaState; status: ScreenerStatus }[];
}

const initialState = { data: [] } as ScreenerState;

const screenerSlice = createSlice({
    name: 'screener',
    initialState,
    reducers: {
        setScreenerData(state, action) {
            state.data = action.payload;
        },
    },
});

export const { setScreenerData } = screenerSlice.actions;
export const getScreenerData = (state: RootState) => state.screener.data;
export default screenerSlice.reducer;
