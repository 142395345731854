import React, { ComponentType, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthRepository } from 'repositories/AuthRepository';
import RouteRegistry from 'routes/routeRegistry';
import { errorMsgInit } from 'utils/init';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { ApiResponse, AuthApiRequest, IAuthNewPasswordRequest, IAuthRequest } from 'utils/types/api.type';
import { ILoginProps, IErrorMsg } from 'utils/types/auth.type';

const loginHOC = <P extends ILoginProps>(WrappedComponent: ComponentType<ILoginProps>) => {
    const LoginDataFetching: React.FC<P & ILoginProps> = (props) => {
        const [isLoading, setIsLoading] = React.useState(false);
        const [errorMsg, setErrorMsg] = useState<IErrorMsg>(errorMsgInit);
        const navigate = useNavigate();
        const localStorageService = LocalStorageService();
        const authRepository = useAuthRepository();

        const navigateToSignIn = () => {
            navigate(RouteRegistry.auth.paths.signIn.path);
        };

        const navigateToSignUp = () => {
            navigate(RouteRegistry.auth.paths.signUp.path);
        };

        const navigateToResetPassword = () => {
            navigate(RouteRegistry.auth.paths.reset.path);
        };

        const navigateToHome = () => {
            navigate(RouteRegistry.app.paths.root.path);
        };

        const navigateToVerification = (email: string) => {
            navigate(RouteRegistry.auth.paths.verification.path, { state: { email: email } });
        };

        const navigateToUserDetails = (email: string) => {
            navigate(RouteRegistry.auth.paths.userDetails.path, { state: { email: email } });
        };

        const navigateToRestEmail = () => {
            navigate(RouteRegistry.auth.paths.resetEmail.path);
        };

        // Login Function
        const login = async (req: AuthApiRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const loginResult = await authRepository.login({
                    email: req.email,
                    password: req.password,
                });
                if (loginResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: loginResult.error.data.message,
                    });
                if (loginResult.data) {
                    localStorageService.setAuthTokens({
                        accessToken: loginResult.data.data.accessToken,
                        refreshToken: loginResult.data.data.refreshToken,
                        idToken: loginResult.data.data.idToken,
                        user: loginResult.data.data.payload,
                    });
                }
                setIsLoading(false);
                return loginResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // New Password Function
        const newPassword = async (req: IAuthNewPasswordRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const newPassResult = await authRepository.newPassword({
                    email: req.email,
                    verificationCode: req.verificationCode,
                    newPassword: req.newPassword,
                });
                if (newPassResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Password reset failed.',
                    });
                setIsLoading(false);
                return newPassResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Password Reset Function
        const resetPassword = async (req: IAuthRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const newPassResult = await authRepository.resetPassword({
                    email: req.email,
                });
                if (newPassResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Password reset failed.',
                    });
                setIsLoading(false);
                return newPassResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const apiMethods = {
            login: login,
            newPassword: newPassword,
            resetPassword: resetPassword,
        };

        const navigateMethods = {
            navigateToSignIn: navigateToSignIn,
            navigateToSignUp: navigateToSignUp,
            navigateToResetPassword: navigateToResetPassword,
            navigateToRestEmail: navigateToRestEmail,
            navigateToVerification: navigateToVerification,
            navigateToUserDetails: navigateToUserDetails,
            navigateToHome: navigateToHome,
        };

        return (
            <WrappedComponent
                {...(props as P & ILoginProps)}
                isLoading={isLoading}
                errorMsg={errorMsg}
                apiMethods={apiMethods}
                navigateMethods={navigateMethods}
            />
        );
    };

    return LoginDataFetching;
};

export default loginHOC;
