import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartDataType } from 'utils/types/reporting.type';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const optionsInitialValue = {
    responsive: true,
    plugins: {
        legend: {
            display: false, // Disable default legend
        },
        title: {
            display: false,
            text: 'Social challenges per patient (avg.)', // Update the title to match the new chart
        },
    },
    scales: {
        x: {
            stacked: false,
            grid: {
                display: false, // Display vertical grid lines
            },
        },
        y: {
            beginAtZero: true,
            stacked: false,
            ticks: {
                stepSize: 1,
                callback: (value: any) => `${value}`, // Adjust tick callback to remove percentage sign
            },
            grid: {
                display: true, // Hide horizontal grid lines
            },
        },
    },
};

const defaultLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const defaultData = {
    labels: defaultLabels,
    datasets: [
        {
            label: 'Bar chart avg',
            data: [20, 80, 60, 50, 80, 60, 40, 20, 90, 50, 60, 70], // Sample average data
            backgroundColor: '#006CD0', // Blue color
        },
    ],
};

interface BarChartAvgProps {
    title: string;
    data?: ChartDataType;
    isPercentage?: boolean;
}

const BarChartAvg: React.FC<BarChartAvgProps> = ({ title, data = defaultData, isPercentage = false }) => {
    const [options, setOptions] = useState(optionsInitialValue);

    useEffect(() => {
        if (isPercentage) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                scales: {
                    ...prevOptions.scales,
                    y: {
                        ...prevOptions.scales.y,
                        ticks: {
                            ...prevOptions.scales.y.ticks,
                            stepSize: isPercentage ? 25 : 1,
                            callback: (value: any) => `${value}%`,
                        },
                        min: isPercentage ? 0 : undefined,
                        max: isPercentage ? 100 : undefined,
                    },
                },
            }));
        }
    }, [isPercentage]);

    return (
        <div className='w-full p-4 bg-white border rounded-lg shadow-md'>
            <div className='flex justify-between items-center mb-4'>
                <h2 className='text-21s font-medium'>{title}</h2>
            </div>
            <Bar options={options} data={data} />
        </div>
    );
};

export default BarChartAvg;
