import { Button, CustomFileInput, Heading, Input, Modal, Select, Typhography } from 'dfh-ui-library';
import { ChangeEvent, useEffect, useState } from 'react';
import { IFormResoureseData, IPatient } from 'utils/types/patient.type';
import { DocxPreview, ImagePreview, PdfPreview, TextPreview } from './imagePreview';
import _ from 'lodash';
import { IPatientResourceRequest } from 'utils/types/image.type';
import { CloseIcon } from '../helpers/iconHelper';

interface FormUploadModalProps {
    rowData: IPatient | undefined;
    isOpenModal: boolean;
    handleCancelButton: () => void;
    avatarUrl?: string;
    handleConfirmButton?: (req: IPatientResourceRequest) => void;
    editData?: IFormResoureseData;
    isNewDoc: boolean;
    setIsNewDoc?(value: boolean): void;
    resourceTypes?: string[];
}
export const FormUploadModal = (formProps: FormUploadModalProps) => {
    const [isOpen, _setIsOpen] = useState(formProps.isOpenModal);
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);
    const [fileNme, setFileName] = useState<string | null>(null);
    const [showFileUpload, setShowFileUpload] = useState<boolean>(formProps.isNewDoc);
    const [resourceType, setResourceType] = useState<string | null>(null);
    const [resourceFile, setResourceFile] = useState<File | null>(null);
    const [labelText, setLabelText] = useState<string>('Enter Your Document Type')
    const [otherResourceName, setOtherResourceName] = useState<string>('')

    const isTextBoxDisabled = formProps?.editData?.signedUrl && !formProps.isNewDoc
    const inputFieldAdditionalClasses = isTextBoxDisabled ? 'text-gray-500' : ''

    const handleFileChange = (file: File | null) => {
        file?.name && setFileName(file?.name);
        if (file) {
            setResourceFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string | null;
                if (result) {
                    setFileContent(result);
                    setFileType(file.type);
                }
            };
            if (file.type.startsWith('image/')) {
                reader.readAsDataURL(file);
            } else if (file.type === 'application/pdf') {
                reader.readAsDataURL(file);
            } else if (file.type.startsWith('text/')) {
                reader.readAsText(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                reader.readAsArrayBuffer(file); // read .docx file as ArrayBuffer
            }
        }
    };

    const handleChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target;
        if (!_.isEmpty(value)) {
            setResourceType(value);
            return;
        }
        setResourceType('');
    };
    const uploadResources = () => {
        formProps.handleConfirmButton &&
            resourceFile &&
            resourceType &&
            formProps.handleConfirmButton({
                resourceId: formProps?.editData?._id,
                file: resourceFile,
                resourceName: resourceType !== 'Other' ? resourceType : otherResourceName,
            });
    };

    useEffect(() => {
        if (isTextBoxDisabled) {
            setLabelText('Document Type')
        } else {
            setLabelText('Enter Your Document Type')
        }
    }, [isTextBoxDisabled])

    const renderPreview = () => {
        if (!fileType || !fileContent) return null;

        switch (true) {
            case fileType.startsWith('image/') || fileType === 'jpeg' || fileType === 'png':
                return <ImagePreview content={fileContent} />;
            case fileType === 'application/pdf' || fileType === 'pdf':
                return <PdfPreview content={fileContent} />;
            case fileType.startsWith('text/'):
                return <TextPreview content={fileContent} />;
            case fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return fileNme && <DocxPreview content={fileNme} />;
            case fileType === 'docx':
                return <DocxPreview />;
            default:
                return <p>File type not supported for preview.</p>;
        }
    };

    useEffect(() => {
        function getFileType(url: string) {
            const extension = url.split('.').pop();
            return extension;
        }

        const editData = formProps?.editData;
        setShowFileUpload(true);
        setFileContent(null);
        if (editData) {
            let type: string | undefined = '';

            if (editData.signedUrl) {
                type =
                    editData?.resourceLink && editData?.resourceLink !== undefined
                        ? getFileType(editData.resourceLink)
                        : '';
                if (type) {
                    setFileType(type);
                }
                setFileContent(editData.signedUrl);
                setShowFileUpload(false);
            }

            if (formProps.resourceTypes && editData.resourceName && formProps.resourceTypes.includes(editData.resourceName)) {
                if (editData.resourceName !== 'Other') {
                    setResourceType(editData.resourceName)
                } else {
                    setResourceType('Other')
                    setOtherResourceName(editData.resourceName)
                }
            } else {
                setResourceType('Other')

                if (editData.resourceName) {
                    setOtherResourceName(editData.resourceName)
                } else {
                    setOtherResourceName('Other')
                }
            }
        }
    }, [formProps.editData, formProps.isOpenModal]);

    useEffect(() => {
        setShowFileUpload(formProps.isNewDoc);
        setResourceType(null)
        setOtherResourceName('')
    }, [formProps.isNewDoc]);

    const handleOtherResourceNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOtherResourceName(event.target.value)
    }

    const typesList = formProps.resourceTypes;
    return (
        <Modal
            isOpen={isOpen}
            onClose={false}
            modalAdditionalClasses={'w-[400px] items-center justify-center flex-col'}
        >
            <Typhography component='div' additionalClasses='flex justify-between pt-4 px-4'>
                <Typhography component='div'>
                    <Heading type='h2' colorType='dark' label={'Upload Documents'} additionalClasses='mb-4' />
                </Typhography>
            </Typhography>
            <Typhography component='div' additionalClasses='border border-x-0'>
                <Typhography component='div' additionalClasses='flex flex-col p-4 gap-4'>
                    { (formProps.isNewDoc || ( !formProps.isNewDoc && resourceType !== 'Other' )) && (
                        <Typhography component='div' additionalClasses=''>
                            <Select
                                name='docType'
                                onChange={handleChange}
                                onBlur={() => {}}
                                value={resourceType ?? ''}
                                defaultText='Select Type'
                                selectType='regular'
                                label={`Document Type`}
                                labelClasses='mb-1'
                                isIcon={false}
                                labelType='formLabelMedeum'
                                options={
                                    typesList &&
                                    typesList.map((types) => ({
                                        displayName: types,
                                        value: types,
                                    }))
                                }
                                disabled={formProps?.editData?.signedUrl && !formProps.isNewDoc ? true : false}
                                additionalClasses='py-3'
                            />{' '}
                        </Typhography>
                    )}
                    { resourceType === 'Other' && (
                        <Typhography>
                            <Input 
                                name='otherResource'
                                label={labelText}
                                labelType='formLabelMedeum'
                                value={otherResourceName}
                                onChange={handleOtherResourceNameInputChange}
                                disabled={isTextBoxDisabled ? true : false}
                                additionalClasses={`${inputFieldAdditionalClasses}`}
                            />
                        </Typhography>
                    )}
                    <Typhography component='div' additionalClasses='relative'>
                        {(formProps.isNewDoc || showFileUpload) && (
                            <CustomFileInput
                                iconType='BlackUpload'
                                iconAlignment='left'
                                isIconEnabled={true}
                                buttonClasses={`rounded-md border-dashed border-2`}
                                buttonBgClass='bg-[#F1F5F9]'
                                textColor='text-black-540'
                                onFileChange={handleFileChange}
                            />
                        )}

                        {!formProps.isNewDoc && !showFileUpload && (
                            <Typhography component='div' additionalClasses='absolute top-6 right-4 cursor-pointer'>
                                <div className='bg-blue-550 rounded-full p-2'>
                                    <CloseIcon
                                        onClick={() => {
                                            setShowFileUpload(true);
                                        }}
                                    />
                                </div>
                            </Typhography>
                        )}
                    </Typhography>
                    {renderPreview()}
                </Typhography>
            </Typhography>

            <Typhography component='div' additionalClasses='grid grid-cols-4 gap-4 mt-4 pb-4 px-4'>
                <Typhography component='div' additionalClasses='col-start-1 col-span-2'>
                    <Button
                        buttonLabel='Cancel'
                        data-testId='InputField-id2'
                        variants='primaryWhite'
                        onClick={formProps.handleCancelButton}
                    />
                </Typhography>
                <Typhography component='div' additionalClasses='col-start-3 col-span-2'>
                    <Button
                        buttonLabel='Upload'
                        data-testId='InputField-id'
                        variants='primaryBlue'
                        onClick={uploadResources}
                        isDisabled={resourceFile === null}
                    />
                </Typhography>
            </Typhography>
        </Modal>
    );
};